import { memo, useState } from 'react';
import { ActionIcon, Alert, Button, Flex, Modal, Text, TextInput, Tooltip } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { IconInfoCircle, IconMail } from '@tabler/icons-react';
import { isEmail, useForm } from '@mantine/form';

import { ShareClientModalProps } from './ShareClientModal.types';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { UserInfoRow } from '@/pageAI/components/users/UserInfoRow';
import { CopyLinkButton } from '@/shared/components/common/CopyLinkButton';
import { useShareClient } from '@/pageAI/hooks/clients/useShareClient';
import { PermissionRoleSelect } from '../PermissionRoleSelect';
import { ClientPermissionRole } from '@/pageAI/gql/graphql';
import { UpdateClientPermissionRoleSelect } from '../UpdateClientPermissionRoleSelect';
import { posthog } from '@/shared/plugins/posthog';

const ShareClientModalBase = ({ opened = false, client, onClose }: ShareClientModalProps) => {
  const { user } = useAuth0();

  const { shareClient, isSharing } = useShareClient();

  const form = useForm({
    initialValues: {
      email: '',
      role: ClientPermissionRole.Viewer,
    },

    validate: {
      email: isEmail('Email is not valid'),
    },
  });
  const [hasShared, setHasShared] = useState(false);

  const handleSubmit = async () => {
    const { email, role } = form.values;

    await shareClient(client.id, email, role);

    setHasShared(true);

    form.reset();
  };

  const handleCopyLink = () => {
    posthog.capture('[Clients] Copy link', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      link: window.location.href,
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size={680}
      title={`Share client – ${getClientDisplayName(client)}`}
      sx={{
        '.ghost-Modal-title': {
          fontWeight: 500,
        },
      }}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="column" gap="xl">
          <Flex direction="column" gap="md">
            {hasShared && (
              <Alert color="orange.7" sx={(theme) => ({ border: `1px solid ${theme.colors.orange[5]}` })}>
                Please ensure your contact received the client-sharing email, as it can occasionally land in their spam
                folder. If they&apos;re new to Page, they will be asked to create an account.
              </Alert>
            )}

            <Flex align="center" gap="xs">
              <TextInput
                placeholder="Enter the email of the person you want to share with..."
                withAsterisk
                icon={<IconMail size={16} />}
                disabled={isSharing}
                sx={{ flex: '1 1' }}
                {...form.getInputProps('email')}
              />

              <PermissionRoleSelect {...form.getInputProps('role')} sx={{ width: 100 }} />
            </Flex>

            <Flex direction="column" gap="xs">
              <Flex align="center" gap={4}>
                <Text fz="0.875rem" fw={600} color="dark">
                  People with access
                </Text>

                <Tooltip
                  label="The people you share this client with can view the case timeline, modify the bookmarks and add comments."
                  withinPortal
                  withArrow
                >
                  <ActionIcon size="xs">
                    <IconInfoCircle size={16} />
                  </ActionIcon>
                </Tooltip>
              </Flex>

              {user && (
                <UserInfoRow
                  id={user.id}
                  name={[user.name || '', '(you)'].join(' ')}
                  email={user.email}
                  picture={user.picture}
                  rightSection={
                    <Text fz="0.875rem" color="gray.6" mr="sm">
                      Owner
                    </Text>
                  }
                />
              )}

              {client.permissions.nodes.map((permission) => (
                <UserInfoRow
                  key={permission.id}
                  id={permission.id}
                  email={permission.email}
                  name={permission.displayName || ''}
                  rightSection={<UpdateClientPermissionRoleSelect client={client} permission={permission} />}
                />
              ))}
            </Flex>
          </Flex>

          <Flex justify="space-between" gap="xs">
            <CopyLinkButton onClick={handleCopyLink} />

            <Flex align="center" gap="xs">
              <Button color="gray.8" variant="subtle" onClick={onClose} disabled={isSharing}>
                Close
              </Button>

              <Button type="submit" color="red" loading={isSharing} disabled={!form.values.email}>
                Share
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export const ShareClientModal = memo(ShareClientModalBase);
