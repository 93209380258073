import { memo, useRef, useState } from 'react';
import { Button } from '@mantine/core';
import { IconCheck, IconLink } from '@tabler/icons-react';

import { CopyLinkButtonProps } from './CopyLinkButton.types';

const CopyLinkButtonBase = ({ onClick }: CopyLinkButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const resetCopyStateTimer = useRef<number | null>(null);

  const handleClick = (event: React.MouseEvent) => {
    onClick?.(event);

    setIsCopied(true);

    navigator.clipboard.writeText(window.location.href);

    if (resetCopyStateTimer.current) window.clearTimeout(resetCopyStateTimer.current);

    resetCopyStateTimer.current = window.setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Button
      variant="outline"
      leftIcon={isCopied ? <IconCheck size={20} /> : <IconLink size={20} />}
      onClick={handleClick}
    >
      Copy link
    </Button>
  );
};

export const CopyLinkButton = memo(CopyLinkButtonBase);
