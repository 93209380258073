import { escapeRegExp } from 'lodash-es';
import memoize from 'memoize';

import { Client, ClientItem } from '@/pageAI/@types';
import { CollectionAsset, FileAsset } from '@/shared/@types';
import { normalizeFileCollection } from '@/shared/services/files';
import { getFullName } from '@/shared/utils/name';

import PASSED_AWAY_CLIENT_FILES from './passedAwayClientFiles.json';

export type ClientTab = 'Organization' | 'Assigned to me' | 'Shared with me';

export const getClientDisplayName = (client: ClientItem) => {
  return getFullName(client.firstName, client.lastName).trim();
};

export const getClientDetailsUrl = (clientId: string) => `/clients/${clientId}`;

export const getClientFiles = memoize((client: Pick<Client, 'fileCollections'>) => {
  const mainFileCollectionAsset = normalizeFileCollection(client.fileCollections.nodes[0]);

  const fileAssets = mainFileCollectionAsset.files || [];

  const fileAssetMapping = getFileAssetMapping(fileAssets);

  return { mainFileCollectionAsset, fileAssets, fileAssetMapping };
}) as (client: Pick<Client, 'fileCollections'>) => {
  mainFileCollectionAsset: CollectionAsset;
  fileAssets: FileAsset[];
  fileAssetMapping: Record<string, FileAsset | undefined>;
};

export const getFileAssetMapping = memoize((fileAssets: FileAsset[]) => {
  const fileAssetMapping: Record<string, FileAsset | undefined> = {};

  fileAssets.forEach((fileAsset) => {
    fileAssetMapping[fileAsset.id] = fileAsset;
  });

  return fileAssetMapping;
}) as (fileAssets: FileAsset[]) => Record<string, FileAsset | undefined>;

export const quickFindFileAsset = (fileAssets: FileAsset[], fileId: string) => {
  const fileAssetMapping = getFileAssetMapping(fileAssets);

  return fileAssetMapping[fileId];
};

export const quickFindClientFile = (client: Pick<Client, 'fileCollections'>, fileId: string) => {
  const { fileAssetMapping } = getClientFiles(client);

  return fileAssetMapping[fileId];
};

export const extractClientFileInfo = (fileAsset: Pick<FileAsset, 'name' | 'metadata'>, fileIndex: number) => {
  if (fileAsset.metadata?.pageAIMetadata) {
    return fileAsset.metadata.pageAIMetadata;
  }

  let filename = fileAsset.name;

  const firstFiveChars = filename.slice(0, 5);

  filename = filename.replace(/&#38[_|;]/g, '&');

  const periodIndex = filename.lastIndexOf('.');
  let fileType = '';

  let isVA = false;

  if (filename.includes('Notification Letter') || filename.includes('Request for Physical Examination')) {
    isVA = false;
  } else {
    const regex = /\b(?:VA Form?|VA)\s\d{2,}/;

    isVA = regex.test(filename);
  }

  if (periodIndex !== -1 && periodIndex !== 0) {
    fileType = filename
      .slice(periodIndex + 1)
      .toUpperCase()
      .trim();

    if (firstFiveChars.split('').some((char) => Number.isNaN(Number(char)))) {
      return {
        isVA,
        indexNumber: `DOC-${String(fileIndex + 1).padStart(4, '0')}`,
        fileType,
        cleanedFileName: filename.slice(0, periodIndex).trim(),
      };
    }

    filename = filename.slice(0, periodIndex - 46).trim();
  }

  filename = filename.slice(6);

  const CROPPED_NAME_REPLACEMENT_MAPPING = {
    'Certificate of Release or Discharge From Active Du': 'Certificate of Release or Discharge From Active Duty',
    '(waiver, requests, check replace': '',
    '(e.g. VA 20-8993, VA 21-0290,': '',
    'VA 21-22 Appointment of Veterans Serv. Org. as Cla': "VA 21-22 Appointment of Veterans Serv. Org. as Claimant's",
    'VA 21-526 Veterans Application for Compensation or':
      'VA 21-526 Application for Disability Compensation and Related Compensation Benefits',
    'VA 21-686c Application Request To Add AndOr Remove': 'VA 21-686c Application Request To Add And Or Remove',
    'VA Form 21-0781, Statement in Support of Claim for':
      'VA Form 21-0781, Statement in Support of Claim for Service Connection for PTSD',
    'VA Form 21-4192, Request for Employment Informatio': 'VA Form 21-4192, Request for Employment Information',
  };

  const replaceCroppedNames = (filename: string) => {
    let modifiedFileName = filename;

    for (const [left, right] of Object.entries(CROPPED_NAME_REPLACEMENT_MAPPING)) {
      const regex = new RegExp(escapeRegExp(left), 'g');
      modifiedFileName = modifiedFileName.replace(regex, right);
    }

    return modifiedFileName;
  };

  filename = replaceCroppedNames(filename);

  return {
    isVA,
    indexNumber: `CF-${firstFiveChars}`,
    fileType,
    cleanedFileName: filename.trim(),
  };
};

export const isPassedAwayClients = (client: Client) => {
  const { fileAssets } = getClientFiles(client);

  return fileAssets.some((fileAsset) => PASSED_AWAY_CLIENT_FILES.includes(fileAsset.id));
};
