import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '../graphql/useGraphQLRequest';
import { featureFlagsQuery } from '@/pageAI/api/featureFlags';

export const useFeatureFlags = () => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery(['feature-flags'], () => {
    return request(featureFlagsQuery);
  });

  return { featureFlags: data?.featureFlags, isLoading, isError };
};
