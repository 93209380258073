import { useEffect, useCallback } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { IS_BUILT_ENV, IS_STAGING } from '@/shared/config';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { getUserDisplayName } from '@/pageAI/services/users';

if (IS_BUILT_ENV) {
  Sentry.init({
    environment: IS_STAGING ? 'staging' : 'production',
    dsn: 'https://caace7b832d6bed1322e41fb9f0ee31a@o4507975310049280.ingest.us.sentry.io/4507977034432512',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/app\.pageai\.co/, /^https:\/\/app\.staging\.pageai\.co/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const useSentry = () => {
  const { currentUser } = useCurrentUser();

  const sentryAuth = useCallback(() => {
    if (!currentUser?.email || !IS_BUILT_ENV) return;

    const scope = Sentry.getCurrentScope();

    scope.setUser({ id: currentUser.id, email: currentUser.email, username: getUserDisplayName(currentUser) });
  }, [currentUser]);

  useEffect(() => {
    sentryAuth();
  }, [sentryAuth]);

  return { sentryAuth };
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const SentryErrorBoundary = Sentry.ErrorBoundary;

export { Sentry };
