import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex, ScrollArea } from '@mantine/core';

import { UnifiedContentSearchProps } from './UnifiedContentSearch.types';
import { ClientFileSearchLayout } from '../../clients/ClientFileSearchLayout';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useUnifiedScrollPosition } from '@/pageAI/hooks/unified/useUnifiedScrollPosition/useUnifiedScrollPosition';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { useConditionTermSearchFeatureFlag } from '@/pageAI/hooks/featureFlags/useConditionTermSearchFeatureFlag';
import { useClientMedicalConditions } from '@/pageAI/hooks/clients/useClientMedicalConditions';

const UnifiedContentSearchBase = (props: UnifiedContentSearchProps) => {
  const [searchParams] = useSearchParams();
  const client = useSelectedClient(true);

  const medicalConditionId = searchParams.get('medicalConditionId');

  const { isLoading: isLoadingFileGroups, isError: isErrorFileGroups } = useFileGroups();
  const { isLoading: isLoadingFeatureFlag, isError: isErrorFeatureFlag } = useConditionTermSearchFeatureFlag();
  const { isLoading: isLoadingConditionSummary, isError: isErrorConditionSummary } = useClientMedicalConditions({
    clientId: medicalConditionId ? client.id : undefined,
    autoSelect: true,
  });
  const scrollAreaRef = useUnifiedScrollPosition(UnifiedTab.CONTENT_SEARCH);

  const isLoading = isLoadingFileGroups || isLoadingFeatureFlag || isLoadingConditionSummary;
  const isError = isErrorFileGroups || isErrorFeatureFlag || isErrorConditionSummary;

  if (isLoading)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <LoadingPlaceholder
          title="Loading content search..."
          description="Please wait while Page loads the page."
          fullScreen
        />
      </Flex>
    );

  if (isError) throw new Error('Could not load content search');

  return (
    <ScrollArea
      h="calc(100vh - 56px)"
      ref={scrollAreaRef}
      sx={{
        '.ghost-ScrollArea-viewport': {
          '> div': {
            height: '100%',
          },
        },
      }}
    >
      <ClientFileSearchLayout client={client} />
    </ScrollArea>
  );
};

export const UnifiedContentSearch = memo(UnifiedContentSearchBase);
