import { memo, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Flex, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { MedicalConditionMenu } from '../MedicalConditionMenu';
import { MergeConditionsButton } from '../MergeConditionsButton';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { MergeConditionsModal } from '../MergeConditionsModal';
import {
  isSidebarOpenedSelector,
  selectCondition,
  selectTermCategory,
  setSelectedTerms,
  setShowSearchTermsWithNoResults,
  setSummaryHighlighted,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { conditionsSelector, selectedConditionSelector } from '@/pageAI/states/unified';
import { areConditionsMergeable, UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useUnifiedTab } from '@/pageAI/hooks/unified/useUnifiedTab';
import { posthog } from '@/shared/plugins/posthog';
import { ConditionTermCategory } from '@/pageAI/hooks/conditions/useConditionSearchTerms';
import { ConditionSidebarToggle } from '../ConditionSidebarToggle';

const MedicalConditionSidebarBase = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const conditions = useUnifiedStore(conditionsSelector);
  const selectedCondition = useUnifiedStore(selectedConditionSelector);
  const opened = useUnifiedStore(isSidebarOpenedSelector);
  const [tab, setTab] = useUnifiedTab();

  const [isConfirmMergeModalOpened, { open: openConfirmMergeModal, close: closeConfirmMergeModal }] = useDisclosure();
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [conditionIdsToMerge, setConditionIdsToMerge] = useState<string[]>([]);

  const conditionsToMerge = useMemo(
    () => conditions.filter((condition) => conditionIdsToMerge.includes(condition.id)),
    [conditions, conditionIdsToMerge],
  );

  const selectedMedicalConditionId = selectedCondition?.id || conditions[0]?.id;

  const handleClickMergeButton = useCallback(() => {
    if (isMergeMode) {
      setIsMergeMode(false);
      setConditionIdsToMerge([]);

      return;
    }

    setIsMergeMode(true);
    setConditionIdsToMerge([selectedMedicalConditionId]);
  }, [isMergeMode, selectedMedicalConditionId]);

  const handleCancelMerge = useCallback(() => {
    setIsMergeMode(false);
    setConditionIdsToMerge([]);
  }, []);

  const handleConfirmMerge = useCallback(() => {
    setIsMergeMode(false);
    setConditionIdsToMerge([]);

    closeConfirmMergeModal();
  }, [closeConfirmMergeModal]);

  const handleMergeSuccess = useCallback((newMedicalConditionId: string) => {
    selectCondition(newMedicalConditionId);
    setSummaryHighlighted('');
  }, []);

  const handleSelect = useCallback(
    (medicalCondition: MedicalConditionItem) => {
      if (isMergeMode) {
        if (conditionIdsToMerge.includes(medicalCondition.id)) {
          return setConditionIdsToMerge(conditionIdsToMerge.filter((id) => id !== medicalCondition.id));
        }

        return setConditionIdsToMerge([...conditionIdsToMerge, medicalCondition.id]);
      }

      posthog.capture('[Conditions] Select condition', {
        conditionId: medicalCondition.id,
        conditionHeader: medicalCondition.headerCondition,
      });

      selectCondition(medicalCondition.id);
      setSummaryHighlighted('');
      setShowSearchTermsWithNoResults(false);
      selectTermCategory(ConditionTermCategory.TERMS_FROM_CFR);
      setSelectedTerms([]);

      const updatedSearchParams = setTab(UnifiedTab.CONDITION_SUMMARY);

      updatedSearchParams.delete('query');
      updatedSearchParams.delete('fileGroup');
      updatedSearchParams.delete('fileType');

      setSearchParams(updatedSearchParams, { replace: true });
    },
    [setTab, isMergeMode, conditionIdsToMerge, setSearchParams],
  );

  const mergeHeader = useMemo(() => {
    const isMergeButtonDisabled = !areConditionsMergeable(conditionsToMerge);

    const mergeButton = (
      <Button size="xs" onClick={openConfirmMergeModal} disabled={isMergeButtonDisabled}>
        Merge
      </Button>
    );

    return (
      <Flex px={16} mb="xs" align="center">
        <Text fz="0.75rem" fw={500} color="gray.6" sx={{ whiteSpace: 'nowrap' }}>
          Select conditions to merge.
        </Text>

        <Button size="xs" variant="subtle" color="gray.7" onClick={handleCancelMerge}>
          Cancel
        </Button>

        {isMergeButtonDisabled ? (
          <Tooltip
            withArrow
            label={
              <>
                Merging is only available when:
                <ul style={{ paddingLeft: 24, margin: 0 }}>
                  <li>At least 2 conditions are selected;</li>
                  <li>At least one condition from codesheet is selected.</li>
                </ul>
              </>
            }
          >
            <Box>{mergeButton}</Box>
          </Tooltip>
        ) : (
          mergeButton
        )}
      </Flex>
    );
  }, [conditionsToMerge, handleCancelMerge, openConfirmMergeModal]);

  return (
    <Flex
      direction="column"
      mt="xs"
      sx={(theme) => ({
        transition: 'all 0.1s ease-in-out',
        marginLeft: opened ? 0 : -320,
        width: 320,
        minWidth: 320,
        borderTop: `1px solid ${theme.colors.gray[3]}`,
        borderRight: `1px solid ${theme.colors.gray[3]}`,
        background: theme.fn.lighten(theme.colors.gray[0], 0.5),
        borderTopRightRadius: 6,
        zIndex: 102,
      })}
    >
      <Flex align="center" justify="space-between" pr="xs" pos="relative">
        <Flex align="center" sx={{ width: '100%' }} pl={16} mt={11} mb="xs" gap={4}>
          <Text fw={600} color="dark.4">
            Conditions
          </Text>

          {selectedCondition && <MergeConditionsButton onClick={handleClickMergeButton} isMergeMode={isMergeMode} />}
        </Flex>

        <ConditionSidebarToggle />
      </Flex>

      {isMergeMode && mergeHeader}

      <MedicalConditionMenu
        medicalConditions={conditions}
        loading={conditions.length === 0}
        selected={!isMergeMode ? [selectedMedicalConditionId] : conditionIdsToMerge}
        onSelect={handleSelect}
        checkboxMode={isMergeMode}
        maxHeight={isMergeMode ? 'calc(100vh - 194px)' : 'calc(100vh - 154px)'}
      />

      {isMergeMode && (
        <MergeConditionsModal
          opened={isConfirmMergeModalOpened}
          onClose={closeConfirmMergeModal}
          onConfirm={handleConfirmMerge}
          onSuccess={handleMergeSuccess}
          medicalConditions={conditions}
          conditionIdsToMerge={conditionIdsToMerge}
        />
      )}
    </Flex>
  );
};

export const MedicalConditionSidebar = memo(MedicalConditionSidebarBase);
