import { memo } from 'react';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { IconSortAscending2, IconSortDescending2 } from '@tabler/icons-react';

import { MedicalConditionTitleProps } from './MedicalConditionTitle.types';
import {
  selectedConditionSelector,
  setSummaryTabSortOrder,
  summaryTabSortOrderSelector,
  summaryTabViewModeSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { ShowNullFieldsFilter } from '../filters/ShowNullFieldsFilter';
import { RelatedConditionsTooltip } from '../RelatedConditionsTooltip';
import { ConditionViewModeTabs } from '../filters/ConditionViewModeTabs';

const MedicalConditionTitleBase = ({ isSidebarOpened = false }: MedicalConditionTitleProps) => {
  const selectedCondition = useUnifiedStore(selectedConditionSelector);
  const viewMode = useUnifiedStore(summaryTabViewModeSelector);
  const sortOrder = useUnifiedStore(summaryTabSortOrderSelector);

  const handleChangeSortOrder = () => {
    setSummaryTabSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  return (
    <Flex align="center" justify="space-between" sx={{ flexWrap: 'wrap' }} columnGap="xs">
      <Flex
        className="header"
        align="center"
        gap={2}
        ml={-6}
        pos="relative"
        sx={{ height: 36, flexGrow: 1, overflow: 'hidden' }}
      >
        <Flex
          align="center"
          gap={4}
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
          data-testid="condition-title"
        >
          <Text
            color="dark"
            fw={600}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {selectedCondition?.headerCondition || 'Conditions'}
          </Text>

          {selectedCondition?.diagnosticCode && (
            <Text
              color="dark"
              fw={600}
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              (DC: {selectedCondition?.diagnosticCode})
            </Text>
          )}

          <RelatedConditionsTooltip medicalCondition={selectedCondition} />
        </Flex>
      </Flex>

      <Flex align="center" justify="end" sx={{ alignSelf: 'flex-end', flexGrow: 1 }} gap={4}>
        {viewMode === 'Chronological' && (
          <Tooltip
            label={sortOrder === 'desc' ? 'Sorting latest to oldest' : 'Sorting oldest to latest'}
            withinPortal
            withArrow
          >
            <ActionIcon onClick={handleChangeSortOrder}>
              {sortOrder === 'desc' ? <IconSortDescending2 size={20} /> : <IconSortAscending2 size={20} />}
            </ActionIcon>
          </Tooltip>
        )}

        {viewMode === 'Table View' && (
          <Flex align="center" mr={4}>
            <ShowNullFieldsFilter />
          </Flex>
        )}

        <ConditionViewModeTabs />
      </Flex>
    </Flex>
  );
};

export const MedicalConditionTitle = memo(MedicalConditionTitleBase);
