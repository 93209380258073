import { memo, useMemo } from 'react';
import { Accordion, Box, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { ConditionAllCodesheetsProps } from './ConditionAllCodesheets.types';
import { addFileIndexToEventReference } from '@/pageAI/services/summaries';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getClientFiles } from '@/pageAI/services/clients';
import { SummaryFileReferenceBadge } from '@/pageAI/components/summaries/SummaryFileReferenceBadge';
import {
  isPrintingConditionSummarySelector,
  setSummaryHighlighted,
  summaryTabHighlightedSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';

const ConditionAllCodesheetsBase = ({ condition }: ConditionAllCodesheetsProps) => {
  const client = useSelectedClient(true);

  const { fileAssets } = getClientFiles(client);
  const [opened, { toggle }] = useDisclosure(false);

  const highlighted = useUnifiedStore(summaryTabHighlightedSelector);
  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);

  const hasAllCodesheets = condition.allCodesheets?.data?.length;

  const latestCodesheetReferenceWithIndex = useMemo(
    () =>
      condition.latestCodesheet?.data?.reference
        ? addFileIndexToEventReference(condition.latestCodesheet.data.reference, fileAssets)
        : null,
    [condition.latestCodesheet?.data?.reference, fileAssets],
  );

  return (
    <Flex direction="column" gap={4}>
      <Flex align="center" gap={6}>
        <Box>
          <Text fz="0.875rem" color="dark" component="span" mr={6}>
            {condition.latestCodesheet?.data?.summary}
          </Text>

          {latestCodesheetReferenceWithIndex && (
            <SummaryFileReferenceBadge
              client={client}
              reference={latestCodesheetReferenceWithIndex}
              isHighlighted={highlighted === 'latestCodesheet'}
              isPrinting={isPrinting}
              onClick={() => setSummaryHighlighted('latestCodesheet')}
            />
          )}

          {hasAllCodesheets && !isPrinting && (
            <UnderlinedTextButton onClick={toggle} sx={{ marginLeft: 6 }}>
              {opened ? (
                <>
                  Hide
                  <IconChevronUp size={14} />
                </>
              ) : (
                <>
                  See all
                  <IconChevronDown size={14} />
                </>
              )}
            </UnderlinedTextButton>
          )}
        </Box>
      </Flex>

      {hasAllCodesheets && (
        <Accordion
          value={opened || isPrinting ? 'default' : ''}
          sx={{
            '.ghost-Accordion-content': {
              padding: 0,
            },
          }}
        >
          <Accordion.Item value="default" key="default" sx={{ border: 'none' }}>
            <Accordion.Panel>
              <Box
                pl="md"
                sx={(theme) => ({
                  ol: {
                    fontSize: '0.875rem',
                    li: {
                      marginBottom: 4,
                    },
                  },
                })}
              >
                <ol>
                  {condition.allCodesheets?.data?.map((codesheet) => {
                    const referenceWithIndex = addFileIndexToEventReference(codesheet.reference, fileAssets);
                    const entryKey = ['allCodesheets', codesheet.fileId, codesheet.reference].join('-');
                    const isEntryHighlighted = highlighted === entryKey;

                    return (
                      <li key={[codesheet.fileId, codesheet.reference].join('')}>
                        <Box>
                          <Text fz="0.875rem" color="dark" component="span" mr={6}>
                            {codesheet.summary.split('-')[0]}
                          </Text>

                          {referenceWithIndex && (
                            <SummaryFileReferenceBadge
                              client={client}
                              reference={referenceWithIndex}
                              isHighlighted={isEntryHighlighted}
                              isPrinting={isPrinting}
                              onClick={() => setSummaryHighlighted(entryKey)}
                            />
                          )}
                        </Box>
                      </li>
                    );
                  })}
                </ol>
              </Box>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      )}
    </Flex>
  );
};

export const ConditionAllCodesheets = memo(ConditionAllCodesheetsBase);
