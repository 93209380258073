import { forwardRef, memo, useCallback } from 'react';
import { Badge, Text } from '@mantine/core';

import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { useClientFileAnchorReference } from '@/pageAI/hooks/files/useClientFileAnchorReference';
import {
  extractFileInfoOfClientFromHref,
  extractPageNumbersFromReference,
  getPageDisplayTextFromReference,
  normalizeCaseTimelineItemReferencedTexts,
} from '@/pageAI/services/summaries';
import { setHighlightedTexts, setPagesToHighlight } from '@/shared/states/filePreview';
import { fillNumberArray } from '@/shared/utils/array';
import { SummaryFileReferenceBadgeProps } from './SummaryFileReferenceBadge.types';
import { useSummaryFileReferenceBadgeState } from './SummaryFileReferenceBadge.utils';
import { MarkdownAnchorRenderer } from '@/shared/components/common/markdown/MarkdownAnchorRenderer';

const SummaryFileReferenceBadgeBase = (
  {
    client,
    reference,
    referencedTexts,
    isHighlighted = false,
    isPrinting = false,
    getPrintingHref,
    onClick,
    keywords = [],
    matchWholePhrase,
    matchWholeWords,
  }: SummaryFileReferenceBadgeProps,
  ref: React.ForwardedRef<HTMLSpanElement>,
) => {
  const { handleOpenFileReferenceByHref } = useClientFileAnchorReference(client);
  const isHrefActive = useSummaryFileReferenceBadgeState((state) => state.isHrefActive);
  const printPageNumberMapping = useSummaryFileReferenceBadgeState((state) => state.printPageNumberMapping);

  const openFileWithReference = useCallback(
    (href: string) => {
      const normalizedReferencedTexts = normalizeCaseTimelineItemReferencedTexts(referencedTexts || []);

      const { startPage, endPage } = extractPageNumbersFromReference(reference);

      setHighlightedTexts(normalizedReferencedTexts);

      if (!Number.isNaN(startPage) || !Number.isNaN(endPage)) {
        let pageIndicesToHighlight: number[] = [];

        if (!Number.isNaN(startPage) && !Number.isNaN(endPage))
          pageIndicesToHighlight = fillNumberArray(startPage - 1, endPage - 1);
        else if (Number.isNaN(endPage)) pageIndicesToHighlight = [startPage - 1];

        setPagesToHighlight(pageIndicesToHighlight);
      }

      return handleOpenFileReferenceByHref(href);
    },
    [reference, referencedTexts, handleOpenFileReferenceByHref],
  );

  const ReferenceAnchorRenderer = ({ href, children }: { href?: string; children?: React.ReactNode }) => {
    if (!href) return null;

    const handleClick = (event: React.MouseEvent) => {
      if (!href) return;

      event.preventDefault();

      if (openFileWithReference(href)) onClick?.();
    };

    const { fileAsset, pageIndex } = isPrinting
      ? extractFileInfoOfClientFromHref(client, href)
      : { fileAsset: undefined, pageIndex: undefined };

    const actualHref = isPrinting ? getPrintingHref?.(href, fileAsset, pageIndex) || window.location.href : href;

    let textToDisplay = children;

    if (isPrinting) {
      const pageAIMetadata = fileAsset?.metadata?.pageAIMetadata;

      const pageNumberOffset = fileAsset?.id ? printPageNumberMapping?.[fileAsset.id] || 1 : 1;

      textToDisplay = `${pageAIMetadata?.cleanedFileName}, ${getPageDisplayTextFromReference(
        reference,
        pageNumberOffset,
      )} [${pageAIMetadata?.indexNumber}]`;
    }

    if ((!isHrefActive || printPageNumberMapping) && isPrinting)
      return (
        <Text className="reference-badge" fz="0.75rem" color="dark">
          {textToDisplay}
        </Text>
      );

    return (
      <MarkdownAnchorRenderer
        href={actualHref}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        color="blue.8"
        className="reference-badge"
      >
        {textToDisplay}
      </MarkdownAnchorRenderer>
    );
  };

  return (
    <Badge
      ref={ref}
      component="span"
      {...(isHighlighted && { 'data-active': true })}
      sx={(theme) => ({
        '> p': { display: 'inline' },
        fontWeight: 500,
        textTransform: 'none',
        ...(isPrinting
          ? {
              whiteSpace: 'nowrap',
              border: `1px solid ${theme.colors.dark[2]}`,
              width: 'fit-content',
            }
          : {}),
      })}
      {...(isHighlighted && !isPrinting
        ? {
            color: 'yellow.3',
            variant: 'filled',
          }
        : { color: 'gray' })}
    >
      <MarkdownRenderer
        content={reference}
        components={{ a: ReferenceAnchorRenderer }}
        highlights={keywords}
        matchWholePhrase={matchWholePhrase}
        matchWholeWords={matchWholeWords}
      />
    </Badge>
  );
};

export const SummaryFileReferenceBadge = memo(forwardRef(SummaryFileReferenceBadgeBase));
