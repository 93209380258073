import { memo, useMemo } from 'react';
import { Badge, Flex } from '@mantine/core';

import { Client } from '@/pageAI/@types';
import { isPassedAwayClients } from '@/pageAI/services/clients';

interface DeceasedClientBadgeProps {
  client: Client;
}

const DeceasedClientBadgeBase = ({ client }: DeceasedClientBadgeProps) => {
  const isClientDeceased = useMemo(() => isPassedAwayClients(client), [client]);

  if (!isClientDeceased) return null;

  return (
    <Flex align="center" ml="xs">
      <Badge color="red" mr={-4}>
        Deceased Veteran
      </Badge>
    </Flex>
  );
};

export const DeceasedClientBadge = memo(DeceasedClientBadgeBase);
