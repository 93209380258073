import { useCallback, useEffect } from 'react';
import posthog from 'posthog-js';

import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { POSTHOG_ID } from '@/shared/config';
import { getUserDisplayName } from '@/pageAI/services/users';

if (POSTHOG_ID) {
  posthog.init(POSTHOG_ID, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'always', // 'always' to create profiles for anonymous users as well
  });
}

export const usePosthog = () => {
  const { currentUser } = useCurrentUser();

  const posthogIdentify = useCallback(() => {
    if (!currentUser?.email) return;

    posthog.identify(currentUser.email, { email: currentUser.email, name: getUserDisplayName(currentUser) });
  }, [currentUser]);

  useEffect(() => {
    posthogIdentify();
  }, [posthogIdentify]);

  return { posthogIdentify };
};

export { posthog };
