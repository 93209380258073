import { useCallback, useState } from 'react';

import { useResizeObserver } from '../useResizeObserver';

export const useElementRect = () => {
  const [rect, setRect] = useState<DOMRect>(() => {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
      toJSON: () => undefined,
    };
  });

  const resizeObserverCallback = useCallback((rect: DOMRect) => {
    setRect(rect);
  }, []);

  const [ref] = useResizeObserver(resizeObserverCallback);

  return { ref, rect };
};
