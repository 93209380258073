import { memo, useMemo } from 'react';
import { Box, Checkbox, Flex, Highlight, Tooltip } from '@mantine/core';

import { MedicalConditionMenuItemProps } from './MedicalConditionMenuItem.types';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { useElementSize } from '@mantine/hooks';
import { isConditionMerging, isLegacyCondition } from '@/pageAI/services/medicalConditions';
import { MergedConditionsBadge } from '../MergedConditionsBadge';
import { ServiceConnectedBadge } from '../ServiceConnectedBadge';

const MedicalConditionMenuItemBase = ({
  medicalCondition,
  highlightKeywords = [],
  selected = false,
  checkboxMode = false,
  disabled = false,
  onSelect,
}: MedicalConditionMenuItemProps) => {
  const { ref } = useElementSize<HTMLDivElement>();

  const isOverflowed = ref.current?.scrollWidth > ref.current?.offsetWidth;

  const isMerging = isConditionMerging(medicalCondition);
  const isLegacy = useMemo(() => isLegacyCondition(medicalCondition), [medicalCondition]);

  if (isMerging && !medicalCondition.mergedConditions?.length) return null;

  let textContent = (
    <Highlight
      ref={ref}
      highlight={highlightKeywords}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {medicalCondition.headerCondition}
    </Highlight>
  );

  if (isOverflowed)
    textContent = (
      <Tooltip label={medicalCondition.headerCondition} withinPortal position="top-start" openDelay={500}>
        {textContent}
      </Tooltip>
    );

  const content = (
    <MenuItem
      key={medicalCondition.id}
      onClick={() => {
        if (disabled) return;

        onSelect?.(medicalCondition);
      }}
      sx={(theme) => ({
        ...(disabled && {
          cursor: 'default',
          backgroundColor: 'transparent !important',
        }),
        ...(selected &&
          !checkboxMode && {
            backgroundColor: theme.colors.dark[5],
            color: theme.white,
            transition: 'all 0.1s',
            '&:hover:not([disabled])': {
              backgroundColor: theme.colors.dark[5],
              color: theme.white,
            },
          }),
      })}
    >
      <Flex
        className="condition-item"
        align="center"
        gap={4}
        sx={(theme) => ({ width: 280, ...(disabled && { color: theme.colors.dark[2] }) })}
      >
        {checkboxMode && <Checkbox checked={selected} size="xs" disabled={disabled} />}

        {textContent}

        <Flex sx={{ flexGrow: 1 }} />

        <ServiceConnectedBadge condition={medicalCondition} selected={!checkboxMode ? selected : undefined} />

        <MergedConditionsBadge condition={medicalCondition} />
      </Flex>
    </MenuItem>
  );

  if (isLegacy) {
    return (
      <Flex direction="column" className={isLegacy ? 'legacy-condition' : undefined}>
        <Box className="prefix" />

        {content}
      </Flex>
    );
  }

  return content;
};

export const MedicalConditionMenuItem = memo(MedicalConditionMenuItemBase);
