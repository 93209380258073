import { memo } from 'react';
import { Badge, Sx, Tooltip } from '@mantine/core';

import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { extractPercentNumber } from '@/shared/utils/string';

interface ServiceConnectedBadgeProps {
  condition: MedicalConditionItem;
  selected?: boolean;
  sx?: Sx;
}

const ServiceConnectedBadgeBase = ({ condition, selected = false, sx }: ServiceConnectedBadgeProps) => {
  if (!condition.isServiceConnected && !condition.rating) return null;

  const content = (
    <Badge
      size="xs"
      variant="outline"
      color="dark"
      ml={2}
      sx={(theme) => ({
        border: '1px solid',
        ...(selected
          ? {
              borderColor: theme.white,
              color: theme.white,
            }
          : {
              borderColor: theme.colors.dark[5],
            }),
        minWidth: 58,
        maxWidth: 58,
        '.ghost-Badge-inner': {
          overflow: 'unset',
          textOverflow: 'unset',
          whiteSpace: 'nowrap',
          minWidth: 'fit-content',
        },
        ...sx,
      })}
    >
      SC
      {condition.rating && <>&nbsp;-&nbsp;{extractPercentNumber(condition.rating || '')}%</>}
    </Badge>
  );

  if (condition.rating)
    return (
      <Tooltip label={`SC – ${condition.rating}`} withinPortal withArrow>
        {content}
      </Tooltip>
    );
};

export const ServiceConnectedBadge = memo(ServiceConnectedBadgeBase);
