import React, { memo, useMemo } from 'react';
import { Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { useParams } from 'react-router-dom';

import { AddOrRemoveFileFromConditionControllerProps } from './AddOrRemoveFileFromConditionController.types';
import { selectedConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { useManageConditionFiles } from '@/pageAI/hooks/conditions/useManageConditionFiles';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { doesConditionHaveFile } from '@/pageAI/services/medicalConditions';
import { AddEventToUnknownConditionDropdown } from '../AddEventToUnknownConditionDropdown';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { getFileDisplayName } from '@/pageAI/services/files';
import { posthog } from '@/shared/plugins/posthog';

const AddOrRemoveFileFromConditionControllerBase = ({
  fileAsset,
  pageIndices,
  docHintType,
  renderAddButton,
  renderRemoveButton,
}: AddOrRemoveFileFromConditionControllerProps) => {
  const { clientId } = useParams();
  const client = useSelectedClient();
  const selectedCondition = useUnifiedStore(selectedConditionSelector);

  const [isPopoverOpened, { close: closePopover, toggle: togglePopover }] = useDisclosure();

  const { addFilesToConditions, isAdding, removeFilesFromCondition, isRemoving } = useManageConditionFiles(
    client?.id || '',
  );

  const conditionHasFile = useMemo(
    () => (selectedCondition ? doesConditionHaveFile(selectedCondition, fileAsset.id) : false),
    [selectedCondition, fileAsset],
  );

  const handleRemoveFileFromCondition = (event: React.MouseEvent) => {
    if (!selectedCondition) return;

    closePopover();

    event.stopPropagation();

    modals.openConfirmModal({
      title: 'Remove file from condition',
      children: (
        <Text fz="0.875rem">
          Are you sure you want to remove this file (<strong>{getFileDisplayName(fileAsset)}</strong>) from the current
          condition (<strong>{selectedCondition.headerCondition}</strong>)?
        </Text>
      ),
      labels: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      cancelProps: {
        color: 'gray.7',
        variant: 'subtle',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: () => {
        posthog.capture('[Conditions] Remove file from condition', {
          clientId: client?.id,
          clientFirstName: client?.firstName,
          clientLastName: client?.lastName,
          conditionId: selectedCondition.id,
          conditionHeader: selectedCondition.headerCondition,
          fileId: fileAsset.id,
          fileName: fileAsset.name,
          fileIndex: fileAsset.metadata?.pageAIMetadata?.cleanedFileName,
        });

        removeFilesFromCondition(selectedCondition.id, [fileAsset.id]);
      },
    });
  };

  const handleClickAdd = (event: React.MouseEvent) => {
    event.stopPropagation();

    togglePopover();
  };

  const handleConfirmAddToConditions = (conditions: MedicalConditionItem[]) => {
    posthog.capture('[Conditions] Add file to conditions', {
      clientId: client?.id,
      clientFirstName: client?.firstName,
      clientLastName: client?.lastName,
      conditions: conditions.map((condition) => ({
        id: condition.id,
        headerCondition: condition.headerCondition,
      })),
      fileId: fileAsset.id,
      fileName: fileAsset.name,
      fileIndex: fileAsset.metadata?.pageAIMetadata?.cleanedFileName,
      ...(pageIndices ? { pageIndices: JSON.stringify(pageIndices) } : {}),
    });

    addFilesToConditions(
      conditions.map((condition) => condition.id),
      [{ id: fileAsset.id, pageIndices, addFromHintType: docHintType }],
    );

    closePopover();
  };

  if (!client || !clientId) return null;

  if (conditionHasFile) {
    return renderRemoveButton({ isRemoving, onClick: handleRemoveFileFromCondition });
  }

  return (
    <Popover opened={isPopoverOpened} onClose={closePopover} shadow="sm" position="left" withinPortal zIndex={302}>
      <Popover.Target>{renderAddButton({ isAdding, onClick: handleClickAdd })}</Popover.Target>

      <Popover.Dropdown p={0}>
        <AddEventToUnknownConditionDropdown onClose={closePopover} onConfirm={handleConfirmAddToConditions} />
      </Popover.Dropdown>
    </Popover>
  );
};

export const AddOrRemoveFileFromConditionController = memo(AddOrRemoveFileFromConditionControllerBase);
