import { useMemo } from 'react';

import { useFeatureFlags } from '@/shared/hooks/featureFlags';

export const useConditionTermSearchFeatureFlag = () => {
  const { featureFlags, isLoading, isError } = useFeatureFlags();

  const enabled = useMemo(
    () => featureFlags?.some((flag) => flag.name === 'enabledSemanticSearch' && flag.value),
    [featureFlags],
  );

  return { enabled, isLoading, isError };
};
