import { memo } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconShare } from '@tabler/icons-react';

import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { ShareClientModal } from '../ShareClientModal';
import { posthog } from '@/shared/plugins/posthog';

interface ShareClientButtonProps {}

const ShareClientButtonBase = (props: ShareClientButtonProps) => {
  const client = useSelectedClient();
  const [isShareClientModalOpened, { open: openShareClientModal, close: closeShareClientModal }] = useDisclosure();

  const handleOpen = () => {
    openShareClientModal();

    if (!client) return null;

    posthog.capture('[Clients] Open share client modal', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
    });
  };

  if (!client?.viewerCanShare) return null;

  return (
    <>
      <Tooltip label="Share this client" withinPortal withArrow>
        <ActionIcon onClick={handleOpen} color="gray.6" data-testid="share-client">
          <IconShare size={16} />
        </ActionIcon>
      </Tooltip>

      <ShareClientModal opened={isShareClientModalOpened} client={client} onClose={closeShareClientModal} />
    </>
  );
};

export const ShareClientButton = memo(ShareClientButtonBase);
