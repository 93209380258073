import { createRoot } from 'react-dom/client';
import 'requestidlecallback-polyfill';

import { App } from '@/shared/App';

const rootElement = document.getElementById('root') as HTMLDivElement;

const root = createRoot(rootElement);

root.render(<App />);
