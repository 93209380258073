import React, { memo, useCallback } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';

import { AddOrRemoveFileFromConditionButtonProps } from './AddOrRemoveFileFromConditionButton.types';
import {
  AddOrRemoveFileFromConditionController,
  RenderAddButtonProps,
  RenderRemoveButtonProps,
} from '../AddOrRemoveFileFromConditionController';

const AddOrRemoveFileFromConditionButtonBase = ({
  fileAsset,
  pageIndices,
  docHintType,
  color = 'gray',
  variant,
  removeButtonColor = 'gray',
  removeButtonVariant,
}: AddOrRemoveFileFromConditionButtonProps) => {
  const renderAddButton = useCallback(
    ({ onClick, isAdding }: RenderAddButtonProps) => {
      return (
        <Tooltip label="Add file to condition(s)" withArrow withinPortal>
          <ActionIcon size="xs" loading={isAdding} onClick={onClick} color={color} variant={variant}>
            <IconPlus size={14} />
          </ActionIcon>
        </Tooltip>
      );
    },
    [color, variant],
  );

  const renderRemoveButton = useCallback(
    ({ onClick, isRemoving }: RenderRemoveButtonProps) => (
      <Tooltip label="Remove file from condition" withArrow withinPortal>
        <ActionIcon
          size="xs"
          loading={isRemoving}
          onClick={onClick}
          color={removeButtonColor}
          variant={removeButtonVariant || variant}
        >
          <IconMinus size={14} />
        </ActionIcon>
      </Tooltip>
    ),
    [removeButtonColor, variant, removeButtonVariant],
  );

  return (
    <AddOrRemoveFileFromConditionController
      fileAsset={fileAsset}
      pageIndices={pageIndices}
      docHintType={docHintType}
      renderAddButton={renderAddButton}
      renderRemoveButton={renderRemoveButton}
    />
  );
};

export const AddOrRemoveFileFromConditionButton = memo(AddOrRemoveFileFromConditionButtonBase);
