import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EventBus } from 'native-pubsub';
import { updateSearchParams } from '@/shared/lib/browser';

export const useContentSearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageString = searchParams.get('page');

  const parsedPage = Number(pageString);
  const page = Number.isNaN(parsedPage) || parsedPage === 0 ? 1 : parsedPage;

  const setPage = useCallback(
    (newPage: number, overrideSearchParams?: URLSearchParams) => {
      const updatedSearchParams = overrideSearchParams || searchParams;

      updatedSearchParams.set('page', newPage.toString());

      setSearchParams(updatedSearchParams);

      return updatedSearchParams;
    },
    [searchParams, setSearchParams],
  );

  return [page, setPage] as const;
};

export const useSearchWithSummary = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const withSummaryString = searchParams.get('withSummary');
  const withSummary = withSummaryString === 'true';

  const setWithSummary = useCallback(
    (newValue: boolean) => {
      const newSearchParams = updateSearchParams(searchParams)('withSummary', newValue || undefined);

      setSearchParams(newSearchParams);

      return newSearchParams;
    },
    [searchParams, setSearchParams],
  );

  return [withSummary, setWithSummary] as const;
};

export const confirmContentSearchEventBus = new EventBus<{ query: string; withSummary?: boolean }>();
