import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withPageWrapper } from '@/shared/hocs/withPageWrapper';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { setSelectedClient } from '@/pageAI/states/client';
import { ClientNotFoundPlaceholder } from '@/pageAI/components/clients/ClientNotFoundPlaceholder';
import { useClientPersistentPDFScale } from '@/pageAI/hooks/files/useClientPersistentPDFScale';
import { UnifiedViewPage } from '../UnifiedViewPage';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { OldMedicalConditionPage } from '../OldMedicalConditionPage';

const SummaryPageBase = () => {
  const { clientId, summaryType } = useParams();
  const { client, isLoading } = useClient({ clientId });
  useClientPersistentPDFScale(clientId || '');

  useLayoutEffect(() => {
    if (!isLoading && client) setSelectedClient(client);
  }, [client, isLoading]);

  if (!clientId) return null;

  if (isLoading)
    return (
      <LoadingPlaceholder
        title="Loading client..."
        description="Please wait while Page retrieves the client."
        fullScreen
      />
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  if (summaryType && ['oldMedicalConditionSummary', 'oldConditionSummary'].includes(summaryType)) {
    return <OldMedicalConditionPage />;
  }

  return <UnifiedViewPage />;
};

export const SummaryPage = withPageWrapper(SummaryPageBase);
