import { Button, DefaultMantineColor, Sx } from '@mantine/core';
import { memo } from 'react';

interface UnderlinedTextButtonProps {
  onClick?: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
  sx?: Sx;
  color?: DefaultMantineColor;
}

const UnderlinedTextButtonBase = ({ onClick, children, color = 'dark.3', sx }: UnderlinedTextButtonProps) => {
  return (
    <Button
      variant="outline"
      color={color}
      onClick={onClick}
      sx={{
        display: 'inline-block',
        height: 'fit-content',
        padding: 0,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderRadius: 0,
        '&:hover': {
          background: 'transparent',
        },
        '.ghost-Button-label': {
          gap: 4,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export const UnderlinedTextButton = memo(UnderlinedTextButtonBase);
