import { memo } from 'react';
import { Flex } from '@mantine/core';
import { IconNews } from '@tabler/icons-react';

import { DataEmptyPlaceholder } from '@/pageAI/components/clients/DataEmptyPlaceholder';
import { ConditionSearchTermContainer } from '@/pageAI/components/medicalConditions/ConditionSearchTermContainer';
import { selectedConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { useConditionTermSearchFeatureFlag } from '@/pageAI/hooks/featureFlags/useConditionTermSearchFeatureFlag';

interface FileSearchInitialPlaceholderProps {}

const FileSearchInitialPlaceholderBase = (props: FileSearchInitialPlaceholderProps) => {
  const selectedCondition = useUnifiedStore(selectedConditionSelector);
  const { enabled } = useConditionTermSearchFeatureFlag();

  const showConditionSearchTerms = selectedCondition && enabled;

  return (
    <Flex direction="column" gap="sm">
      <DataEmptyPlaceholder
        icon={<IconNews size={80} />}
        title="Enter keywords"
        description={
          showConditionSearchTerms
            ? "Type your keywords in the search box or select one of the current condition's search terms to start."
            : 'Type your keywords in the search box and press Enter to start.'
        }
        sx={{
          minHeight: 'unset',
        }}
      />

      {enabled && (
        <Flex align="center" justify="center" sx={{ maxWidth: 880 }}>
          <ConditionSearchTermContainer alwaysShow mah={348} />
        </Flex>
      )}
    </Flex>
  );
};

export const FileSearchInitialPlaceholder = memo(FileSearchInitialPlaceholderBase);
