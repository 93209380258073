import { memo, useCallback, useMemo } from 'react';
import { Flex } from '@mantine/core';

import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getClientFiles } from '@/pageAI/services/clients';
import { AssetTable } from '../AssetTable';
import { truthy } from '@/shared/utils/boolean';
import { AssetItem, AssetType } from '@/shared/@types';
import { AddOrRemoveFileFromConditionButton } from '../../medicalConditions/errorCorrection/AddOrRemoveFileFromConditionButton';
import { sortDate } from '@/shared/utils/date';
import { DocHintType } from '@/pageAI/gql/graphql';

interface PotentialMissingFileSectionProps {
  fileIds: string[];
  docHintType: DocHintType;
  width?: number;
}

const PotentialMissingFileSectionBase = ({ fileIds, docHintType, width = 0 }: PotentialMissingFileSectionProps) => {
  const client = useSelectedClient(true);
  const { fileAssetMapping } = useMemo(() => getClientFiles(client), [client]);

  const fileAssets = useMemo(() => {
    return fileIds
      .map((fileId) => fileAssetMapping[fileId])
      .filter(truthy)
      .map((fileAsset) => {
        return {
          ...fileAsset,
          index: fileAsset.metadata?.pageAIMetadata?.indexNumber,
          name: fileAsset.metadata?.pageAIMetadata?.cleanedFileName || fileAsset.name,
        };
      })
      .toSorted((fileA, fileB) => sortDate(fileA.createdAt || new Date(), fileB.createdAt || new Date()));
  }, [fileAssetMapping, fileIds]);

  const renderActionCell = useCallback(
    (assetItem: AssetItem) => {
      if (assetItem.type === AssetType.COLLECTION) return null;

      return (
        <Flex gap={4} justify="flex-end">
          <AddOrRemoveFileFromConditionButton
            fileAsset={assetItem}
            docHintType={docHintType}
            color="gray.7"
            removeButtonColor="dark.4"
            variant="filled"
            removeButtonVariant="outline"
          />
        </Flex>
      );
    },
    [docHintType],
  );

  return (
    <Flex
      py="xs"
      px={1}
      direction="column"
      gap="xs"
      sx={{
        '.asset-table-header-row': {
          top: '0px !important',
        },
        '.file-name': {
          maxWidth: width - 772,
        },
        '.important-icon': {
          display: 'none',
        },
      }}
    >
      <AssetTable
        fileAssets={fileAssets}
        hiddenColumns={['Status', 'Classification', 'Size']}
        renderActionCell={renderActionCell}
        colgroup={
          <colgroup>
            <col style={{ width: '3%', minWidth: 100 }} />
            <col style={{ width: '70%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '7%', minWidth: 40 }} />
          </colgroup>
        }
      />
    </Flex>
  );
};

export const PotentialMissingFileSection = memo(PotentialMissingFileSectionBase);
