import { memo, useEffect, useId, useState } from 'react';
import { ActionIcon, Flex, Text, Tooltip, clsx } from '@mantine/core';
import { IconCheck, IconChevronRight, IconMessage, IconPencil, IconX } from '@tabler/icons-react';

import { CommentRowProps } from './CommentRow.types';
import CommentEditor from '../CommentEditor';
import { useUpdateComment } from '@/pageAI/hooks/caseTimeline/useUpdateComment';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { UserAvatar } from '@/pageAI/components/users/UserAvatar';
import { getUserDisplayName } from '@/pageAI/services/users/users.services';
import { DeleteCommentButton } from '../DeleteCommentButton';
import { CommentTime } from '../CommentTime';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { posthog } from '@/shared/plugins/posthog';

import styles from './CommentRow.module.scss';

const CommentRowBase = ({
  event,
  comment,
  showReply = false,
  compact = true,
  onReply,
  inSidebar = false,
}: CommentRowProps) => {
  const { updateComment } = useUpdateComment();
  const client = useSelectedClient(true);
  const { isPrinting } = useCaseTimelineContext();
  const { currentUser } = useCurrentUser();
  const commentUniqueId = useId();

  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(comment.content);
  const [shouldFlash, setShouldFlash] = useState(comment.shouldFlash);

  const authorDisplayName = getUserDisplayName(comment.author);

  useEffect(() => {
    setContent(comment.content);
  }, [comment.content]);

  useEffect(() => {
    setShouldFlash(comment.shouldFlash);

    if (!comment.shouldFlash) return;

    const timeout = window.setTimeout(() => {
      setShouldFlash(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [comment.shouldFlash]);

  const submitContentChange = () => {
    posthog.capture('[Timeline] Edit comment', {
      newContent: content,
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      eventId: event.id,
      eventReference: event.reference,
    });

    updateComment(client, event, { id: comment.id, content });

    setIsEditing(false);
  };

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isEditing || !event.metaKey || event.key !== 'Enter') return;

    submitContentChange();
  };

  const handleCancelEditing = (event: React.MouseEvent) => {
    event.stopPropagation();

    setIsEditing(false);
    setContent(comment.content);
  };

  const isCurrentUser = currentUser?.id === comment.author.id;
  const isContentLong = comment.content.length > 100;
  const shouldBreakLine = isPrinting && isContentLong;

  const renderCommentActions = () => {
    if (!isCurrentUser) return null;

    if (isEditing) {
      return (
        <Flex align="center">
          <Tooltip label="Cancel" withArrow withinPortal>
            <ActionIcon
              size="xs"
              onClick={handleCancelEditing}
              className="unprintable"
              data-testid={inSidebar ? 'cancel-editing-sidebar' : 'cancel-editing'}
            >
              <IconX size={12} />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Confirm" withArrow withinPortal>
            <ActionIcon
              size="xs"
              color="gray.8"
              variant="light"
              onClick={(event) => {
                event.stopPropagation();
                submitContentChange();
              }}
              className="unprintable"
              data-testid={inSidebar ? 'confirm-editing-sidebar' : 'confirm-editing'}
            >
              <IconCheck size={12} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      );
    }

    return (
      <Flex align="center" className="comment-actions" sx={compact ? {} : { opacity: 0, pointerEvents: 'none' }}>
        <Tooltip label="Edit" withArrow withinPortal>
          <ActionIcon
            size="xs"
            onClick={(event) => {
              event.stopPropagation();
              setIsEditing(true);
            }}
            className="unprintable"
            data-testid={inSidebar ? 'edit-comment-sidebar' : 'edit-comment'}
          >
            <IconPencil size={12} />
          </ActionIcon>
        </Tooltip>

        <DeleteCommentButton client={client} event={event} commentId={comment.id} />
      </Flex>
    );
  };

  const renderCompactComment = () => {
    return (
      <Flex
        gap={shouldBreakLine ? 4 : 6}
        direction={shouldBreakLine || !compact ? 'column' : 'row'}
        sx={{
          ...(compact ? {} : { width: '100%' }),
        }}
      >
        <Flex gap={6} sx={{ ...(compact ? {} : { width: '100%' }) }} justify="space-between">
          <Flex gap={6}>
            <UserAvatar size={20} user={comment.author} />

            <Text fz="0.75rem" color="dark.4" fw={500} sx={{ whiteSpace: 'nowrap' }}>
              {authorDisplayName}
            </Text>

            <Flex align="center" sx={{ alignSelf: 'flex-start' }} gap={6} mt={2}>
              <CommentTime comment={comment} compact={compact} />

              {!shouldBreakLine && compact && (
                <Flex align="center" sx={(theme) => ({ color: theme.colors.dark[3] })} mx={-2}>
                  <IconChevronRight size={12} />
                </Flex>
              )}
            </Flex>
          </Flex>

          {!compact ? renderCommentActions() : null}
        </Flex>

        <CommentEditor value={content} onChange={handleContentChange} readonly={!isEditing} />

        {compact ? renderCommentActions() : null}
      </Flex>
    );
  };

  return (
    <Flex
      id={commentUniqueId}
      className="comment-row"
      mb={4}
      align="center"
      gap={4}
      sx={{
        width: '100%',
        '.comment-actions': {
          transition: 'all 0.2s ease-in-out',
        },
        '&:hover': {
          '.comment-actions': {
            opacity: 1,
            pointerEvents: 'all',
          },
        },
      }}
    >
      <Flex
        className={clsx(shouldFlash ? styles.flash : '', 'comment-container')}
        sx={(theme) => ({
          padding: '4px 8px',
          backgroundColor: theme.colors.gray[0],
          borderRadius: 4,
          width: compact ? 'fit-content' : '100%',
        })}
        align="center"
        gap="xs"
        onKeyDown={handleKeyDown}
      >
        {renderCompactComment()}
      </Flex>

      {showReply && compact && (
        <Tooltip label="Reply" withinPortal withArrow>
          <ActionIcon
            size="xs"
            color="gray.7"
            sx={{ alignSelf: 'end' }}
            mb={4}
            onClick={() => onReply?.(comment)}
            className="unprintable"
            data-testid={inSidebar ? 'comment-reply-sidebar' : 'comment-reply'}
          >
            <IconMessage size={14} />
          </ActionIcon>
        </Tooltip>
      )}
    </Flex>
  );
};

export const CommentRow = memo(CommentRowBase);
