import { Fragment, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Highlight, Skeleton, Table, Text, Tooltip } from '@mantine/core';
import { IconArrowRight, IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';

import { ClientsTableProps } from './ClientsTable.types';
import { DeleteClientButton } from '../DeleteClientButton';
import { ClientItemAlert } from '../ClientItemAlert';
import { formatDate, formatDateTime } from '@/shared/utils/date';
import { getClientDetailsUrl, getClientDisplayName } from '@/pageAI/services/clients';
import { ClientAssignee } from '../ClientAssignee';
import { ClientOrderField, OrderDirection } from '@/pageAI/gql/graphql';

const ClientsTableBase = ({
  clients,
  query,
  sortBy = ClientOrderField.CreatedAt,
  sortOrder = OrderDirection.Desc,
  isLoadingMore = false,
  onSortChange,
}: ClientsTableProps) => {
  const navigate = useNavigate();

  const rows = useMemo(
    () =>
      clients.map((client) => {
        return (
          <tr
            key={client.id}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(getClientDetailsUrl(client.id))}
            data-testid={`client-${client.id}`}
          >
            <td>
              <Text fw={600} color="dark.4" data-testid="client-name">
                <Highlight highlight={query ? [query] : []}>{getClientDisplayName(client)}</Highlight>
              </Text>
            </td>
            <td>
              <Tooltip label={formatDateTime(client.createdAt)} withinPortal withArrow>
                <Text color="dark.5" sx={{ width: 'fit-content' }}>
                  {formatDate(client.createdAt)}
                </Text>
              </Tooltip>
            </td>
            <td>
              <ClientAssignee client={client} />
            </td>
            <td style={{ textAlign: 'right' }}>
              <Flex align="center" justify="flex-end" gap={6}>
                <ClientItemAlert client={client} />

                <DeleteClientButton client={client} />

                <Button variant="subtle" size="xs" rightIcon={<IconArrowRight size={14} />}>
                  View client
                </Button>
              </Flex>
            </td>
          </tr>
        );
      }),
    [clients, navigate, query],
  );

  const handleClickSort = (field: ClientOrderField) => () => {
    if (field === sortBy)
      return onSortChange?.(field, sortOrder === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc);

    onSortChange?.(field, OrderDirection.Desc);
  };

  const renderSortIcon = (field: ClientOrderField) => {
    if (field !== sortBy) return <IconSelector size={14} />;

    if (sortOrder === OrderDirection.Desc) return <IconChevronDown size={14} />;

    return <IconChevronUp size={14} />;
  };

  const renderLoadingRows = () => {
    if (!isLoadingMore) return null;

    const loadingRow = (
      <tr
        style={{
          userSelect: 'none',
          height: 50,
        }}
      >
        <td>
          <Skeleton width="80%" height={22} />
        </td>

        <td>
          <Skeleton width={100} height={22} />
        </td>

        <td>
          <Skeleton width={144} height={22} />
        </td>

        <td style={{ textAlign: 'right' }}>
          <Flex align="center" justify="end" sx={{ width: '100%' }}>
            <Skeleton width={144} height={22} />
          </Flex>
        </td>
      </tr>
    );

    return Array.from({ length: 5 }).map((_, index) => <Fragment key={index}>{loadingRow}</Fragment>);
  };

  return (
    <Table
      highlightOnHover
      sx={(theme) => ({
        th: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.colors.gray[1],
          },
        },
      })}
      data-testid="clients-table"
    >
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '40%' }} />
        <col style={{ width: '10%' }} />
      </colgroup>

      <thead>
        <tr>
          <th>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
              component="span"
              onClick={handleClickSort(ClientOrderField.Name)}
            >
              Name
              {renderSortIcon(ClientOrderField.Name)}
            </Box>
          </th>
          <th>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
              component="span"
              onClick={handleClickSort(ClientOrderField.CreatedAt)}
            >
              Created Date
              {renderSortIcon(ClientOrderField.CreatedAt)}
            </Box>
          </th>
          <th style={{ cursor: 'initial', background: 'transparent' }}>Assigned To</th>
          <th style={{ cursor: 'initial', background: 'transparent' }}></th>
        </tr>
      </thead>

      <tbody>
        {rows}

        {renderLoadingRows()}
      </tbody>
    </Table>
  );
};

export const ClientsTable = memo(ClientsTableBase);
