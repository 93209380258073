import { getClientFileSearchPath } from '@/pageAI/services/files';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useCallback } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const useNavigateToUnifiedContentSearch = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { clientId } = useParams();

  const navigateToContentSearch = useCallback(
    (query?: string | null) => {
      if (location.pathname.includes('/summaries')) {
        if (query) searchParams.set('query', query);
        searchParams.set('tab', UnifiedTab.CONTENT_SEARCH);
        setSearchParams(searchParams);

        return;
      }

      if (!clientId) return;

      navigate(getClientFileSearchPath(clientId, query));
    },
    [navigate, clientId, location, searchParams, setSearchParams],
  );

  return { navigateToContentSearch };
};
