import { memo, ComponentType } from 'react';

import { PageErrorBoundary } from '@/shared/components/common/placeholders/PageErrorBoundary';
import { SentryErrorBoundary } from '@/shared/plugins/sentry';

export const withPageWrapper = <P extends Record<string, unknown>>(Component: ComponentType<P>) => {
  const WithPageWrapper = (props: P) => {
    return (
      <SentryErrorBoundary fallback={PageErrorBoundary}>
        <Component {...props} />
      </SentryErrorBoundary>
    );
  };

  return memo(WithPageWrapper);
};
