import { create } from 'zustand';

import { getItemFromDB, saveItemToDB } from '@/shared/lib/storage';

const SEARCH_KEYWORD_HISTORY_DB_KEY = 'searchKeywordHistory';

const getSearchKeywordHistory = async () => {
  const value = await getItemFromDB(SEARCH_KEYWORD_HISTORY_DB_KEY);

  return value ? JSON.parse(value as string) : [];
};

const saveSearchKeywordHistory = async (searchKeywords: string[]) => {
  await saveItemToDB(SEARCH_KEYWORD_HISTORY_DB_KEY, JSON.stringify(searchKeywords));
};

const useSearchKeywordHistoryStore = create<{ searchKeywordHistory: string[] }>((set) => ({
  searchKeywordHistory: [],
}));

export const setSearchKeywordHistory = (searchKeywords: string[]) => {
  useSearchKeywordHistoryStore.setState({ searchKeywordHistory: searchKeywords });
  saveSearchKeywordHistory(searchKeywords);
};

export const pushSearchKeyword = (searchKeyword: string) => {
  if (!searchKeyword) return;

  const { searchKeywordHistory } = useSearchKeywordHistoryStore.getState();

  setSearchKeywordHistory([...new Set([searchKeyword.trim(), ...searchKeywordHistory])]);
};

export const useSearchKeywordHistory = () => {
  const { searchKeywordHistory } = useSearchKeywordHistoryStore();

  return searchKeywordHistory;
};

(async () => {
  const searchKeywordHistory = await getSearchKeywordHistory();

  setSearchKeywordHistory(searchKeywordHistory);
})();
