import { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { MarkdownRendererProps } from './MarkdownRenderer.types';
import { MarkdownAnchorRenderer } from '../MarkdownAnchorRenderer';
import { MarkdownRendererContextProvider } from '../MarkdownRendererContextProvider';
import {
  MarkdownEmRenderer,
  MarkdownLiRenderer,
  MarkdownParagraphRenderer,
  MarkdownSpanRenderer,
  MarkdownStrongRenderer,
} from '../MarkdownComponentsWithHighlights';

const MarkdownRendererBase: React.FC<MarkdownRendererProps> = ({
  content,
  components,
  highlights,
  matchWholePhrase,
  matchWholeWords,
}) => {
  return (
    <MarkdownRendererContextProvider
      highlights={highlights}
      matchWholePhrase={matchWholePhrase}
      matchWholeWords={matchWholeWords}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: MarkdownAnchorRenderer,
          p: MarkdownParagraphRenderer,
          strong: MarkdownStrongRenderer,
          em: MarkdownEmRenderer,
          li: MarkdownLiRenderer,
          span: MarkdownSpanRenderer,
          ...components,
        }}
      >
        {content}
      </ReactMarkdown>
    </MarkdownRendererContextProvider>
  );
};

export const MarkdownRenderer = memo(MarkdownRendererBase);
