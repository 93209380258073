import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { toggleConditionSidebar } from '@/pageAI/states/unified';

export const useUnifiedTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  let tab = searchParams.get('tab') as UnifiedTab | undefined;

  if (!tab) tab = UnifiedTab.CONDITION_SUMMARY;

  const setTab = useCallback(
    (tab: UnifiedTab) => {
      searchParams.set('tab', tab);
      setSearchParams(searchParams);

      if (tab === UnifiedTab.CONDITION_SUMMARY) {
        toggleConditionSidebar(true);
      }

      return searchParams;
    },
    [searchParams, setSearchParams],
  );

  return [tab, setTab] as const;
};
