import { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Flex } from '@mantine/core';

import { useClient } from '@/pageAI/hooks/clients/useClient';
import { ClientNotFoundPlaceholder } from '@/pageAI/components/clients/ClientNotFoundPlaceholder';
import { useClientMedicalConditions } from '@/pageAI/hooks/clients/useClientMedicalConditions';
import { PageAIHeader } from '@/pageAI/components/layout/PageAIHeader';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { truthy } from '@/shared/utils/boolean';
import { OldMedicalConditionsPanel } from '@/pageAI/components/medicalConditions/old/OldMedicalConditionsPanel';

const OldMedicalConditionPageBase = () => {
  const { clientId } = useParams();
  const { client, isLoading: isLoadingClient, isError: isClientError } = useClient({ clientId });
  const {
    medicalConditions,
    isLoading: isLoadingMedicalConditions,
    isError: isMedicalConditionsError,
  } = useClientMedicalConditions({ clientId, autoSelect: true });

  const isLoading = isLoadingClient || isLoadingMedicalConditions;
  const isError = isClientError || isMedicalConditionsError;

  if (isLoading)
    return (
      <LoadingPlaceholder
        title="Loading condition summary..."
        description={[
          'Please wait while Page retrieves the condition summary',
          client ? ` of ${getClientDisplayName(client)}` : '',
          '.',
        ]
          .filter(truthy)
          .join('')}
        fullScreen
      />
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  if (isError || !medicalConditions) throw new Error('Could not load condition summary');

  const items = [
    { title: 'Clients', href: '/clients' },
    { title: getClientDisplayName(client), href: `/clients/${client.id}` },
    { title: 'Condition Summary', href: window.location.href },
  ].map((item, index) => (
    <Anchor
      key={item.href}
      to={item.href}
      sx={{ ...(index === 2 ? { fontWeight: 600 } : { fontWeight: 500 }) }}
      component={Link}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <Flex direction="column">
      <PageAIHeader headerItems={items} />

      <OldMedicalConditionsPanel client={client} medicalConditions={medicalConditions} />
    </Flex>
  );
};

export const OldMedicalConditionPage = memo(OldMedicalConditionPageBase);
