import { create } from 'zustand';

interface SummaryFileReferenceBadgeState {
  isHrefActive: boolean;
  printPageNumberMapping: Record<string, number> | null;
}

export const useSummaryFileReferenceBadgeState = create<SummaryFileReferenceBadgeState>((set) => ({
  isHrefActive: true,
  printPageNumberMapping: null,
}));

export const setIsHrefActive = (isHrefActive: boolean) => useSummaryFileReferenceBadgeState.setState({ isHrefActive });

export const setPrintPageNumberMapping = (printPageNumberMapping: Record<string, number> | null) =>
  useSummaryFileReferenceBadgeState.setState({ printPageNumberMapping });
