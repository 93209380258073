import { graphql } from '@/pageAI/gql';

export const featureFlagsQuery = graphql(/* GraphQL */ `
  query FeatureFlags {
    featureFlags {
      ... on BooleanFeatureFlag {
        name
        value
      }
    }
  }
`);
