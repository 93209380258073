import { memo, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Flex, Text } from '@mantine/core';
import { IconCircleFilled } from '@tabler/icons-react';

import { conditionsSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { categorizeConditions } from '@/pageAI/services/medicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { MedicalConditionItemAsSummary } from '../../MedicalConditionItemAsSummary';
import { removeAllSpaces } from '@/shared/utils/string';
import { formatDate } from '@/shared/utils/date';

interface PrintableConditionSummaryProps {
  selectedConditionIds: string[];
}

const PrintableConditionSummaryBase = ({ selectedConditionIds }: PrintableConditionSummaryProps) => {
  const client = useSelectedClient(true);
  const conditions = useUnifiedStore(conditionsSelector);

  const selectedConditions = useMemo(
    () => conditions.filter((condition) => selectedConditionIds.includes(condition.id)),
    [conditions, selectedConditionIds],
  );

  const { codesheetConditions, claimConditions, otherConditions } = categorizeConditions(selectedConditions);

  const renderConditions = (type: 'codesheet' | 'claim' | 'other') => {
    const conditions =
      type === 'codesheet' ? codesheetConditions : type === 'claim' ? claimConditions : otherConditions;
    const conditionGroupTitle =
      type === 'codesheet'
        ? 'Conditions from Codesheet'
        : type === 'claim'
        ? 'Conditions from Claim Documents'
        : 'Potential Conditions';

    if (!conditions.length) return null;

    return (
      <>
        <Flex align="center" justify="center" gap="sm" mt="xs" mb={-8}>
          <Box sx={(theme) => ({ flexGrow: 1, height: 1, border: `1px solid ${theme.colors.gray[5]}` })} />

          <Text fz="1rem" fw={600}>
            {conditionGroupTitle}
          </Text>

          <Box sx={(theme) => ({ flexGrow: 1, height: 1, border: `1px solid ${theme.colors.gray[5]}` })} />
        </Flex>

        <>
          {conditions.map((condition, index) => {
            return (
              <Flex direction="column" key={condition.id}>
                <Flex direction="column" mt="lg" mb="xs">
                  <Text fw={600} fz="0.75rem" color="dark.3" component="span" mb={-4}>
                    {conditionGroupTitle.replace('Conditions', 'Condition')} {index + 1}
                    {condition.rating && <>&nbsp;(SC – {condition.rating})</>}
                  </Text>

                  <Text fw={600} component="span">
                    <IconCircleFilled size={10} /> {condition.headerCondition}
                    {condition.diagnosticCode && (
                      <Text color="dark" component="span" fw={600}>
                        &nbsp;(DC: {condition.diagnosticCode})
                      </Text>
                    )}
                  </Text>
                </Flex>

                <MedicalConditionItemAsSummary medicalCondition={condition} />
              </Flex>
            );
          })}
        </>
      </>
    );
  };

  return (
    <Flex
      id="printable-condition-summary-container"
      pt={4}
      px="xl"
      sx={{
        code: {
          fontFamily: 'unset',
          textDecoration: 'underline',
          fontWeight: 500,
        },
        'div.condition-summary-section': {
          overflow: 'unset',
        },
        '.extra-action--bookmark': {
          display: 'none',
        },
        '.unprintable': {
          display: 'none',
        },
      }}
    >
      <Flex direction="column">
        <Flex direction="column">
          <Text fw={600} color="dark.4" fz="1.125rem" align="center">
            Condition Summary
          </Text>

          <Text fw={500} color="dark.2" align="center" mt={-2}>
            {getClientDisplayName(client)}
          </Text>
        </Flex>

        {renderConditions('codesheet')}

        {renderConditions('claim')}

        {renderConditions('other')}
      </Flex>

      <Helmet>
        <title>
          {removeAllSpaces(getClientDisplayName(client))}_ConditionSummary_
          {formatDate(new Date(), { dateStyle: 'short', timeStyle: 'short', hour12: false }).replace(', ', '_')}
        </title>
      </Helmet>
    </Flex>
  );
};

export const PrintableConditionSummary = memo(PrintableConditionSummaryBase);
