import { memo, useMemo } from 'react';
import { Flex } from '@mantine/core';

import { TimelineEventPersistentExtraBoxesProps } from './TimelineEventPersistentExtraBoxes.types';
import { DetailsType } from '@/pageAI/gql/graphql';
import { TimelineEventExtraBox } from '../TimelineEventExtraBox';
import {
  doesEventHaveMultipleSummaries,
  doesEventHaveRatingDecisions,
  filterTimelineEventInfoByCondition,
  isTimelineEventFieldEmpty,
} from '@/pageAI/services/caseTimeline';
import { TimelineEventSummaryBox } from '../TimelineEventSummaryBox';
import { keys } from '@/shared/utils/object';
import { selectedConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';

const TimelineEventPersistentExtraBoxesBase = ({
  event,
  isPrinting,
  keywords,
  matchWholePhrase,
  matchWholeWords,
}: TimelineEventPersistentExtraBoxesProps) => {
  const selectedCondition = useUnifiedStore(selectedConditionSelector);
  // TODO: Temporarily disabling this feature until we have a better way to display condition-related events
  const displayConditionRelatedEvents = false;

  const hasDetails = useMemo(
    () =>
      keys(event.detailsByType).some(
        (detailsType) =>
          detailsType !== DetailsType.DetailedInformation &&
          event.detailsByType[detailsType]?.some((detail) => !isTimelineEventFieldEmpty(detail)),
      ),
    [event.detailsByType],
  );
  const hasMultipleSummaries = useMemo(() => doesEventHaveMultipleSummaries(event), [event]);
  const hasRatingDecisions = useMemo(() => doesEventHaveRatingDecisions(event), [event]);
  const eventWithFilteredInfo = useMemo(
    () =>
      selectedCondition && displayConditionRelatedEvents
        ? filterTimelineEventInfoByCondition(event, selectedCondition)
        : event,
    [event, selectedCondition, displayConditionRelatedEvents],
  );
  const uniqueDetailsTypes = useMemo(
    () =>
      [...new Set(event.detailsTypes || [])].filter((detailsType) => detailsType !== DetailsType.DetailedInformation),
    [event.detailsTypes],
  );

  if (!hasDetails && !hasMultipleSummaries && !hasRatingDecisions) return null;

  return (
    <Flex direction="column" gap={4} mt={8} mb={4}>
      {(hasMultipleSummaries || hasRatingDecisions) && (
        <TimelineEventSummaryBox
          event={eventWithFilteredInfo}
          isPrinting={isPrinting}
          keywords={keywords}
          matchWholeWords={matchWholeWords}
          matchWholePhrase={matchWholePhrase}
        />
      )}

      {uniqueDetailsTypes.map((detailsType) => {
        if (!eventWithFilteredInfo.detailsByType[detailsType]?.length) return null;

        return (
          <TimelineEventExtraBox
            key={detailsType}
            event={eventWithFilteredInfo}
            detailsType={detailsType}
            isPrinting={isPrinting}
            keywords={keywords}
            matchWholePhrase={matchWholePhrase}
            matchWholeWords={matchWholeWords}
          />
        );
      })}
    </Flex>
  );
};

export const TimelineEventPersistentExtraBoxes = memo(TimelineEventPersistentExtraBoxesBase);
