import { memo } from 'react';
import { Anchor, Highlight } from '@mantine/core';

import { openWebPreviewer } from '@/shared/states/webPreview';
import {
  MarkdownAnchorRendererOverrides,
  MarkdownAnchorRendererWithWebPreviewerProps,
} from './MarkdownAnchorRendererWithWebPreviewer.types';
import { useMarkdownRendererContext } from '../MarkdownRendererContextProvider';

export const markdownAnchorRendererOverrides: MarkdownAnchorRendererOverrides = {
  action: undefined,
};

const MarkdownAnchorRendererWithWebPreviewerBase = ({
  href,
  sx,
  children,
}: MarkdownAnchorRendererWithWebPreviewerProps) => {
  const highlights = useMarkdownRendererContext((state) => state.highlights);

  const handleClick = (event: React.MouseEvent) => {
    if (!href) return;

    event.preventDefault();

    const shouldSkip = markdownAnchorRendererOverrides.action?.(href);

    if (shouldSkip) return;

    openWebPreviewer(href);
  };

  return (
    <Anchor href={href} target="_blank" rel="noopener noreferrer" onClick={handleClick} sx={sx} color="blue.8">
      <Highlight highlight={highlights || []}>{children as string}</Highlight>
    </Anchor>
  );
};

export const MarkdownAnchorRendererWithWebPreviewer = memo(
  MarkdownAnchorRendererWithWebPreviewerBase,
) as typeof MarkdownAnchorRendererWithWebPreviewerBase;
