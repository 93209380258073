import { memo, useEffect, useState } from 'react';
import { Accordion, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAlertCircle, IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { PotentialMissingFileSection } from '../../files/PotentialMissingFileSection';
import {
  getMedicalConditionEntryId,
  MEDICAL_CONDITION_TEXT_DISPLAY_MAPPING,
} from '@/pageAI/services/medicalConditions';
import { MissingDocHint } from '@/pageAI/gql/graphql';

interface PotentialMissingFileEntryProps {
  condition: MedicalConditionItem;
  sectionField: string;
  entryKey: string;
  missingHints?: MissingDocHint[];
}

const PotentialMissingFileEntryBase = ({
  condition,
  sectionField,
  entryKey,
  missingHints,
}: PotentialMissingFileEntryProps) => {
  const [opened, { toggle, close }] = useDisclosure();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!opened) return;

    const appBodyElement = document.getElementById('app-body');
    const appBodyLeftSplitElement = appBodyElement?.querySelector('.__dbk__child-wrapper') as
      | HTMLDivElement
      | undefined;

    if (!appBodyLeftSplitElement) return;

    const observer = new ResizeObserver(() => {
      const widthString = getComputedStyle(appBodyLeftSplitElement).width;
      const width = Number(widthString.replace('px', ''));

      setWidth(width + 54);
    });

    observer.observe(appBodyLeftSplitElement);

    return () => {
      observer.disconnect();
    };
  }, [opened]);

  if (!missingHints?.length) return null;

  return (
    <Flex
      direction="column"
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
        padding: '8px 16px',
      })}
    >
      <Flex>
        <Flex>
          <Text
            fz="0.875rem"
            color="dark"
            sx={{ width: 220, whiteSpace: 'pre-line', wordBreak: 'break-word', paddingRight: 16 }}
          >
            {
              MEDICAL_CONDITION_TEXT_DISPLAY_MAPPING[
                sectionField as keyof typeof MEDICAL_CONDITION_TEXT_DISPLAY_MAPPING
              ]
            }
          </Text>
        </Flex>

        <Flex id={getMedicalConditionEntryId(condition.id, entryKey)} direction="column">
          <Flex
            align="center"
            gap={4}
            sx={(theme) => ({
              color: theme.colors.red[6],
            })}
          >
            <IconAlertCircle size={16} />

            <Text fz="0.875rem" sx={{ whiteSpace: 'nowrap' }} fw={500}>
              Potential missing document(s)
            </Text>

            <Button
              color="dark"
              variant="outline"
              size="xs"
              ml={4}
              sx={{
                height: 28,
                padding: '0 10px',
                '.ghost-Button-rightIcon': {
                  marginLeft: 4,
                },
              }}
              onClick={toggle}
              rightIcon={opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
            >
              Investigate
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Accordion value={opened ? 'default' : undefined}>
        <Accordion.Item
          value="default"
          sx={{
            border: 'none',
            '.ghost-Accordion-content': {
              padding: 0,
            },
          }}
        >
          <Accordion.Panel>
            <PotentialMissingFileSection
              fileIds={missingHints.map((hint) => hint.fileId) || []}
              docHintType={missingHints[0].hintType}
              width={width}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Flex>
  );
};

export const PotentialMissingFileEntry = memo(PotentialMissingFileEntryBase);
