import { memo } from 'react';
import { Flex, Pagination } from '@mantine/core';

import { useContentSearchPage } from '@/pageAI/components/clients/ClientFileSearchPanel/ClientFileSearchPanel.utils';
import { useConditionTermSearchFeatureFlag } from '@/pageAI/hooks/featureFlags/useConditionTermSearchFeatureFlag';

interface FileSearchPaginationProps {
  totalPages?: number;
  numberOfResults?: number;
  hasNextPage?: boolean;
}

const FileSearchPaginationBase = ({
  totalPages = 0,
  numberOfResults = 0,
  hasNextPage = false,
}: FileSearchPaginationProps) => {
  const [page, setPage] = useContentSearchPage();
  const { enabled: isFeatureFlagEnabled } = useConditionTermSearchFeatureFlag();

  if (isFeatureFlagEnabled) {
    if (page === 1 && !numberOfResults) return null;

    return (
      <Flex align="center" justify="center">
        <Pagination
          mt="md"
          total={hasNextPage ? page + 1 : page}
          size="xs"
          value={page}
          onChange={setPage}
          siblings={3}
        />
      </Flex>
    );
  }

  if (!totalPages) return null;

  return (
    <Flex align="center" justify="center">
      <Pagination mt="md" total={totalPages} size="xs" value={page} onChange={setPage} siblings={3} />
    </Flex>
  );
};

export const FileSearchPagination = memo(FileSearchPaginationBase);
