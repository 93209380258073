import { memo, useMemo, useRef, useState, useTransition } from 'react';
import { ActionIcon, Button, Flex, Loader, Text, TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';

import { DebouncedSearchInputProps } from './DebouncedSearchInput.types';
import { useSearchKeywordHistory } from '@/pageAI/hooks/search/useSearchKeywordHistory';

const DebouncedSearchInputBase = ({
  initialValue,
  placeholder = 'Search for keywords...',
  forceRefocusRef,
  w,
  onChange,
  renderInputWrapper,
  showSuggestion,
}: DebouncedSearchInputProps) => {
  const [value, setValue] = useState(initialValue);
  const [showInput, setShowInput] = useState(!!value);
  const [isPending, startTransition] = useTransition();
  const debounceTimeoutRef = useRef<number | null>(null);
  const searchKeywordHistory = useSearchKeywordHistory();

  const suggestion = useMemo(() => {
    if (!value) return;

    return searchKeywordHistory.find((keyword) => {
      const loweredCaseKeyword = keyword.toLowerCase();
      const loweredCaseValue = value.toLowerCase();

      return loweredCaseKeyword.startsWith(loweredCaseValue) && loweredCaseKeyword !== loweredCaseValue;
    });
  }, [searchKeywordHistory, value]);

  const handleChange = (event: { target: { value: string } }) => {
    const newValue = event.target.value;

    setValue(newValue);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = window.setTimeout(() => {
      startTransition(() => onChange?.(newValue));
    }, 500);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value) return;

    const eventTarget = event.target;

    setTimeout(() => {
      if (forceRefocusRef?.current) {
        forceRefocusRef.current = false;

        eventTarget.focus();

        return;
      }

      setShowInput(false);
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setValue('');
      setShowInput(false);
    }

    if (event.key === 'Enter') {
      onChange?.(value || '');
    }

    if (event.key === 'Tab') {
      event.preventDefault();

      if (suggestion) {
        handleChange({ target: { value: suggestion } });
      }
    }
  };

  if (showInput) {
    const inputNode = (
      <Flex pos="relative">
        <TextInput
          pos="relative"
          className="search-input"
          size="xs"
          placeholder={placeholder}
          w={w}
          icon={<IconSearch size={16} />}
          rightSection={
            <>
              {isPending ? (
                <Loader size={12} />
              ) : (
                <ActionIcon size="xs" onClick={() => handleChange({ target: { value: '' } })}>
                  <IconX size={14} />
                </ActionIcon>
              )}
            </>
          }
          value={value || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          onKeyDown={handleKeyDown}
        />

        {showSuggestion && suggestion && value && (
          <Text pos="absolute" left={31} top={6} fz="0.75rem" sx={{ zIndex: 0, opacity: 0.5 }}>
            {suggestion}
          </Text>
        )}
      </Flex>
    );

    if (typeof renderInputWrapper === 'function') {
      return renderInputWrapper(inputNode, value || '');
    }

    return inputNode;
  }

  return (
    <Button
      leftIcon={<IconSearch size={14} />}
      onClick={() => setShowInput(true)}
      variant="subtle"
      color="gray.7"
      size="xs"
    >
      Search
    </Button>
  );
};

export const DebouncedSearchInput = memo(DebouncedSearchInputBase);
