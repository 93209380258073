import { memo } from 'react';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

import { CreateClientButtonProps } from './CreateClientButton.types';
import { UpsertClientModal } from '../UpsertClientModal';

const CreateClientButtonBase = (props: CreateClientButtonProps) => {
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Button color="red" leftIcon={<IconPlus size={16} />} onClick={open} data-testid="create-client-button">
        Create client
      </Button>

      <UpsertClientModal opened={opened} onClose={close} />
    </>
  );
};

export const CreateClientButton = memo(CreateClientButtonBase);
