import { graphql } from '../gql';

export const mergeConditionsMutation = graphql(/* GraphQL */ `
  mutation MergeConditions($input: MergeConditionsInput!) {
    mergeConditionsErrorCorrection(input: $input) {
      destCondition {
        id
        isMiscellaneous
        headerCondition
        subConditions
        conditionStatus
        mergedConditions
      }
      sourceConditions {
        id
        isMiscellaneous
        headerCondition
        subConditions
        conditionStatus
        mergedConditions
      }
    }
  }
`);

export const deleteMedicalConditionMutation = graphql(/* GraphQL */ `
  mutation DeleteMedicalCondition($input: DeleteMedicalConditionInput!) {
    deleteMedicalCondition(input: $input) {
      deletedMedicalConditionId
    }
  }
`);

export const addEventsToConditionsMutation = graphql(/* GraphQL */ `
  mutation AddEventsToConditions($input: AddEventsToConditionsInput!) {
    addEventsToConditions(input: $input) {
      id
      isMiscellaneous
      headerCondition
      subConditions
      conditionType
      conditionStatus
      conditionOrder
      isServiceConnected
      rating
      diagnosticCode

      mergedConditions

      latestCodesheet {
        data {
          date
          fileId
          reference
          summary
          isDuplicated
        }
        missingDocHints {
          fileId
          hintType
        }
      }

      allCodesheets {
        data {
          date
          fileId
          reference
          summary
          isDuplicated
        }
        missingDocHints {
          fileId
          hintType
        }
      }

      proceduralHistory {
        vaForm218940s {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        applicableItf {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        filings {
          vaForm9 {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          disagreementNotices {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          initialClaims {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          requestsForHighLevelReview {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          supplementalClaims {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          bvaDisagreementNotices {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          vaForm21526b {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
        }
        firstDecision {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        dateOfOriginalClaim {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        otherDecisions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      medicalEvidence {
        disabilityBenefitsQuestionnaires {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        medicalOpinions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        addendumOpinions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        examinationsForHouseboundStatus {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      otherEvidence {
        socStatements {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        favorableFindings {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        layStatements {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        additionalEvidence {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      relatedTimelineEvents {
        id
        eventType
        summaries
        summariesTypes
        details
        detailsTypes
        date
        duplicated
        confidenceLevel
        reference
        referencedText
        verificationLevel
        bookmarked
        generation
        metadata {
          key
          value
        }
        viewerCanBookmark
        viewerCanComment
        viewerCanDelete
        viewerCanUnbookmark
        viewerCanUpdate
        viewerCanHide
        viewerCanUnhide
        createdAt
        updatedAt
        comments(first: 9999) {
          nodes {
            id
            content
            viewerCanDelete
            viewerCanUpdate
            viewerDidAuthor
            createdAt
            updatedAt
            author {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
      }

      generation
      createdAt
      updatedAt
    }
  }
`);

export const removeEventsFromConditionMutation = graphql(/* GraphQL */ `
  mutation RemoveEventsFromCondition($input: RemoveEventsFromConditionInput!) {
    removeEventsFromCondition(input: $input) {
      id
      isMiscellaneous
      headerCondition
      subConditions
      conditionType
      conditionStatus
      conditionOrder
      isServiceConnected
      rating
      diagnosticCode

      mergedConditions

      latestCodesheet {
        data {
          date
          fileId
          reference
          summary
          isDuplicated
        }
        missingDocHints {
          fileId
          hintType
        }
      }

      allCodesheets {
        data {
          date
          fileId
          reference
          summary
          isDuplicated
        }
        missingDocHints {
          fileId
          hintType
        }
      }

      proceduralHistory {
        vaForm218940s {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        applicableItf {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        filings {
          vaForm9 {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          disagreementNotices {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          initialClaims {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          requestsForHighLevelReview {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          supplementalClaims {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          bvaDisagreementNotices {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          vaForm21526b {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
        }
        firstDecision {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        dateOfOriginalClaim {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        otherDecisions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      medicalEvidence {
        disabilityBenefitsQuestionnaires {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        medicalOpinions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        addendumOpinions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        examinationsForHouseboundStatus {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      otherEvidence {
        socStatements {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        favorableFindings {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        layStatements {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        additionalEvidence {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      relatedTimelineEvents {
        id
        eventType
        summaries
        summariesTypes
        details
        detailsTypes
        date
        duplicated
        confidenceLevel
        reference
        referencedText
        verificationLevel
        bookmarked
        generation
        metadata {
          key
          value
        }
        viewerCanBookmark
        viewerCanComment
        viewerCanDelete
        viewerCanUnbookmark
        viewerCanUpdate
        viewerCanHide
        viewerCanUnhide
        createdAt
        updatedAt
        comments(first: 9999) {
          nodes {
            id
            content
            viewerCanDelete
            viewerCanUpdate
            viewerDidAuthor
            createdAt
            updatedAt
            author {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
      }

      generation
      createdAt
      updatedAt
    }
  }
`);

export const addFilesToConditionsMutation = graphql(/* GraphQL */ `
  mutation AddFilesToConditions($input: AddFilesToConditionsInput!) {
    addFilesToConditions(input: $input) {
      id
      isMiscellaneous
      headerCondition
      subConditions
      conditionType
      conditionStatus
      conditionOrder
      isServiceConnected
      rating
      diagnosticCode

      mergedConditions

      latestCodesheet {
        data {
          date
          fileId
          reference
          summary
          isDuplicated
        }
        missingDocHints {
          fileId
          hintType
        }
      }

      allCodesheets {
        data {
          date
          fileId
          reference
          summary
          isDuplicated
        }
        missingDocHints {
          fileId
          hintType
        }
      }

      proceduralHistory {
        vaForm218940s {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        applicableItf {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        filings {
          vaForm9 {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          disagreementNotices {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          initialClaims {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          requestsForHighLevelReview {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          supplementalClaims {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          bvaDisagreementNotices {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          vaForm21526b {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
        }
        firstDecision {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        dateOfOriginalClaim {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        otherDecisions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      medicalEvidence {
        disabilityBenefitsQuestionnaires {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        medicalOpinions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        addendumOpinions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        examinationsForHouseboundStatus {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      otherEvidence {
        socStatements {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        favorableFindings {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        layStatements {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        additionalEvidence {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      relatedTimelineEvents {
        id
        eventType
        summaries
        summariesTypes
        details
        detailsTypes
        date
        duplicated
        confidenceLevel
        reference
        referencedText
        verificationLevel
        bookmarked
        generation
        metadata {
          key
          value
        }
        viewerCanBookmark
        viewerCanComment
        viewerCanDelete
        viewerCanUnbookmark
        viewerCanUpdate
        viewerCanHide
        viewerCanUnhide
        createdAt
        updatedAt
        comments(first: 9999) {
          nodes {
            id
            content
            viewerCanDelete
            viewerCanUpdate
            viewerDidAuthor
            createdAt
            updatedAt
            author {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
      }

      generation
      createdAt
      updatedAt
    }
  }
`);

export const removeFilesFromConditionMutation = graphql(/* GraphQL */ `
  mutation RemoveFilesFromCondition($input: RemoveFilesFromConditionInput!) {
    removeFilesFromCondition(input: $input) {
      id
      isMiscellaneous
      headerCondition
      subConditions
      conditionType
      conditionStatus
      conditionOrder
      isServiceConnected
      rating
      diagnosticCode

      mergedConditions

      latestCodesheet {
        data {
          date
          fileId
          reference
          summary
          isDuplicated
        }
        missingDocHints {
          fileId
          hintType
        }
      }

      allCodesheets {
        data {
          date
          fileId
          reference
          summary
          isDuplicated
        }
        missingDocHints {
          fileId
          hintType
        }
      }

      proceduralHistory {
        vaForm218940s {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        applicableItf {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        filings {
          vaForm9 {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          disagreementNotices {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          initialClaims {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          requestsForHighLevelReview {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          supplementalClaims {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          bvaDisagreementNotices {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
          vaForm21526b {
            data {
              date
              fileId
              reference
              summary
              isDuplicated
            }
            missingDocHints {
              fileId
              hintType
            }
          }
        }
        firstDecision {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        dateOfOriginalClaim {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        otherDecisions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      medicalEvidence {
        disabilityBenefitsQuestionnaires {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        medicalOpinions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        addendumOpinions {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        examinationsForHouseboundStatus {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      otherEvidence {
        socStatements {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        favorableFindings {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        layStatements {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
        additionalEvidence {
          data {
            date
            fileId
            reference
            summary
            isDuplicated
          }
          missingDocHints {
            fileId
            hintType
          }
        }
      }

      relatedTimelineEvents {
        id
        eventType
        summaries
        summariesTypes
        details
        detailsTypes
        date
        duplicated
        confidenceLevel
        reference
        referencedText
        verificationLevel
        bookmarked
        generation
        metadata {
          key
          value
        }
        viewerCanBookmark
        viewerCanComment
        viewerCanDelete
        viewerCanUnbookmark
        viewerCanUpdate
        viewerCanHide
        viewerCanUnhide
        createdAt
        updatedAt
        comments(first: 9999) {
          nodes {
            id
            content
            viewerCanDelete
            viewerCanUpdate
            viewerDidAuthor
            createdAt
            updatedAt
            author {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
      }

      generation
      createdAt
      updatedAt
    }
  }
`);

export const conditionSearchTermsQuery = graphql(/* GraphQL */ `
  query ConditionSearchTerms($id: ID!) {
    client(id: $id) {
      searchTerms {
        code
        abbreviations
        terms
        termsFromDiagnostic
      }
    }
  }
`);

export const availableConditionKeywordsQuery = graphql(/* GraphQL */ `
  query AvailableConditionKeywords($keywords: [String!]!, $clientIds: [ID!]) {
    availableKeywords(keywords: $keywords, clientIds: $clientIds) {
      keyword
      score
    }
  }
`);
