import { memo, useEffect, useMemo, useState } from 'react';
import { Box, Flex, LoadingOverlay, Table, Text, useMantineTheme } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';
import { useInView } from 'react-intersection-observer';

import { AssetTableProps } from './AssetTable.types';
import { FileStatusBadge } from '@/shared/components/files/FileStatusBadge';
import { formatDate, parseDateOnly } from '@/shared/utils/date';
import { AssetType, FileUploadStatus } from '@/shared/@types';
import { getAssetIcon, getFileDisplayStatus } from '@/shared/services/files';
import { FileSize, convertUnit } from '@/shared/utils/unit';
import { formatAmount } from '@/shared/utils/number';
import { sortAlphabetically } from '@/shared/utils/string';
import { sortDate } from '@/shared/utils/date';

const headers = ['#', 'Name', 'Received Date', 'Size', 'Type', 'Status', ''];

const AssetTableBase: React.FC<AssetTableProps> = ({
  assetItems,
  loading = false,
  placeholderText = 'There are no files. Please upload some files.',
  loadingPlaceholderHeight = 240,
  selected,
  headerOverrides,
  colgroup,
  sx,
  trStyle,
  hiddenColumns,
  initialLoadCount,
  onSelectIndex,
  onCollectionClick,
  renderActionCell,
}) => {
  const theme = useMantineTheme();
  const [sortedColumnName, setSortedColumnName] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
  const [currentLoad, setCurrentLoad] = useState(initialLoadCount ?? assetItems.length);
  const [infiniteScrollRef, inView] = useInView();

  useEffect(() => {
    if (!inView || typeof initialLoadCount === 'undefined') return;

    setCurrentLoad((prev) => prev + initialLoadCount);
  }, [inView, initialLoadCount]);

  const sortedAssetItems = useMemo(() => {
    let sorted = [...assetItems].sort((assetA, assetB) => {
      if (!sortedColumnName || sortedColumnName === 'Name') {
        return sortAlphabetically(assetA.name, assetB.name);
      }

      if (sortedColumnName === '#') {
        return sortAlphabetically(assetA.index || '', assetB.index || '');
      }

      if (sortedColumnName === 'Type') {
        return sortAlphabetically(assetA.type, assetB.type);
      }

      if (sortedColumnName === 'Received Date') {
        return sortDate(assetA.createdAt || new Date(), assetB.createdAt || new Date());
      }

      if (sortedColumnName === 'Size') {
        if (assetA.type === AssetType.FILE && assetB.type === AssetType.FILE) {
          if (typeof assetA.metadata?.totalPages === 'number' && typeof assetB.metadata?.totalPages === 'number') {
            return assetA.metadata.totalPages - assetB.metadata.totalPages;
          }
        }

        const sizeA = assetA.size || 0;
        const sizeB = assetB.size || 0;

        return sizeA - sizeB;
      }

      if (sortedColumnName === 'Status') {
        if (assetA.type === AssetType.COLLECTION) return -1;

        if (assetB.type === AssetType.COLLECTION) return 1;

        const displayStatusA = getFileDisplayStatus(assetA);
        const displayStatusB = getFileDisplayStatus(assetB);

        return sortAlphabetically(displayStatusA, displayStatusB);
      }

      return 0;
    });

    if (sortOrder === 'asc') sorted = sorted.reverse();

    return sorted;
  }, [assetItems, sortedColumnName, sortOrder]);

  const visibleItems = useMemo(
    () => (currentLoad > 0 ? sortedAssetItems.slice(0, currentLoad) : sortedAssetItems),
    [sortedAssetItems, currentLoad],
  );

  const handleSortColumn = (columnName: string) => () => {
    setSortedColumnName(columnName);

    if (sortOrder === null) return setSortOrder('desc');

    if (sortOrder === 'desc') return setSortOrder('asc');

    if (sortOrder === 'asc') {
      setSortedColumnName(null);

      return setSortOrder(null);
    }
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <tr
          style={{
            position: 'sticky',
            top: 0,
            background: 'white',
            boxShadow: `inset 0 -1px 0px ${theme.colors.gray[3]}`,
            zIndex: 100,
          }}
        >
          {headers.map((header, headerIndex) => {
            if (hiddenColumns?.includes(header)) return null;

            return (
              <th key={header} onClick={handleSortColumn(header)}>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 4,
                    ...(headerIndex === headers.length - 1
                      ? {
                          justifyContent: 'flex-end',
                        }
                      : {}),
                  }}
                >
                  {headerOverrides?.[headerIndex] || header}

                  {headerIndex !== headers.length - 1 && (
                    <>
                      {sortedColumnName === header ? (
                        <>{sortOrder === 'asc' ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}</>
                      ) : (
                        <IconSelector size={14} />
                      )}
                    </>
                  )}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderTableBody = () => {
    if (loading)
      return (
        <tbody style={{ position: 'relative', height: loadingPlaceholderHeight }}>
          <tr>
            <th>
              <LoadingOverlay visible />
            </th>
          </tr>
        </tbody>
      );

    if (!sortedAssetItems.length) {
      return (
        <tbody>
          <tr></tr>
        </tbody>
      );
    }

    return (
      <tbody>
        {visibleItems.map((assetItem, rowIndex) => {
          const AssetIcon = getAssetIcon(assetItem);

          const isSelected = selected?.includes(rowIndex);
          const commonTableDataStyles: React.CSSProperties = {
            ...(isSelected ? { borderColor: theme.colors.blue[1] } : {}),
          };

          const convertedSize = assetItem.size ? convertUnit(assetItem.size, FileSize.Byte) : null;
          let formattedSize: React.ReactNode = convertedSize
            ? `${formatAmount(convertedSize.newValue)} ${convertedSize.newUnit}`
            : '-';

          if (assetItem.type === AssetType.FILE && typeof assetItem.metadata?.totalPages === 'number') {
            formattedSize = (
              <Text fz="0.875rem" color="dark.5">
                {assetItem.metadata.totalPages} pages{' '}
                <Text display="inline" fz="0.75rem">
                  ({formattedSize})
                </Text>
              </Text>
            );
          }

          return (
            <tr
              className={assetItem.index}
              onClick={(event) => {
                onSelectIndex?.(rowIndex, event, assetItem);

                if (assetItem.type === AssetType.FILE) return;

                onCollectionClick?.(assetItem);
              }}
              key={assetItem.id}
              style={{
                userSelect: 'none',
                transition: 'all 0.1s',
                opacity:
                  !hiddenColumns?.includes('Status') &&
                  assetItem.type === AssetType.FILE &&
                  assetItem.status.uploadStatus === FileUploadStatus.UPLOADING
                    ? 0.5
                    : 1,
                ...(isSelected ? { backgroundColor: theme.colors.blue[1] } : {}),
                ...trStyle,
              }}
            >
              {!hiddenColumns?.includes('#') && (
                <td style={commonTableDataStyles}>{assetItem.index || rowIndex + 1}</td>
              )}

              {!hiddenColumns?.includes('Name') && <td style={commonTableDataStyles}>{assetItem.name}</td>}

              {!hiddenColumns?.includes('Received Date') && (
                <td style={commonTableDataStyles}>
                  {assetItem.createdAt ? formatDate(parseDateOnly(assetItem.createdAt)) : '-'}
                </td>
              )}

              {!hiddenColumns?.includes('Size') && <td style={commonTableDataStyles}>{formattedSize}</td>}

              {!hiddenColumns?.includes('Type') && (
                <td style={commonTableDataStyles}>
                  <Flex align="center" gap={4}>
                    <AssetIcon width={16} height={16} />
                  </Flex>
                </td>
              )}

              {!hiddenColumns?.includes('Status') && (
                <td style={commonTableDataStyles}>
                  {assetItem.type === AssetType.FILE ? <FileStatusBadge file={assetItem} /> : '-'}
                </td>
              )}

              {!hiddenColumns?.includes('') && (
                <td style={{ ...commonTableDataStyles, textAlign: 'right' }}>
                  {renderActionCell?.(assetItem, rowIndex)}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <Flex direction="column" gap={6} sx={sx} pos="relative">
      <Table
        highlightOnHover={!!assetItems.length}
        sx={(theme) => ({
          th: {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.colors.gray[1],
            },
          },
        })}
      >
        {colgroup ?? (
          <colgroup>
            <col style={{ width: '3%' }} />
            <col style={{ width: '45%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '2%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
        )}

        {renderTableHeader()}

        {renderTableBody()}
      </Table>

      <Box ref={infiniteScrollRef} sx={{ position: 'absolute', bottom: 40 }} />

      {!visibleItems.length && !loading && (
        <Flex direction="column" justify="center" px="xs" pb={6}>
          <Text fz="0.875rem" color="gray.7">
            {placeholderText}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export const AssetTable = memo(AssetTableBase);
