import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import { UploadMultipleFilesProps, UseUploadMultipleFilesProps } from './useUploadMultipleFiles.types';
import { useUploadLargeFiles } from '../useUploadLargeFiles';
import { useOcrFiles } from '../useOcrFiles';
import { estimateUploadTimeForFiles } from '@/shared/services/files';
import { FileUploadStatus } from '@/shared/@types';
import { initUploadMultipleFilesNotifications } from '@/shared/services/files';
import { startUploadingFiles, useUploadBatch } from './useUploadMultipleFiles.states';

export const useUploadMultipleFiles = (props?: UseUploadMultipleFilesProps) => {
  const queryClient = useQueryClient();
  const { uploadLargeFile } = useUploadLargeFiles();
  const { ocrFiles } = useOcrFiles();
  const uploadBatchId = props?.id || uuidv4();

  const { fileAssets, status } = useUploadBatch(uploadBatchId);
  const isUploading = status === FileUploadStatus.UPLOADING;
  const uploadedFileAssetCount = useMemo(
    () => fileAssets.filter((fileAsset) => fileAsset.status.uploadStatus === FileUploadStatus.UPLOADED).length,
    [fileAssets],
  );

  useEffect(() => {
    if (!isUploading) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      const confirmationMessage = 'There are still files that need to be uploaded. Do you still want to reload?';

      event.returnValue = confirmationMessage; // Chrome requires this to display the confirmation dialog

      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUploading]);

  const uploadMultipleFiles = async ({ files, options }: UploadMultipleFilesProps) => {
    const { showUploadingNotification, updateUploadingNotification, finishUploadingNotification } =
      initUploadMultipleFilesNotifications({
        uploadingTitle: props?.uploadingTitle,
        successTitle: props?.successTitle,
        errorTitle: props?.errorTitle,
      });

    const postUploadAction = async () => {
      if (typeof props?.postUploadAction === 'function') {
        await props.postUploadAction();
      } else {
        await queryClient.refetchQueries(['files']);
      }
    };

    const { initialFileAssets, uploadPromise } = startUploadingFiles({
      id: uploadBatchId,
      files,
      uploadFunction: (file, waitUntilUploaded) => uploadLargeFile(file, waitUntilUploaded, files.length === 1),
      onSuccess: (uploadedFileAsset, updatedFileAssets, successCount) => {
        const estimatedUploadTime = estimateUploadTimeForFiles(updatedFileAssets);

        updateUploadingNotification(files.length, successCount, estimatedUploadTime);
      },
      postUploadAction,
    });

    const estimatedUploadTime = estimateUploadTimeForFiles(initialFileAssets);

    showUploadingNotification(files.length, estimatedUploadTime);

    const { uploadedFiles, failedFiles, originalFailedFiles } = await uploadPromise;

    finishUploadingNotification(files.length, uploadedFiles.length, failedFiles.length, () => {
      uploadMultipleFiles({ files: originalFailedFiles, options });
    });

    if (options.shouldOcr) {
      await ocrFiles({
        ids: uploadedFiles.map((file) => file.id),
        options: {
          method: options.ocrMethod,
          indexingOptions: {
            doIndexing: options.shouldIndex,
            ...(options.shouldIndex
              ? {
                  chunkCharOverlap: options.chunkCharOverlap,
                  chunkCharSize: options.chunkCharSize,
                }
              : {}),
          },
        },
      });
    }

    return { uploadedFiles };
  };

  return { uploadMultipleFiles, fileAssets, uploadedFileAssetCount, isUploading };
};
