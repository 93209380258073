import { Fragment, memo, useMemo, useState } from 'react';
import { ActionIcon, Box, Checkbox, Divider, Flex, Input, Loader, ScrollArea, Text, Tooltip } from '@mantine/core';
import { IconSearch, IconSortAscendingLetters, IconSortDescendingNumbers } from '@tabler/icons-react';

import { categorizeConditions, sortCodesheetConditions } from '@/pageAI/services/medicalConditions';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { MedicalConditionMenuProps } from './MedicalConditionMenu.types';
import { MedicalConditionMenuItem } from '../MedicalConditionMenuItem';
import { posthog } from '@/shared/plugins/posthog';

const MedicalConditionMenuBase = ({
  medicalConditions,
  selected,
  onSelect,
  onSelectMultiple,
  maxHeight = 'calc(100vh - 154px)',
  checkboxMode = false,
  loading = false,
}: MedicalConditionMenuProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [sortState, setSortState] = useState('rating');
  const [selectAllCodesheetConditions, setSelectAllCodesheetConditions] = useState(true);
  const [selectAllClaimConditions, setSelectAllClaimConditions] = useState(true);
  const [selectAllOtherConditions, setSelectAllOtherConditions] = useState(true);

  const filteredMedicalConditions = useMemo(
    () =>
      medicalConditions.filter((medicalCondition) =>
        medicalCondition.headerCondition.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [medicalConditions, searchValue],
  );

  const { codesheetConditions, claimConditions, otherConditions } = useMemo(
    () => categorizeConditions(filteredMedicalConditions),
    [filteredMedicalConditions],
  );

  const sortedCodesheetConditions = useMemo(
    () => sortCodesheetConditions(codesheetConditions, sortState === 'asc' ? 'asc' : undefined),
    [codesheetConditions, sortState],
  );

  const handleToggleSelectAllCodesheetConditions = () => {
    if (!selectAllCodesheetConditions) {
      onSelectMultiple?.(codesheetConditions);

      setSelectAllCodesheetConditions(true);

      return;
    }

    onSelectMultiple?.(codesheetConditions, true);

    setSelectAllCodesheetConditions(false);
  };

  const handleToggleSelectAllClaimConditions = () => {
    if (!selectAllClaimConditions) {
      onSelectMultiple?.(claimConditions);

      setSelectAllClaimConditions(true);

      return;
    }

    onSelectMultiple?.(claimConditions, true);

    setSelectAllClaimConditions(false);
  };

  const handleToggleSelectAllOtherConditions = () => {
    if (!selectAllOtherConditions) {
      onSelectMultiple?.(otherConditions);

      setSelectAllOtherConditions(true);

      return;
    }

    onSelectMultiple?.(otherConditions, true);

    setSelectAllOtherConditions(false);
  };

  const renderSortButton = () => {
    return (
      <Tooltip label={sortState === 'asc' ? 'Sorting alphebetically' : 'Sorting by rating'}>
        <ActionIcon
          size="xs"
          onClick={() => setSortState(sortState === 'asc' ? 'rating' : 'asc')}
          sx={(theme) => ({
            '&:hover': {
              background: theme.colors.gray[2],
            },
          })}
        >
          {sortState === 'asc' ? <IconSortAscendingLetters size={16} /> : <IconSortDescendingNumbers size={16} />}
        </ActionIcon>
      </Tooltip>
    );
  };

  const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setSearchValue(newValue);

    posthog.capture('[Conditions] Search for conditions', {
      searchValue: newValue,
    });
  };

  const renderOptions = (medicalConditions: MedicalConditionItem[]) => {
    return (
      <>
        {medicalConditions.map((medicalCondition) => {
          return (
            <MedicalConditionMenuItem
              key={medicalCondition.id}
              medicalCondition={medicalCondition}
              selected={selected?.includes(medicalCondition.id)}
              highlightKeywords={[searchValue]}
              onSelect={onSelect}
              checkboxMode={checkboxMode}
            />
          );
        })}
      </>
    );
  };

  const renderSearchInput = () => {
    return (
      <Flex
        pl={4}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <Input
          type="text"
          placeholder="Search conditions..."
          value={searchValue}
          onChange={handleChangeSearchValue}
          icon={<IconSearch size={16} />}
          sx={(theme) => ({
            width: '100%',
            padding: 0,
            input: {
              fontSize: '0.875rem',
              border: 'none',
              background: theme.fn.lighten(theme.colors.gray[0], 0.5),
            },
          })}
        />
      </Flex>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Flex align="center" justify="center" sx={{ height: 40 }} gap={6}>
          <Loader size={14} />

          <Text fz="0.875rem" color="gray.7">
            Loading conditions...
          </Text>
        </Flex>
      );
    }

    if (filteredMedicalConditions.length === 0) {
      return (
        <Flex align="center" justify="center" sx={{ height: 40 }}>
          <Text fz="0.875rem" color="gray.7">
            Nothing found
          </Text>
        </Flex>
      );
    }

    return (
      <>
        {codesheetConditions.length > 0 && (
          <>
            <Flex align="center" justify="space-between" gap="xs" px={12} py={6}>
              <Flex align="center" gap={4}>
                <Text fz="0.75rem" color="gray.6" fw={500}>
                  Conditions from Codesheet
                </Text>

                {renderSortButton()}
              </Flex>

              {onSelectMultiple && (
                <Checkbox
                  checked={selectAllCodesheetConditions}
                  onChange={handleToggleSelectAllCodesheetConditions}
                  size="xs"
                  label="Select all"
                />
              )}
            </Flex>

            <Box
              sx={(theme) => ({
                '.legacy-condition': {
                  '&:not(.legacy-condition ~ .legacy-condition)': {
                    marginTop: 4,
                    '.prefix': {
                      marginBottom: 4,
                      marginLeft: 12,
                      width: '50%',
                      height: 1,
                      background: theme.colors.gray[3],
                    },
                  },
                },
              })}
            >
              {renderOptions(sortedCodesheetConditions)}
            </Box>
          </>
        )}

        {claimConditions.length > 0 && (
          <>
            {codesheetConditions.length > 0 && <Divider my={4} color="gray.3" />}

            <Flex align="center" justify="space-between" gap="xs" px={12} py={6}>
              <Text fz="0.75rem" color="gray.6" fw={500}>
                Conditions from Claim Documents
              </Text>

              {onSelectMultiple && (
                <Checkbox
                  checked={selectAllClaimConditions}
                  onChange={handleToggleSelectAllClaimConditions}
                  size="xs"
                  label="Select all"
                />
              )}
            </Flex>

            {renderOptions(claimConditions)}
          </>
        )}

        {otherConditions.length > 0 && (
          <>
            {(codesheetConditions.length > 0 || claimConditions.length > 0) && <Divider my={4} color="gray.3" />}

            <Flex align="center" justify="space-between" gap="xs" px={12} py={6}>
              <Text fz="0.75rem" color="gray.6" fw={500}>
                Potential Conditions
              </Text>

              {onSelectMultiple && (
                <Checkbox
                  checked={selectAllOtherConditions}
                  onChange={handleToggleSelectAllOtherConditions}
                  size="xs"
                  label="Select all"
                />
              )}
            </Flex>

            {renderOptions(otherConditions)}
          </>
        )}
      </>
    );
  };

  return (
    <Flex direction="column" onClick={(event) => event.stopPropagation()}>
      {renderSearchInput()}

      <ScrollArea.Autosize sx={{ maxHeight }}>
        <Box pl={4} py={4} pr="sm">
          {renderContent()}
        </Box>
      </ScrollArea.Autosize>
    </Flex>
  );
};

export const MedicalConditionMenu = memo(MedicalConditionMenuBase);
