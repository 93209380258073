import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { mergeConditionsMutation } from '@/pageAI/api/conditions';
import { MergeConditionsInput, MergeConditionsMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { queryMap } from '../../clients/useClientMedicalConditions';
import { showAsyncNotification } from '@/shared/services/notifications';

export const useMergeConditions = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();

  const { mutateAsync: mergeConditionsBase, isLoading } = useMutation<
    MergeConditionsMutation,
    Error,
    MergeConditionsInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(mergeConditionsMutation, { input });

      await queryClient.refetchQueries(queryMap.client.medicalConditions.queryKey(input.clientId));

      return responseBody;
    },
  });

  const mergeConditions = useCallback(
    async (input: MergeConditionsInput) => {
      const { onSuccess, onError } = showAsyncNotification('Merging...', 'The conditions are being merged.');

      try {
        const responseBody = await mergeConditionsBase(input);

        onSuccess('Success', 'The conditions have been merged.');

        return responseBody;
      } catch (error) {
        console.error(error);

        if ((error as Error)?.message?.includes('re-run')) {
          return onError('Error', 'The condition summary needs to be re-generated for this functionality to work.');
        }

        onError('Error', 'The conditions could not be merged.');
      }
    },
    [mergeConditionsBase],
  );

  return {
    mergeConditions,
    isLoading,
  };
};
