import {
  FileStatus as GraphQLFileStatus,
  Maybe,
  OcrMethod,
  PageAiFileMetadata,
  TextExtractionProcessingDetails,
} from '@/pageAI/gql/graphql';

export enum AssetType {
  COLLECTION = 'COLLECTION',
  FILE = 'FILE',
}

export interface BaseAssetItem {
  id: string;
  type: AssetType;
  name: string;
  index?: string;
  isDraft?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export enum FileUploadStatus {
  FAILED = 'FAILED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
}

export interface FileStatus extends Omit<GraphQLFileStatus, 'uploadStatus'> {
  uploadStatus?: Maybe<FileUploadStatus>;
}

export interface FileAsset extends BaseAssetItem {
  type: AssetType.FILE;
  url: string;
  size: number;
  status: FileStatus;
  isImportant?: boolean;
  isCombinedCfile: boolean;
  mimeType: string;
  textExtractionProcessingDetails?: TextExtractionProcessingDetails | null;
  viewerCanUpdate?: boolean;
  viewerCanDelete?: boolean;
  metadata?: {
    totalPages?: number | null;
    pageAIMetadata?: PageAiFileMetadata | null;
  };
}

export interface CollectionAsset extends BaseAssetItem {
  type: AssetType.COLLECTION;
  numberOfFiles: number;
  size?: number;
  files?: FileAsset[];
}

export type AssetItem = FileAsset | CollectionAsset;

export interface UploadFileOptions {
  shouldOcr: boolean;
  ocrMethod: OcrMethod;
  shouldIndex: boolean;
  chunkCharSize: number;
  chunkCharOverlap: number;
}

export interface BrowserFile {
  id: string;
  originalFile: File;
}

export interface FileSearchResult {
  file: FileAsset;
  pageNumber: number;
  snippet: string;
  score: number;
  rerankMultiplier: number;
}

export interface FileSearchResultGroup {
  file: FileAsset;
  results: FileSearchResult[];
  pageNumber: number;
}
