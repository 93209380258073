import { memo, useCallback, useMemo, useState } from 'react';
import { Accordion } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { ConditionSummaryReferencedFileInfo } from '@/pageAI/gql/graphql';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { MedicalConditionSummaryEntry } from '../../MedicalConditionSummaryEntry';
import {
  isPrintingConditionSummarySelector,
  setSummaryHighlighted,
  summaryTabHighlightedSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { dedupeConditionSummaryEntries } from '@/pageAI/services/medicalConditions';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';

export interface ConditionSummaryEntriesProps {
  entries: ConditionSummaryReferencedFileInfo[];
  sectionKey: string;
  sectionField: string;
  condition: MedicalConditionItem;
  keywords?: string[];
}

const ConditionSummaryEntriesBase = ({
  entries,
  sectionKey,
  sectionField,
  condition,
  keywords = [],
}: ConditionSummaryEntriesProps) => {
  const highlighted = useUnifiedStore(summaryTabHighlightedSelector);
  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);

  const [openedMapping, setOpenedMapping] = useState<Record<string, boolean>>({});

  const handleEntryReferenceClick = useCallback((entry: ConditionSummaryReferencedFileInfo, entryKey: string) => {
    setSummaryHighlighted(entryKey);
  }, []);

  const dedupedEntries = useMemo(() => dedupeConditionSummaryEntries(entries), [entries]);

  return (
    <ol>
      {dedupedEntries.map((entry, entryIndex) => {
        const entryKey = [sectionKey, sectionField, entryIndex].join('.');
        const opened = openedMapping[entryKey];

        return (
          <li key={entryKey}>
            <MedicalConditionSummaryEntry
              medicalCondition={condition}
              entryKey={entryKey}
              entry={entry}
              isHighlighted={entryKey === highlighted}
              onReferenceClick={handleEntryReferenceClick}
              keywords={keywords}
            />

            {entry.subEntries.length > 0 && !isPrinting && (
              <>
                <UnderlinedTextButton
                  sx={{ marginLeft: 6 }}
                  onClick={() => setOpenedMapping({ ...openedMapping, [entryKey]: !opened })}
                >
                  {opened ? (
                    <>
                      Hide
                      <IconChevronUp size={14} />
                    </>
                  ) : (
                    <>
                      See all
                      <IconChevronDown size={14} />
                    </>
                  )}
                </UnderlinedTextButton>

                <Accordion
                  value={opened || isPrinting ? 'default' : ''}
                  sx={{
                    '.ghost-Accordion-content': {
                      padding: 0,
                    },
                  }}
                >
                  <Accordion.Item value="default" key="default" sx={{ border: 'none' }}>
                    <Accordion.Panel>
                      <ul>
                        {entry.subEntries.map((subEntry, subEntryIndex) => {
                          const subEntryKey = [entryKey, subEntryIndex].join('.');

                          return (
                            <li key={subEntryKey}>
                              <MedicalConditionSummaryEntry
                                medicalCondition={condition}
                                entryKey={subEntryKey}
                                entry={subEntry}
                                isHighlighted={subEntryKey === highlighted}
                                onReferenceClick={() => handleEntryReferenceClick(subEntry, subEntryKey)}
                                keywords={keywords}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </>
            )}
          </li>
        );
      })}
    </ol>
  );
};

export const ConditionSummaryEntries = memo(ConditionSummaryEntriesBase);
