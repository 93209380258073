import { memo } from 'react';
import { Flex, Menu, Tabs, Text } from '@mantine/core';
import { useIntersection } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';

import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useUnifiedTab } from '@/pageAI/hooks/unified/useUnifiedTab';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { CaseTimelineStatus, SummaryByConditionStatus } from '@/pageAI/gql/graphql';
import { posthog } from '@/shared/plugins/posthog';

const UnifiedTabsBase = () => {
  const client = useSelectedClient(true);

  const [tab, setTab] = useUnifiedTab();

  const { ref, entry } = useIntersection({
    root: document.getElementById('app-bar'),
    threshold: 1,
  });

  const handleTabChange = (newTab: UnifiedTab) => {
    posthog.capture('[Unified] Change tab', {
      tab: newTab,
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
    });

    setTab(newTab);
  };

  const options = [
    {
      value: UnifiedTab.CONDITION_SUMMARY,
      label: 'Condition Summary',
      disabled: client.summaryByConditionStatus !== SummaryByConditionStatus.Generated,
    },
    {
      value: UnifiedTab.TIMELINE,
      label: 'Timeline',
      disabled: client.caseTimelineStatus !== CaseTimelineStatus.Generated,
    },
    {
      value: UnifiedTab.CONTENT_SEARCH,
      label: 'Content Search',
    },
    {
      value: UnifiedTab.FILE_INDEX,
      label: 'File Index',
    },
  ];

  const isCropped = !entry?.isIntersecting;

  return (
    <Flex ref={ref} align="center" sx={{ width: 420, height: 28 }}>
      {isCropped ? (
        <Menu withinPortal shadow="sm">
          <Menu.Target>
            <Flex
              pos="absolute"
              right={32}
              align="center"
              gap={4}
              sx={(theme) => ({
                padding: '4px 8px 2px',
                borderBottom: `2px solid ${theme.colors.dark[5]}`,
                transition: 'all 0.1s ease-in-out',
                cursor: 'pointer',
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                '&:hover': {
                  background: theme.colors.gray[1],
                },
              })}
            >
              <Text fz="0.875rem" color="dark" fw={500}>
                {tab}
              </Text>

              <IconChevronDown size={16} />
            </Flex>
          </Menu.Target>

          <Menu.Dropdown>
            {options.map((option) => {
              return (
                <Menu.Item
                  key={option.value}
                  onClick={() => handleTabChange(option.value)}
                  disabled={option.disabled}
                  sx={{ fontSize: '0.875rem', fontWeight: 500 }}
                >
                  {option.label}
                </Menu.Item>
              );
            })}
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Tabs
          value={tab}
          onTabChange={handleTabChange}
          sx={{
            width: 420,
            whiteSpace: 'nowrap',
            '.ghost-Tabs-tab': {
              padding: '6px 8px',
              fontSize: '0.875rem',
              fontWeight: 500,
            },
          }}
        >
          <Tabs.List sx={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
            <Tabs.Tab
              value={UnifiedTab.CONDITION_SUMMARY}
              disabled={client.summaryByConditionStatus !== SummaryByConditionStatus.Generated}
            >
              Condition Summary
            </Tabs.Tab>
            <Tabs.Tab value={UnifiedTab.TIMELINE} disabled={client.caseTimelineStatus !== CaseTimelineStatus.Generated}>
              Timeline
            </Tabs.Tab>
            <Tabs.Tab value={UnifiedTab.CONTENT_SEARCH}>Content Search</Tabs.Tab>
            <Tabs.Tab value={UnifiedTab.FILE_INDEX}>File Index</Tabs.Tab>
          </Tabs.List>
        </Tabs>
      )}
    </Flex>
  );
};

export const UnifiedTabs = memo(UnifiedTabsBase);
