import { memo, useMemo } from 'react';
import { Checkbox } from '@mantine/core';

import { ShowNullFieldsFilterProps } from './ShowNullFieldsFilter.types';
import {
  selectedConditionSelector,
  setShowConditionSummaryNullFields,
  showNullFieldsSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { shouldAlwaysHideNullFields } from '@/pageAI/services/medicalConditions';

const ShowNullFieldsFilterBase = ({ alwaysShow = false }: ShowNullFieldsFilterProps) => {
  const showNullFields = useUnifiedStore(showNullFieldsSelector);
  const selectedCondition = useUnifiedStore(selectedConditionSelector);

  const forceHideNullFields = useMemo(
    () => selectedCondition && shouldAlwaysHideNullFields(selectedCondition),
    [selectedCondition],
  );

  if (forceHideNullFields && !alwaysShow) return null;

  return (
    <Checkbox
      size="xs"
      label="Hide N/A fields"
      checked={!showNullFields}
      onChange={(event) => setShowConditionSummaryNullFields(!event.target.checked)}
    />
  );
};

export const ShowNullFieldsFilter = memo(ShowNullFieldsFilterBase);
