import { memo } from 'react';
import { SegmentedControl } from '@mantine/core';

import {
  setSummaryTabViewMode,
  summaryTabViewModeSelector,
  SummaryViewMode,
  useUnifiedStore,
} from '@/pageAI/states/unified';

interface ConditionViewModeTabsProps {}

const ConditionViewModeTabsBase = (props: ConditionViewModeTabsProps) => {
  const viewMode = useUnifiedStore(summaryTabViewModeSelector);

  const handleChangeViewMode = (viewMode: SummaryViewMode) => {
    setSummaryTabViewMode(viewMode);
  };

  return (
    <SegmentedControl
      size="xs"
      data={['Table View', 'Chronological']}
      value={viewMode}
      onChange={handleChangeViewMode}
      sx={{
        '.ghost-SegmentedControl-label': {
          width: 96,
        },
      }}
    />
  );
};

export const ConditionViewModeTabs = memo(ConditionViewModeTabsBase);
