import { memo, useEffect, useState } from 'react';
import { Global, MantineProvider, createEmotionCache } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';

import './styles/index.scss';

import { PageAIAppRouter } from './routes/PageAIAppRouter';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { networkErrorEventBus } from '@/shared/hooks/graphql/useGraphQLRequest';
import { ServiceUnavailablePlaceholder } from '@/shared/components/common/placeholders/ServiceUnavailablePlaceholder/ServiceUnavailablePlaceholder';
import { IS_STAGING } from '@/shared/config';
import { usePosthog } from '@/shared/plugins/posthog';
import { useSentry } from '@/shared/plugins/sentry';

const emotionCache = createEmotionCache({ key: 'ghost' });

const PageAIAppBase = () => {
  const [isNetworkError, setIsNetworkError] = useState(false);
  const { isLoading } = useAuth0();
  useSentry();
  usePosthog();

  useEffect(() => {
    const unsubscribe = networkErrorEventBus.subscribe(() => setIsNetworkError(true));

    return unsubscribe;
  }, []);

  const renderApp = () => {
    if (isNetworkError) return <ServiceUnavailablePlaceholder />;

    if (isLoading)
      return (
        <LoadingPlaceholder
          title="Authenticating..."
          description="Please wait while Page verifies your credentials."
          fullScreen
        />
      );

    return (
      <>
        <PageAIAppRouter />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Page</title>
        <link rel="icon" type="image/x-icon" href={IS_STAGING ? '/page-ai-staging.svg' : '/page-ai-favicon.ico'} />
        <link rel="stylesheet" href="https://use.typekit.net/wwl8xjw.css" />
      </Helmet>

      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        emotionCache={emotionCache}
        theme={{
          colorScheme: 'light',
          primaryColor: 'brand',
          primaryShade: 5,
          fontFamily:
            "'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          colors: {
            brand: [
              '#C1C2C5',
              '#A6A7AB',
              '#909296',
              '#5C5F66',
              '#373A40',
              '#2C2E33',
              '#25262B',
              '#1A1B1E',
              '#141517',
              '#101113',
            ],
          },
        }}
      >
        <Global
          styles={{
            '.ck.ck-balloon-panel.ck-balloon-panel_toolbar_west.ck-toolbar-container': {
              display: 'none',
            },
            '.ck.ck-powered-by': {
              display: 'none',
            },
            '.ck.ck-content.ck-editor__editable': {
              minHeight: 24,
              height: 'fit-content',
              width: '100%',
              padding: 0,
              overflow: 'hidden',

              '&.ck.ck-content.ck-editor__editable.ck-focused': {
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
                padding: '1px 0 0 1px',
              },

              p: {
                '&:first-of-type': {
                  marginTop: 0,
                },

                '&:last-of-type': {
                  marginBottom: 0,
                },
              },
            },
          }}
        />

        <ModalsProvider>
          <Notifications />

          {renderApp()}
        </ModalsProvider>
      </MantineProvider>
    </>
  );
};

export const PageAIApp = memo(PageAIAppBase);
