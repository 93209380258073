import { memo, useCallback, useRef } from 'react';
import { Flex, Popover, Text } from '@mantine/core';
import { IconFileSearch } from '@tabler/icons-react';

import { FileIndexSearchInputProps } from './FileIndexSearchInput.types';
import { DebouncedSearchInput } from '@/shared/components/common/forms/DebouncedSearchInput';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { useNavigateToUnifiedContentSearch } from '@/pageAI/hooks/unified/useNavigateToUnifiedContentSearch';
import { posthog } from '@/shared/plugins/posthog';

const FileIndexSearchInputBase = ({ initialValue, onChange }: FileIndexSearchInputProps) => {
  const { navigateToContentSearch } = useNavigateToUnifiedContentSearch();

  const forceRefocusRef = useRef(false);

  const renderInputWrapper = useCallback(
    (inputNode: React.ReactNode) => {
      return (
        <Popover width="target" shadow="sm" defaultOpened withinPortal>
          <Popover.Target>{inputNode}</Popover.Target>

          <Popover.Dropdown p={4} onMouseDownCapture={() => (forceRefocusRef.current = true)}>
            <Flex direction="column" gap={4}>
              <MenuItem
                icon={<IconFileSearch size={14} />}
                title={<Text fz="0.75rem">Search file contents</Text>}
                onMouseDown={() => {
                  const inputElement = document.querySelector('.search-input input') as HTMLInputElement | null;

                  navigateToContentSearch(inputElement?.value || initialValue);
                }}
              />
            </Flex>
          </Popover.Dropdown>
        </Popover>
      );
    },
    [initialValue, navigateToContentSearch],
  );

  const handleChange = useCallback(
    (value: string) => {
      posthog.capture('[Files] Search file index', {
        searchQuery: value,
      });

      onChange?.(value);
    },
    [onChange],
  );

  return (
    <DebouncedSearchInput
      initialValue={initialValue}
      w={200}
      placeholder="Enter file name or index..."
      onChange={handleChange}
      renderInputWrapper={renderInputWrapper}
      forceRefocusRef={forceRefocusRef}
    />
  );
};

export const FileIndexSearchInput = memo(FileIndexSearchInputBase);
