import {
  ActionIcon,
  DefaultMantineColor,
  CopyButton as MantineCopyButton,
  MantineNumberSize,
  Tooltip,
} from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';

interface CopyButtonProps {
  value?: string;
  size?: MantineNumberSize;
  icon?: React.ReactNode;
  iconSize?: string | number;
  copiedTooltip?: string;
  tooltip?: string;
  color?: DefaultMantineColor;
  testId?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  value = '',
  tooltip = 'Copy',
  copiedTooltip = 'Copied',
  size,
  icon,
  iconSize = '1rem',
  color = 'gray',
  testId,
  onClick,
}) => {
  return (
    <MantineCopyButton value={value} timeout={2000}>
      {({ copied, copy }) => (
        <Tooltip label={copied ? copiedTooltip : tooltip} withArrow withinPortal>
          <ActionIcon
            color={copied ? 'teal' : color}
            onClick={(event) => {
              event.stopPropagation();

              onClick?.(event);

              copy();
            }}
            size={size}
            data-testid={testId}
          >
            {copied ? <IconCheck size={iconSize} /> : icon || <IconCopy size={iconSize} />}
          </ActionIcon>
        </Tooltip>
      )}
    </MantineCopyButton>
  );
};
