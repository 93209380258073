export const replaceTags = (text: string, tag: string, replacer: string) => {
  return text.replace(new RegExp(`<${tag}>(.*?)<\\/${tag}>`, 'gi'), `${replacer}$1${replacer}`);
};

export const extractStringsBetweenTags = (text: string, tag: string) => {
  const regex = new RegExp(`<${tag}>(.*?)<\\/${tag}>`, 'gi');
  const matches = text.match(regex);

  return matches?.map((match) => match.replace(new RegExp(`<${tag}>|<\\/${tag}>`, 'gi'), '')) || [];
};
