import { memo, useCallback, useEffect, useState } from 'react';
import { ActionIcon, Flex, clsx } from '@mantine/core';
import { IconBookmark, IconBookmarkFilled } from '@tabler/icons-react';

import { CaseTimelineItemBookmarkButtonProps } from './CaseTimelineItemBookmarkButton.types';
import { useBookmarkTimelineEvent } from '@/pageAI/hooks/caseTimeline/useBookmarkTimelineEvent';
import { posthog } from '@/shared/plugins/posthog';

const CaseTimelineItemBookmarkButtonBase = ({
  client,
  item,
  isRecentlyUpdated = false,
}: CaseTimelineItemBookmarkButtonProps) => {
  const [isBookmarked, setIsBookmarked] = useState(item.bookmarked);
  const { bookmarkTimelineEvent, unbookmarkTimelineEvent } = useBookmarkTimelineEvent();

  useEffect(() => {
    if (isRecentlyUpdated && item.bookmarked !== isBookmarked) {
      setIsBookmarked(item.bookmarked);
    }
  }, [isBookmarked, item.bookmarked, isRecentlyUpdated]);

  const handleAddBookmark = useCallback(() => {
    setIsBookmarked(true);

    posthog.capture('[Timeline] Bookmark event', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      eventId: item.id,
      eventReference: item.reference,
    });

    bookmarkTimelineEvent(client, item);
  }, [client, item, bookmarkTimelineEvent]);

  const handleRemoveBookmark = useCallback(() => {
    setIsBookmarked(false);

    posthog.capture('[Timeline] Unbookmark event', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      eventId: item.id,
      eventReference: item.reference,
    });

    unbookmarkTimelineEvent(client, item);
  }, [client, item, unbookmarkTimelineEvent]);

  return (
    <Flex
      display="inline-flex"
      className={clsx('extra-action', 'extra-action--bookmark', { 'extra-action--bookmarked': isBookmarked })}
      gap={4}
      sx={{
        ...(isBookmarked
          ? {
              '&.extra-action.extra-action--bookmark': {
                opacity: 1,
                pointerEvents: 'all',
              },
            }
          : {}),
      }}
    >
      <ActionIcon size="xs" color="gray.8" onClick={isBookmarked ? handleRemoveBookmark : handleAddBookmark}>
        {isBookmarked ? <IconBookmarkFilled size={16} /> : <IconBookmark size={16} />}
      </ActionIcon>
    </Flex>
  );
};

export const CaseTimelineItemBookmarkButton = memo(CaseTimelineItemBookmarkButtonBase);
