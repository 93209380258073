import { create } from 'zustand';

import { FetchStatus } from '@/shared/@types';

interface FileSummaryState {
  summary: string;
  relevant?: boolean;
  status: FetchStatus;
}

interface FileSummaryStore {
  fileSummary: Record<string, FileSummaryState>;
}

export const useFileSummaryState = create<FileSummaryStore>((set) => ({
  fileSummary: {},
}));

export const fileSummarySelector = (fileId: string) => (state: FileSummaryStore) => state.fileSummary[fileId];

interface SetFileSummaryProps {
  fileId: string;
  summary: string;
  relevant?: boolean;
  status: FetchStatus;
}

export const setFileSummary = ({ fileId, summary, relevant, status }: SetFileSummaryProps) => {
  useFileSummaryState.setState((state) => ({
    ...state,
    fileSummary: {
      ...state.fileSummary,
      [fileId]: {
        summary,
        relevant,
        status,
      },
    },
  }));
};
