import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { ActionIcon, Alert, Box, Button, ColorInput, Flex, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { usePrevious } from '@mantine/hooks';
import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';

import { UpsertFileGroupLayoutProps } from './UpsertFileGroupLayout.types';
import { useFileIndexConfig } from '@/pageAI/hooks/files/useFileIndexConfig';
import { FileNameSelector } from '../FileNameSelector';
import { FileGroup } from '@/pageAI/gql/graphql';
import { useManageFileGroups } from '@/pageAI/hooks/files/useManageFileGroups';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { TIMELINE_EVENT_TYPE_COLOR_MAPPING } from '@/pageAI/services/caseTimeline';
import { applyFileGroupFilterEventBus } from '../../timeline/TimelineFilters';

const UpsertFileGroupLayoutBase = ({
  organizationId,
  selectedFileGroup,
  isEdit = false,
  onBack,
  onClose,
  onDuplicate,
}: UpsertFileGroupLayoutProps) => {
  const theme = useMantineTheme();
  const location = useLocation();

  const { notify } = useNotifications();
  const initialized = useRef(false);
  const previousInitialized = usePrevious(initialized.current);
  const readonly = isEdit && selectedFileGroup?.isDefault;
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const shouldApply = useRef(false);

  const { fileNames, isLoading: isLoadingFileIndexConfig, isError } = useFileIndexConfig();

  const form = useForm({
    initialValues: {
      color:
        selectedFileGroup?.color ||
        (selectedFileGroup?.displayName &&
          theme.colors[
            TIMELINE_EVENT_TYPE_COLOR_MAPPING[
              selectedFileGroup.displayName as keyof typeof TIMELINE_EVENT_TYPE_COLOR_MAPPING
            ]
          ]?.[1]) ||
        theme.colors.gray[1],
      fileGroupName: selectedFileGroup?.displayName || '',
      selectedFiles: [] as string[],
    },
    validate: {
      fileGroupName: (value) => (!value ? 'Custom filter name is required' : null),
      selectedFiles: (value) => (value.length === 0 ? 'At least one file must be selected' : null),
    },
  });
  const { createFileGroup, isCreating, updateFileGroup, isUpdating } = useManageFileGroups(organizationId);

  useEffect(() => {
    if (!selectedFileGroup || initialized.current) return;

    initialized.current = true;

    form.setFieldValue('selectedFiles', selectedFileGroup.fileNames);
  }, [selectedFileGroup, form]);

  const justInitialized = !previousInitialized && initialized.current;

  useEffect(() => {
    if (justInitialized) form.resetDirty();
  }, [justInitialized, form]);

  const isLoading = isCreating || isUpdating;
  const isFormDirty = useMemo(
    () => form.isDirty('fileGroupName') || form.isDirty('selectedFiles') || form.isDirty('color'),
    [form],
  );

  const selectedFileNames = form.values.selectedFiles;

  const selectableFiles = useMemo(
    () => fileNames?.filter((fileName) => !selectedFileNames.includes(fileName)) || [],
    [fileNames, selectedFileNames],
  );

  const handleSubmit = async (values: typeof form.values) => {
    if (isEdit) {
      if (!selectedFileGroup) return;

      const responseBody = await updateFileGroup({
        id: selectedFileGroup.id,
        displayName: values.fileGroupName,
        color: values.color,
        ...(form.isDirty('selectedFiles') && { fileNames: values.selectedFiles }),
      });

      notify('Success', 'Custom filter saved', 'brand');

      if (shouldApply.current) {
        applyFilterToTimeline(responseBody.updateFileGroup);
        shouldApply.current = false;
      } else {
        onBack?.();
      }

      return responseBody.updateFileGroup;
    }

    const responseBody = await createFileGroup({
      organizationId,
      color: values.color,
      displayName: values.fileGroupName,
      fileNames: values.selectedFiles,
    });

    notify('Success', 'Custom filter created', 'brand');

    if (shouldApply.current) {
      applyFilterToTimeline(responseBody.createFileGroup);
      shouldApply.current = false;
    } else {
      onBack?.();
    }

    return responseBody.createFileGroup;
  };

  const applyFilterToTimeline = (fileGroupToApply: FileGroup) => {
    applyFileGroupFilterEventBus.publish({ fileGroups: [fileGroupToApply] });

    onClose?.();
  };

  const handleSaveAndApply = async () => {
    if (!form.isValid()) {
      return submitFormButtonRef.current?.click();
    }

    if (isFormReallyDirty || !isEdit) {
      submitFormButtonRef.current?.click();
      shouldApply.current = true;

      return;
    }

    if (!selectedFileGroup) return;

    applyFilterToTimeline(selectedFileGroup);

    onClose?.();
  };

  const handleSelectFile = useCallback(
    (selectedFileName: string) => {
      form.setFieldValue('selectedFiles', [selectedFileName, ...form.values.selectedFiles]);
    },
    [form],
  );

  const renderSelectedItemActions = useCallback(
    (fileName: string) => {
      return (
        <ActionIcon
          color="dark.3"
          size="xs"
          onClick={() =>
            form.setFieldValue(
              'selectedFiles',
              form.values.selectedFiles.filter((f) => f !== fileName),
            )
          }
        >
          <IconX size={12} />
        </ActionIcon>
      );
    },
    [form],
  );

  const isFormReallyDirty = !justInitialized && isFormDirty;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Flex direction="column" gap="md">
        {isEdit && (
          <Alert
            icon={<IconInfoCircle />}
            variant="outline"
            color="gray"
            sx={{
              paddingLeft: 8,
              paddingTop: 8,
              paddingBottom: 8,
              '.ghost-Alert-icon': {
                marginRight: 8,
              },
            }}
          >
            <Flex justify="space-between">
              <Text fz="0.875rem" color="dark">
                This custom filter is used throughout your organization. Any changes made will affect all the other
                members.
              </Text>
            </Flex>
          </Alert>
        )}

        <Flex direction="column" gap={4}>
          <Text fz="0.875rem" fw={500} color="dark">
            Custom filter name
          </Text>

          <Flex align="start" gap="xs">
            <ColorInput
              {...form.getInputProps('color')}
              sx={{
                '.ghost-ColorInput-input': { width: 24, paddingRight: 0, cursor: 'pointer' },
                '.ghost-ColorInput-rightSection': { display: 'none' },
              }}
              dropdownZIndex={1001}
              closeOnColorSwatchClick
              swatchesPerRow={5}
              withinPortal
              withPicker={false}
              disallowInput
              format="hex"
              readOnly={readonly}
              disabled={isLoading}
              swatches={[
                theme.colors.blue[1],
                theme.colors.green[1],
                theme.colors.grape[1],
                theme.colors.teal[1],
                theme.colors.red[1],
                theme.colors.yellow[1],
                theme.colors.orange[2],
                theme.colors.cyan[2],
                theme.colors.lime[2],
                theme.colors.gray[1],
              ]}
            />

            <TextInput
              {...form.getInputProps('fileGroupName')}
              sx={{ flex: '1 1' }}
              disabled={isLoading}
              readOnly={readonly}
            />
          </Flex>
        </Flex>

        <Flex gap="md">
          <Box data-testid="selectable-files" sx={{ width: '100%' }}>
            <FileNameSelector
              fileList={selectableFiles}
              onSelect={handleSelectFile}
              loading={isLoadingFileIndexConfig}
            />
          </Box>

          <Box data-testid="selected-files" sx={{ width: '100%' }}>
            <FileNameSelector
              fileList={form.values.selectedFiles}
              title="Selected"
              renderItemActions={renderSelectedItemActions}
              loading={isLoadingFileIndexConfig || (isEdit && !initialized.current)}
              error={form.errors['selectedFiles']}
            />
          </Box>
        </Flex>

        <Flex align="center" justify="end" gap="xs">
          {isEdit && (
            <Button variant="subtle" color="gray.7" onClick={onDuplicate}>
              Duplicate
            </Button>
          )}

          {location.pathname.includes('/summaries/caseTimeline') ? (
            <>
              <Button
                ref={submitFormButtonRef}
                variant="outline"
                type="submit"
                disabled={isLoading || (isEdit && !isFormReallyDirty)}
              >
                {isEdit ? 'Save' : 'Create'}
              </Button>

              <Button loading={isLoading} onClick={handleSaveAndApply}>
                <>
                  {(() => {
                    if (!isEdit) return 'Create and apply to timeline';

                    if (isFormReallyDirty) return 'Save and apply to timeline';

                    return 'Apply to timeline';
                  })()}
                </>
              </Button>
            </>
          ) : (
            <Button ref={submitFormButtonRef} type="submit" loading={isLoading} disabled={isEdit && !isFormReallyDirty}>
              {isEdit ? 'Save' : 'Create'}
            </Button>
          )}
        </Flex>
      </Flex>
    </form>
  );
};

export const UpsertFileGroupLayout = memo(UpsertFileGroupLayoutBase);
