import { memo } from 'react';
import { Button } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { showMedicalTermSearchSelector, toggleShowMedicalTermSearch, useUnifiedStore } from '@/pageAI/states/unified';

interface ShowConditionSearchTermsButtonProps {}

const ShowConditionSearchTermsButtonBase = (props: ShowConditionSearchTermsButtonProps) => {
  const opened = useUnifiedStore(showMedicalTermSearchSelector);

  return null;

  return (
    <Button
      variant="outline"
      color="gray.7"
      rightIcon={opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
      onClick={toggleShowMedicalTermSearch}
      size="xs"
    >
      Medical term search
    </Button>
  );
};

export const ShowConditionSearchTermsButton = memo(ShowConditionSearchTermsButtonBase);
