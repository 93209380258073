import { memo, useCallback } from 'react';
import { Flex, Text, Tooltip } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';

import { InlineEditor } from '@/shared/components/editor/InlineEditor';

interface TimelineEditorRowProps {
  data: string;
  index: number;
  hideNumbering?: boolean;
  isFirstGroup?: boolean;
  onChange: (data: string, index: number) => void;
  onRemove?: (index: number) => void;
}

const ITEM_REMOVE_HINT_STORAGE_KEY = 'shouldShowEventEditorItemRemoveHint';

const TimelineEditorRowBase = ({
  data,
  index,
  onChange,
  onRemove,
  isFirstGroup = false,
  hideNumbering = false,
}: TimelineEditorRowProps) => {
  const [showHint, setShowHint] = useLocalStorage({
    defaultValue: true,
    key: ITEM_REMOVE_HINT_STORAGE_KEY,
  });

  const handleChange = useCallback(
    (newData: string) => {
      onChange(newData, index);
    },
    [onChange, index],
  );

  const handleRemove = useCallback(() => {
    onRemove?.(index);
  }, [onRemove, index]);

  const indexContainer = (
    <Flex
      onClick={handleRemove}
      onMouseEnter={() => setShowHint(false)}
      align="center"
      justify="center"
      sx={(theme) => ({
        padding: 4,
        width: 20,
        height: 20,
        background: theme.colors.gray[1],
        borderRadius: 4,
        transition: 'all 0.1s ease-in-out',
        ...(onRemove
          ? {
              cursor: 'pointer',
              svg: {
                display: 'none',
                color: theme.white,
              },
              '&:hover': {
                background: theme.colors.red[5],
                '.index': {
                  display: 'none',
                },
                svg: {
                  display: 'block',
                },
              },
            }
          : {}),
      })}
      mt={10}
      mr={6}
    >
      <Text className="index" fz="0.75rem" fw={500} color="dark">
        {index + 1}
      </Text>

      {onRemove && <IconX size={14} />}
    </Flex>
  );

  const renderNumbering = () => {
    if (hideNumbering) return null;

    if (!onRemove) return indexContainer;

    if (index === 0 && showHint && isFirstGroup) {
      return (
        <Tooltip
          label={
            <Flex align="center" gap="xs">
              Hover over the ordinal numbers if you want to remove an item
              <IconX style={{ cursor: 'pointer' }} size={14} onClick={() => setShowHint(false)} />
            </Flex>
          }
          withinPortal
          withArrow
          opened
        >
          {indexContainer}
        </Tooltip>
      );
    }

    return (
      <Tooltip label="Remove" withinPortal withArrow>
        {indexContainer}
      </Tooltip>
    );
  };

  return (
    <Flex direction="column">
      <Flex>
        {renderNumbering()}

        <Flex
          sx={(theme) => ({
            padding: '6px 12px',
            width: '100%',
            border: `1px solid ${theme.colors.gray[3]}`,
            borderRadius: 4,
            transition: 'all 0.1s',
            '&:hover': {
              borderColor: theme.colors.dark[4],
            },
            maxWidth: 640,
            '.ck.ck-content': {
              ul: {
                margin: 0,
              },
            },
          })}
        >
          <InlineEditor data={data} onDataChange={handleChange} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const TimelineEditorRow = memo(TimelineEditorRowBase);
