import { memo, useCallback, useMemo } from 'react';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';

import { selectedConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { useManageConditionEvents } from '@/pageAI/hooks/conditions/useManageConditionEvents';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { AddOrRemoveEventFromConditionButtonProps } from './AddOrRemoveEventFromConditionButton.types';
import { doesConditionHaveEvent } from '@/pageAI/services/medicalConditions';
import { RemoveEventFromConditionButton } from '../RemoveEventFromConditionButton';
import { AddEventToUnknownConditionButton } from '../AddEventToUnknownConditionButton';
import { posthog } from '@/shared/plugins/posthog';

const AddOrRemoveEventFromConditionButtonBase = ({ event, tab }: AddOrRemoveEventFromConditionButtonProps) => {
  const client = useSelectedClient(true);

  const { removeEventsFromCondition, isRemoving } = useManageConditionEvents(client.id);

  const selectedCondition = useUnifiedStore(selectedConditionSelector);

  const hasEvent = useMemo(
    () => selectedCondition && doesConditionHaveEvent(selectedCondition, event.id),
    [selectedCondition, event.id],
  );

  const handleClickRemove = useCallback(() => {
    if (!selectedCondition) return;

    modals.openConfirmModal({
      title: 'Remove event',
      children: (
        <Text fz="0.875rem" color="dark">
          Are you sure you want to remove this information from the condition summary for&nbsp;
          <strong>{selectedCondition.headerCondition}</strong>?
        </Text>
      ),
      labels: {
        confirm: 'Remove',
        cancel: 'Cancel',
      },
      cancelProps: {
        variant: 'subtle',
        color: 'gray.7',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: () => {
        posthog.capture('[Conditions] Remove event from condition', {
          clientId: client.id,
          clientFirstName: client.firstName,
          clientLastName: client.lastName,
          conditionId: selectedCondition.id,
          conditionHeader: selectedCondition.headerCondition,
          eventId: event.id,
          eventReference: event.reference,
        });

        removeEventsFromCondition(selectedCondition.id, [event.id]);
      },
    });
  }, [client, selectedCondition, event, removeEventsFromCondition]);

  if (!selectedCondition) return null;

  if (hasEvent) return <RemoveEventFromConditionButton tab={tab} onClick={handleClickRemove} loading={isRemoving} />;

  return <AddEventToUnknownConditionButton event={event} tab={tab} />;
};

export const AddOrRemoveEventFromConditionButton = memo(AddOrRemoveEventFromConditionButtonBase);
