import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';

import { addEventsToConditionsMutation, removeEventsFromConditionMutation } from '@/pageAI/api/conditions';
import {
  AddEventsToConditionsInput,
  AddEventsToConditionsMutation,
  RemoveEventsFromConditionInput,
  RemoveEventsFromConditionMutation,
} from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { singularOrPlural } from '@/shared/utils/string';
import { useUpdateCondition } from '../useUpdateCondition';
import { showAsyncNotification } from '@/shared/services/notifications';

export const useManageConditionEvents = (clientId: string) => {
  const { request } = useGraphQLRequest();
  const { updateConditionLocally } = useUpdateCondition(clientId);

  const { mutateAsync: addEventsToConditionsBase, isLoading: isAdding } = useMutation<
    AddEventsToConditionsMutation,
    Error,
    AddEventsToConditionsInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(addEventsToConditionsMutation, { input });

      return responseBody;
    },
  });

  const addEventsToConditions = useCallback(
    async (conditionIds: string[], eventIds: string[]) => {
      const { onSuccess, onError } = showAsyncNotification(
        'Executing...',
        `Adding ${eventIds.length} ${singularOrPlural('event', 'events')(eventIds.length)} to the condition(s)`,
      );

      try {
        const responseBody = await addEventsToConditionsBase({
          conditionIds,
          events: eventIds.map((eventId) => ({ id: eventId })),
          clientId,
        });

        responseBody.addEventsToConditions.forEach((condition) => {
          updateConditionLocally(condition.id, condition);
        });

        onSuccess(
          'Success',
          `${eventIds.length} ${singularOrPlural('event', 'events')(eventIds.length)} ${singularOrPlural(
            'was',
            'were',
          )(eventIds.length)} added to the condition(s).`,
        );
      } catch (error) {
        console.error(error);

        if ((error as Error)?.message?.includes('re-run')) {
          return onError('Error', 'The condition summary needs to be re-generated for this functionality to work.');
        }

        onError('Error', 'Failed to add the selected event(s) to the condition(s).');
      }
    },
    [addEventsToConditionsBase, clientId, updateConditionLocally],
  );

  const { mutateAsync: removeEventsFromConditionBase, isLoading: isRemoving } = useMutation<
    RemoveEventsFromConditionMutation,
    Error,
    RemoveEventsFromConditionInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(removeEventsFromConditionMutation, { input });

      return responseBody;
    },
  });

  const removeEventsFromCondition = useCallback(
    async (conditionId: string, eventIds: string[]) => {
      const { onSuccess, onError } = showAsyncNotification(
        'Executing...',
        `Removing ${eventIds.length} ${singularOrPlural('event', 'events')(eventIds.length)} from condition`,
      );

      try {
        const responseBody = await removeEventsFromConditionBase({
          conditionId: conditionId,
          events: eventIds.map((eventId) => ({ id: eventId })),
          clientId,
        });

        updateConditionLocally(conditionId, responseBody.removeEventsFromCondition);

        onSuccess(
          'Success',
          `${eventIds.length} ${singularOrPlural('event', 'events')(eventIds.length)} ${singularOrPlural(
            'was',
            'were',
          )(eventIds.length)} removed from the condition.`,
        );
      } catch (error) {
        console.error(error);

        if ((error as Error)?.message?.includes('re-run')) {
          return onError('Error', 'The condition summary needs to be re-generated for this functionality to work.');
        }

        onError('Error', 'Failed to remove the selected event(s) from the condition.');
      }
    },
    [clientId, removeEventsFromConditionBase, updateConditionLocally],
  );

  return {
    addEventsToConditions,
    removeEventsFromCondition,
    isAdding,
    isRemoving,
  };
};
