import { memo } from 'react';
import { Flex, Tooltip } from '@mantine/core';
import { IconStar, IconStarFilled } from '@tabler/icons-react';

import { MarkFileAsImportantButtonProps } from './MarkFileAsImportantButton.types';

const MarkFileAsImportantButtonBase = ({ fileAsset }: MarkFileAsImportantButtonProps) => {
  if (!fileAsset.isImportant)
    return (
      <Tooltip
        label="Files are marked as important when they have been bookmarked or commented on in your timeline."
        withArrow
        withinPortal
      >
        <Flex
          className="important-icon"
          align="center"
          justify="center"
          sx={(theme) => ({
            color: theme.colors.gray[5],
          })}
        >
          <IconStar size={14} />
        </Flex>
      </Tooltip>
    );

  return (
    <Tooltip
      label="Files are marked as important when they have been bookmarked or commented on in your timeline."
      withArrow
      withinPortal
    >
      <Flex
        className="important-icon"
        align="center"
        justify="center"
        sx={(theme) => ({
          color: theme.colors.yellow[5],
        })}
      >
        <IconStarFilled size={14} />
      </Flex>
    </Tooltip>
  );
};

export const MarkFileAsImportantButton = memo(MarkFileAsImportantButtonBase);
