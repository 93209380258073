import { memo, useMemo } from 'react';
import { Flex, LoadingOverlay } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';

import { ClientMainLayoutProps } from './ClientMainLayout.types';
import { ClientFilePanel } from '../ClientFilePanel';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { getClientFiles } from '@/pageAI/services/clients';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { useFileUploadStatusForClient } from '@/pageAI/hooks/files/useUploadFileStatusForClient';
import { FileUploadStatus } from '@/shared/@types';
import { ClientAlert } from '../ClientAlert';
import { SummariesPanel } from '@/pageAI/components/summaries/SummariesPanel';
import { ClientNotFoundPlaceholder } from '../ClientNotFoundPlaceholder';
import { FileClassificationAlert } from '@/pageAI/components/files/FileClassificationAlert';
import { truthy } from '@/shared/utils/boolean';

const ClientMainLayoutBase = ({ clientId }: ClientMainLayoutProps) => {
  const { client, isLoading, isError } = useClient({ clientId });
  const { status: uploadStatus } = useFileUploadStatusForClient(clientId);
  const clientFileObject = useMemo(() => (client ? getClientFiles(client) : undefined), [client]);
  const { ref, width } = useElementSize();

  if (isLoading) return <LoadingOverlay visible />;

  if (isError)
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder />
      </Flex>
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  const isFileEmpty = !clientFileObject?.fileAssets || !clientFileObject.fileAssets.length;
  const canShowSummaryPanel = !isFileEmpty || uploadStatus === FileUploadStatus.UPLOADING;

  return (
    <Flex
      direction="column"
      gap="md"
      sx={{
        width: '100%',
        ...(width <= 1040 && {
          '.file-name': {
            maxWidth: width <= 780 ? width / 4 : width <= 864 ? 400 : 240,
          },
        }),
      }}
      px="md"
      ref={ref}
    >
      <Flex direction="column" gap="md">
        <ClientAlert client={client} />

        <FileClassificationAlert client={client} />

        {canShowSummaryPanel && <SummariesPanel client={client} />}

        <ClientFilePanel
          client={client}
          hiddenColumns={
            width <= 1040
              ? ['Status', width <= 864 ? 'Received Date' : undefined, width <= 864 ? 'Size' : undefined].filter(truthy)
              : undefined
          }
          colgroup={
            width <= 1040 ? (
              <colgroup>
                <col style={{ width: '3%', minWidth: 100 }} />
                <col style={{ width: width <= 864 ? '70%' : '55%' }} />
                <col style={{ width: '15%' }} />
                {width > 864 && <col style={{ width: '12%' }} />}
                {width > 1040 && <col style={{ width: '10%' }} />}
                <col style={{ width: '5%' }} />
              </colgroup>
            ) : undefined
          }
        />
      </Flex>
    </Flex>
  );
};

export const ClientMainLayout = memo(ClientMainLayoutBase);
