import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FileAsset } from '@/shared/@types';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { isInViewport } from '@/shared/lib/dom';

let isHardRefresh = true;

export const useFileIdSearchParams = (
  sortedFileAssets: FileAsset[],
  currentLoad: number,
  setCurrentLoad: (newLoad: number) => void,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { notify } = useNotifications();

  const fileId = searchParams.get('fileId');

  const removeFileIdSearchParam = useCallback(() => {
    setSearchParams(
      (currentSearchParams) => {
        currentSearchParams.delete('fileId');

        return currentSearchParams;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  useEffect(() => {
    if (!fileId || !isHardRefresh) return;

    isHardRefresh = false;

    const fileAssetIndex = sortedFileAssets.findIndex((fileAsset) => fileAsset.id === fileId);

    if (fileAssetIndex === -1) {
      return removeFileIdSearchParam();
    }

    if (fileAssetIndex >= currentLoad) {
      setCurrentLoad(fileAssetIndex + 1);
    }

    requestIdleCallback(() => {
      const fileAssetRowElement = document.querySelector(`.asset-table .row-${fileAssetIndex}`) as
        | HTMLTableRowElement
        | undefined;

      if (!fileAssetRowElement) return;

      fileAssetRowElement.click();

      if (isInViewport(fileAssetRowElement)) return;

      requestIdleCallback(() => {
        fileAssetRowElement.scrollIntoView();
      });
    });

    removeFileIdSearchParam();
  }, [fileId, sortedFileAssets, removeFileIdSearchParam, currentLoad, setCurrentLoad, notify]);
};
