import { memo } from 'react';
import { Box, Flex } from '@mantine/core';
import { IconCalendarOff } from '@tabler/icons-react';

import { CaseTimelinePanel } from '@/pageAI/components/timeline/CaseTimelinePanel';
import { useTimelineSearchParams } from '@/pageAI/hooks/caseTimeline/useTimelineSearchParams';
import { useClientTimelineEvents } from '@/pageAI/hooks/clients/useClientTimelineEvents';
import { ClientNotFoundPlaceholder } from '@/pageAI/components/clients/ClientNotFoundPlaceholder';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { truthy } from '@/shared/utils/boolean';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { TimelineCommentOperationWebSocket } from '../../sockets/TimelineCommentOperationWebSocket';
import { TimelineOperationWebSocket } from '../../sockets/TimelineOperationWebSocket';
import { CaseTimelineStatus } from '@/pageAI/gql/graphql';
import { DataEmptyPlaceholder } from '../../clients/DataEmptyPlaceholder';

const UnifiedTimelineBase = () => {
  const client = useSelectedClient(true);

  const { isLoading: isLoadingFileGroups, isError: isFileGroupsError } = useFileGroups();
  const {
    timelineEvents,
    isLoading: isLoadingTimelineEvents,
    isError: isTimelineEventsError,
  } = useClientTimelineEvents({ clientId: client.id });
  useTimelineSearchParams(timelineEvents, client);

  const isLoading = isLoadingTimelineEvents || isLoadingFileGroups;
  const isError = isTimelineEventsError || isFileGroupsError;

  if (client.caseTimelineStatus !== CaseTimelineStatus.Generated)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <DataEmptyPlaceholder
          icon={<IconCalendarOff size={80} />}
          title="Not generated"
          description="The timeline has yet to be generated for this client."
        />
      </Flex>
    );

  if (isLoading)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <LoadingPlaceholder
          title="Loading timeline..."
          description={[
            'Please wait while Page retrieves the timeline',
            client ? ` of ${getClientDisplayName(client)}` : '',
            '.',
          ]
            .filter(truthy)
            .join('')}
          fullScreen
        />
      </Flex>
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  if (isError && !timelineEvents) throw new Error('Could not load timeline events');

  return (
    <Box
      sx={{
        overflow: 'hidden',
        '#case-timeline-scroll-area': {
          height: 'calc(100vh - 116px)',
        },
      }}
    >
      <CaseTimelinePanel client={client} caseTimeline={timelineEvents} unified />

      <TimelineCommentOperationWebSocket />

      <TimelineOperationWebSocket />
    </Box>
  );
};

export const UnifiedTimeline = memo(UnifiedTimelineBase);
