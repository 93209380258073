export const API_URL = import.meta.env.VITE_APP_API_SERVER_URL || '';

export const CONTACT_EMAIL = import.meta.env.VITE_APP_CONTACT_EMAIL || 'support@pageai.co';

export const WEB_SOCKET_ENDPOINT = import.meta.env.VITE_APP_WS;

export const IS_BUILT_ENV = import.meta.env.PROD;

export const IS_STAGING = typeof API_URL === 'string' && API_URL.includes('.staging.');

export const POST_LOG_OUT_REDIRECT_URL = import.meta.env.VITE_APP_POST_LOG_OUT_REDIRECT_URL as string | undefined;

export const POSTHOG_ID = import.meta.env.VITE_APP_POSTHOG_ID as string | undefined;
