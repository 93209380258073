import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ActionIcon, Box, Flex, ScrollArea, Select } from '@mantine/core';
import { IconCaretLeftFilled, IconCaretRightFilled } from '@tabler/icons-react';

import { FindResultMetadata, HighlightedPart, TextPreviewerProps, TextPreviewerRef } from './TextPreviewer.types';
import {
  addHighlightedPartMarkers,
  divideTextIntoPages,
  findPageIndexOfHighlightedPart,
  getHighlightsFromKeywords,
  scrollToCode,
  scrollToHighlightedPart,
} from './TextPreviewer.utils';
import { MarkdownRenderer } from '../../common/markdown/MarkdownRenderer';
import { SearchButton } from '../SearchButton';
import { HighlightType } from '@/shared/utils/markdown';
import { setPdfPreviewInitalPageIndex } from '@/shared/states/filePreview';
import { isJSON } from '@/shared/utils/object';
import { JSONPreviewer } from '../JSONPreviewer';

const TextPreviewerBase = (
  { content, highlightedPart, paginated = false, highlights, initialPageIndex = 0 }: TextPreviewerProps,
  ref: React.Ref<TextPreviewerRef>,
) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(initialPageIndex);
  const [findResultMetadata, setFindResultMetadata] = useState<FindResultMetadata>();
  const [currentFindResultIndex, setCurrentFindResultIndexBase] = useState(-1);

  const pages = useMemo(() => (paginated ? divideTextIntoPages(content || '') : []), [content, paginated]);

  useEffect(() => {
    setPdfPreviewInitalPageIndex(currentPageIndex);
  }, [currentPageIndex]);

  const setCurrentFindResultIndex = useCallback(
    (newIndex: number, forceFindResultMetadata?: FindResultMetadata) => {
      setCurrentFindResultIndexBase(newIndex);

      const findResultMetadataToUse = forceFindResultMetadata || findResultMetadata;

      if (!findResultMetadataToUse) return;

      const newPageIndex = findResultMetadataToUse.pageByResultIndex[newIndex];

      setCurrentPageIndex(newPageIndex);
    },
    [findResultMetadata],
  );

  useImperativeHandle(
    ref,
    () => ({
      onHighlightedPartChange: (newHighlightedPart: HighlightedPart) => {
        const highlightedPartPageIndex = findPageIndexOfHighlightedPart(pages, newHighlightedPart);

        setCurrentPageIndex(highlightedPartPageIndex);

        setTimeout(() => scrollToHighlightedPart(newHighlightedPart));
      },
    }),
    [pages],
  );

  const pageContent = useMemo(() => {
    if (!paginated || !content) return content;

    let pageInfo = pages[currentPageIndex];

    if (findResultMetadata) {
      pageInfo = { ...pages[findResultMetadata.pageByResultIndex[currentFindResultIndex]] };
      pageInfo.content =
        findResultMetadata.replacedContentsOfPages[findResultMetadata.pageByResultIndex[currentFindResultIndex]];
    }

    if (!pageInfo) return content;

    if (!highlightedPart) return pageInfo.content;

    return addHighlightedPartMarkers(pageInfo, highlightedPart, highlights).content;
  }, [
    content,
    highlightedPart,
    paginated,
    highlights,
    currentPageIndex,
    pages,
    findResultMetadata,
    currentFindResultIndex,
  ]);

  const pageOptions = useMemo(
    () => pages.map((_, index) => ({ value: String(index), label: `Page ${String(index + 1)}` })),
    [pages],
  );

  const goToNextPage = useCallback(() => {
    setCurrentPageIndex((prevIndex) => Math.min(prevIndex + 1, pages.length - 1));
  }, [pages]);

  const goToPreviousPage = useCallback(() => {
    setCurrentPageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  }, []);

  const handleFind = useCallback(
    (textToFind: string) => {
      const findResultMetadata: FindResultMetadata = {
        keyword: textToFind,
        total: 0,
        replacedContentsOfPages: {},
        pageByResultIndex: {},
      };

      pages.forEach((page, pageIndex) => {
        const { highlights, replacedContent } = getHighlightsFromKeywords(
          page.content,
          [textToFind],
          HighlightType.LIGHTER,
          true,
        );

        findResultMetadata.replacedContentsOfPages[pageIndex] = replacedContent;

        highlights.forEach(() => {
          findResultMetadata.pageByResultIndex[findResultMetadata.total] = pageIndex;
          findResultMetadata.total++;
        });
      }, []);

      if (findResultMetadata.total > 0) {
        setFindResultMetadata(findResultMetadata);
        setCurrentFindResultIndex(0, findResultMetadata);
        setTimeout(() => scrollToCode());
      }
    },
    [pages, setCurrentFindResultIndex],
  );

  const isJSONContent = useMemo(() => isJSON(pageContent), [pageContent]);

  const renderContent = () => {
    if (isJSONContent)
      return (
        <ScrollArea.Autosize h="100%">
          <Box p="xs" pb="xl">
            <JSONPreviewer src={pageContent} />
          </Box>
        </ScrollArea.Autosize>
      );

    return (
      <Box
        className="markdown-container"
        sx={(theme) => ({
          height: '100%',
          overflow: 'auto',
          padding: 16,
          'p:first-of-type': { marginTop: 0 },
          h1: {
            fontSize: '1.5rem',
          },
          h2: {
            fontSize: '1.25rem',
          },
          h3: {
            fontSize: '1.125rem',
          },
          h4: {
            fontSize: '1rem',
          },
          h5: {
            fontSize: '0.875rem',
          },
          h6: {
            fontSize: '0.875rem',
          },
          '*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6)': {
            fontSize: '0.875rem',
          },
          blockquote: {
            display: 'inline',
            border: 'none',
            margin: 0,
            padding: 0,
            '> p': {
              margin: 0,
              display: 'inline',
              em: {
                strong: {
                  display: 'inline-block',
                  backgroundColor: theme.colors.yellow[3],
                  fontStyle: 'normal',
                  borderRadius: 4,
                  padding: '0 2px',
                },
              },
            },
          },
          del: {
            textDecoration: 'none',
            backgroundColor: theme.colors.yellow[3],
          },
          code: {
            display: 'inline-block',
            fontFamily: 'unset',
            background: theme.colors.gray[3],
          },
        })}
      >
        <MarkdownRenderer content={pageContent} />
      </Box>
    );
  };

  return (
    <Flex
      sx={(theme) => ({
        width: '100%',
        borderTop: `1px solid ${theme.colors.gray[4]}`,
        background: 'white',
      })}
      direction="column"
    >
      <Flex
        align="center"
        sx={(theme) => ({
          padding: '0px 16px',
          height: 48,
          borderBottom: `1px solid ${theme.colors.gray[4]}`,
        })}
      >
        {!isJSONContent && (
          <SearchButton
            onFind={handleFind}
            currentResultIndex={currentFindResultIndex}
            totalOfResults={findResultMetadata?.total || 0}
            onGoToNextResult={() => setCurrentFindResultIndex(currentFindResultIndex + 1)}
            onGoToPreviousResult={() => setCurrentFindResultIndex(currentFindResultIndex - 1)}
            onClose={() => {
              setFindResultMetadata(undefined);
              setCurrentFindResultIndexBase(-1);
            }}
          />
        )}

        <Box sx={{ flexGrow: 1 }} />

        {pages.length > 0 && (
          <Flex align="center" sx={{ zIndex: 100 }} gap={4}>
            <ActionIcon onClick={goToPreviousPage} variant="outline">
              <IconCaretLeftFilled size={14} />
            </ActionIcon>

            <Select
              size="xs"
              w={100}
              value={String(currentPageIndex)}
              data={pageOptions}
              onChange={(newPageIndex) => setCurrentPageIndex(Number(newPageIndex))}
            />

            <ActionIcon onClick={goToNextPage} variant="outline">
              <IconCaretRightFilled size={14} />
            </ActionIcon>
          </Flex>
        )}
      </Flex>

      {renderContent()}
    </Flex>
  );
};

export const TextPreviewer = memo(forwardRef(TextPreviewerBase));
