import { memo, useMemo } from 'react';
import { sum } from 'lodash-es';
import { useKeyPress } from 'ahooks';
import { Flex } from '@mantine/core';

import { ClientFileSearchTableProps } from './ClientFileSearchTable.types';
import { FileSearchResultCard } from '../FileSearchResultCard';
import { getFileSearchResultElementId, groupFileSearchResults } from '@/pageAI/services/files';
import { useFileSearchStore } from '@/pageAI/states/fileSearch';

const ClientFileSearchTableBase = ({ results: rawResults, query, page }: ClientFileSearchTableProps) => {
  const resultGroups = useMemo(() => groupFileSearchResults(rawResults), [rawResults]);

  const navigateToNext = (isNext: boolean) => {
    const rowIndex = useFileSearchStore.getState().activeResult?.rowIndex;

    if (typeof rowIndex !== 'number') return;

    const rowElement = document.getElementById(getFileSearchResultElementId(isNext ? rowIndex + 1 : rowIndex - 1));

    rowElement?.click();
  };

  useKeyPress(['shift.uparrow'], () => navigateToNext(false));
  useKeyPress(['shift.downarrow'], () => navigateToNext(true));

  return (
    <Flex direction="column" gap={6} pos="relative">
      <Flex direction="column" gap="lg">
        {resultGroups.map((resultGroup, index) => {
          const numberOfPreviousResults = sum(
            resultGroups
              .slice(0, index)
              .map((group) => [...new Set(group.results.map((result) => result.pageNumber))].length),
          );

          return (
            <FileSearchResultCard
              key={[resultGroup.file.id, resultGroup.pageNumber].join('_')}
              resultGroup={resultGroup}
              index={(page - 1) * 10 + index}
              offset={numberOfPreviousResults}
              query={query}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export const ClientFileSearchTable = memo(ClientFileSearchTableBase);
