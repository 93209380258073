import { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFilePreviewStore } from '@/shared/states/filePreview';
import { updateSearchParams } from '@/shared/lib/browser';

const AssetSidebarControllerBase = () => {
  const [, setSearchParams] = useSearchParams();
  const openedAsset = useFilePreviewStore((state) => state.asset);

  useEffect(() => {
    setSearchParams((searchParams) => updateSearchParams(searchParams)('fileId', openedAsset?.id), { replace: true });
  }, [openedAsset, setSearchParams]);

  return null;
};

export const AssetSidebarController = memo(AssetSidebarControllerBase);
