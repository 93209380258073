import { memo, useMemo } from 'react';
import { Flex } from '@mantine/core';

import { useSummaryStatusPolling } from '@/pageAI/hooks/summaries/useSummaryStatusPolling';
import { useSummariesGenerationSchedule } from '@/pageAI/hooks/caseTimeline/useEnsureTimelineGeneration';
import { SummariesTableProps } from './SummariesTable.types';
import { SummaryCard } from '../SummaryCard';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';

const SummariesTableBase = ({ client }: SummariesTableProps) => {
  const { hasScheduled } = useSummariesGenerationSchedule(client);
  useSummaryStatusPolling(client);

  const summaries = useMemo(
    () => [
      {
        id: UnifiedTab.CONDITION_SUMMARY,
        name: 'Condition Summary',
        description:
          'A complete procedural history summary at a condition level with a flexible view to help you easily review the case.',
        updatedAt: client.updatedAt,
        status: hasScheduled ? 'SCHEDULED' : client.summaryByConditionStatus,
      },
      {
        id: UnifiedTab.TIMELINE,
        name: 'Case Timeline',
        description: 'A detailed, filterable, and interactive chronological summary of the entire client file.',
        updatedAt: client.updatedAt,
        status: hasScheduled ? 'SCHEDULED' : client.caseTimelineStatus,
      },
    ],
    [client, hasScheduled],
  );

  return (
    <Flex gap="xl">
      {summaries.map((summary) => {
        return <SummaryCard key={summary.id} {...summary} />;
      })}
    </Flex>
  );
};

export const SummariesTable = memo(SummariesTableBase);
