import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { withPageWrapper } from '@/shared/hocs/withPageWrapper';

const SummaryPageRedirectionBase = () => {
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();

  return <Navigate replace to={`/clients/${clientId}/summaries?${searchParams.toString()}`} />;
};

export const SummaryPageRedirection = withPageWrapper(SummaryPageRedirectionBase);
