import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { clientMergedFileQuery } from '@/pageAI/api';
import { FetchStatus } from '@/shared/@types';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useMergedFile = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const { notify } = useNotifications();
  const [status, setStatus] = useState(FetchStatus.INITIAL);

  const getClientMergedFile = useCallback(
    async (clientId: string) => {
      setStatus(FetchStatus.LOADING);

      try {
        const response = await queryClient.fetchQuery(['client-merged-file', clientId], () => {
          return request(clientMergedFileQuery, { id: clientId });
        });

        setStatus(FetchStatus.SUCCESS);

        return response.client.mergedFile;
      } catch (error) {
        setStatus(FetchStatus.ERROR);

        if ((error as Error).message.includes('Failed to open input PDF file')) {
          notify('Error', 'One of the files of this client is corrupted.');
        } else {
          notify('Error', 'Failed to fetch the merged file');
        }

        throw error;
      }
    },
    [request, queryClient, notify],
  );

  return { getClientMergedFile, isLoading: status === FetchStatus.LOADING, isError: status === FetchStatus.ERROR };
};
