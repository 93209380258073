import { create } from 'zustand';

import { getItemFromDB, saveItemToDB } from '@/shared/lib/storage';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { truthy } from '@/shared/utils/boolean';
import { summarySearchResultQuery } from '@/pageAI/api';
import { SummarySearchDocumentInput, SummarySearchResultQuery } from '@/pageAI/gql/graphql';
import { FetchStatus } from '@/shared/@types';

export const TERM_SEARCH_RESULTS_DB_KEY = 'search_result_count_by_term';
export const AI_FILE_SUMMARY_DB_KEY = 'ai_file_summary';

export type NumberOfResultsByTerm = Record<string, number | undefined>;

interface TermPrefetchResult {
  score: number;
}

interface TermSearchResultsState {
  status: FetchStatus;
  scoreByTerm: Record<string, TermPrefetchResult | undefined>;
}

export const useTermSearchResultsState = create<TermSearchResultsState>((set) => ({
  status: FetchStatus.INITIAL,
  scoreByTerm: {},
}));

export const conditionTermStatusSelector = (state: TermSearchResultsState) => state.status;

export const conditionTermResultSelector = (term: string) => (state: TermSearchResultsState) => state.scoreByTerm[term];

export const resetConditionTermState = () => {
  useTermSearchResultsState.setState((state) => ({
    status: FetchStatus.INITIAL,
    scoreByTerm: {},
  }));
};

export const setConditionTermFetchStatus = (status: FetchStatus) => {
  useTermSearchResultsState.setState((state) => ({
    ...state,
    status,
  }));
};

export const setConditionTermScore = (term: string, score: number) => {
  useTermSearchResultsState.setState((state) => ({
    ...state,
    scoreByTerm: {
      ...state.scoreByTerm,
      [term]: {
        score: score,
      },
    },
  }));
};

export const getConditionCode = (condition: MedicalConditionItem) => {
  return condition.diagnosticCode?.split('-')?.[0];
};

export const getConditionSearchTermsByCode = (
  condition: MedicalConditionItem,
  conditionSearchTermsByCode?: Record<string, string[]>,
) => {
  return condition.diagnosticCode ? conditionSearchTermsByCode?.[condition.diagnosticCode.split('-')?.[0]] || [] : [];
};

export const getConditionSearchTerms = (
  condition: MedicalConditionItem,
  conditionSearchTermsByCode?: Record<string, string[]>,
) => {
  return [
    ...new Set([
      ...getConditionSearchTermsByCode(condition, conditionSearchTermsByCode),
      condition.headerCondition,
      ...condition.subConditions,
    ]),
  ].filter(truthy);
};

interface FetchSearchResultSummariesProps {
  clientId: string;
  condition: string;
  documents: SummarySearchDocumentInput[];
  keyword: string[];
}

export const fetchSearchResultSummaries =
  (request: GraphQLRequestFunction) =>
  async ({ clientId, condition, documents, keyword }: FetchSearchResultSummariesProps) => {
    const cache = await getAIFileSummaryCache({ clientId, condition, documents, keyword });

    if (cache) return cache;

    const response = await request(summarySearchResultQuery, { clientId, condition, documents, keyword });

    await saveAIFileSummaryCache({ clientId, condition, documents, keyword, results: response.summarySearchResult });

    return response.summarySearchResult;
  };

export const getAIFileSummaryCacheKey = ({
  clientId,
  condition,
  documents,
  keyword,
}: FetchSearchResultSummariesProps) => {
  return `${AI_FILE_SUMMARY_DB_KEY}_${clientId}_${condition}_${documents.map((doc) => doc.id).join('_')}_${keyword.join(
    '_',
  )}`;
};

interface SaveAIFileSummaryProps extends FetchSearchResultSummariesProps {
  results: SummarySearchResultQuery['summarySearchResult'];
}

export const getAIFileSummaryCache = async ({
  clientId,
  condition,
  documents,
  keyword,
}: FetchSearchResultSummariesProps) => {
  const cacheKey = getAIFileSummaryCacheKey({ clientId, condition, documents, keyword });

  const rawResults = (await getItemFromDB(cacheKey)) as string | undefined;

  if (!rawResults) return;

  return JSON.parse(rawResults) as SummarySearchResultQuery['summarySearchResult'];
};

export const saveAIFileSummaryCache = async ({
  clientId,
  condition,
  documents,
  keyword,
  results,
}: SaveAIFileSummaryProps) => {
  const cacheKey = getAIFileSummaryCacheKey({ clientId, condition, documents, keyword });

  await saveItemToDB(cacheKey, JSON.stringify(results));
};

export const getTermsFromQuery = (query: string) => {
  const terms = query
    .split(' or ')
    .map((term) => term.trim())
    .filter(truthy);

  return terms;
};

export const getQueryFromTerms = (terms: string[]) => {
  return terms.join(' or ');
};
