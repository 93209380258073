import { memo } from 'react';
import { ActionIcon, Button, Flex, Modal, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';

import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useDeleteFiles } from '@/shared/hooks/files/useDeleteFiles';
import { AssetType, FileAsset } from '@/shared/@types';
import { DeleteFileButtonProps } from './DeleteAssetButton.types';
import { posthog } from '@/shared/plugins/posthog';

const DeleteAssetButtonBase: React.FC<DeleteFileButtonProps> = ({ assets, collectionId, onDeleted }) => {
  const [opened, { open, close }] = useDisclosure();
  const { deleteFiles } = useDeleteFiles(collectionId);
  const { notify } = useNotifications();

  const handleDeleteFile = async () => {
    const hideDeletingNotification = notify('Operation', `Deleting ${assets.length} file(s)...`, 'primary', false);

    posthog.capture('[Files] Delete file', {
      firstFileId: assets[0].id,
      firstFileName: assets[0].name,
      firstFileIndex: (assets[0] as FileAsset).metadata?.pageAIMetadata?.cleanedFileName,
      files: assets,
    });

    try {
      const assetIds = assets.map((asset) => asset.id);

      await deleteFiles({ ids: assetIds });

      await onDeleted?.(assets);

      hideDeletingNotification();
    } catch (error) {
      notify('Error', 'File(s) could not be deleted.');
    }
  };

  const handleDeleteAsset = async () => {
    close();

    if (assets.length === 1 && assets[0].type === AssetType.COLLECTION) return;

    handleDeleteFile();
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    open();
  };

  return (
    <>
      <Tooltip label="Delete" onClick={handleClick} withArrow withinPortal>
        <ActionIcon size="xs">
          <IconTrash size={14} />
        </ActionIcon>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        centered
        title="Delete file"
        sx={{
          '.ghost-Modal-title': {
            fontWeight: 500,
          },
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <Flex direction="column" gap="md">
          <Text color="dark.5" fz="0.875rem">
            {assets.length > 1 ? (
              <>Are you sure you want to delete the {assets.length} selected assets?</>
            ) : (
              <>
                Are you sure you want to delete this asset: <strong>{assets[0].name}</strong>?
              </>
            )}
          </Text>

          <Flex sx={{ alignSelf: 'flex-end' }} gap="xs">
            <Button onClick={close} color="dark" variant="subtle">
              Cancel
            </Button>

            <Button onClick={handleDeleteAsset} color="red">
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const DeleteAssetButton = memo(DeleteAssetButtonBase);
