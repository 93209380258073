import { memo, ElementType, useMemo } from 'react';
import { Highlight } from '@mantine/core';
import { escapeRegExp } from 'lodash-es';

import { useMarkdownRendererContext } from '../MarkdownRendererContextProvider';

interface MarkdownElementRendererProps {
  node?: unknown;
  children?: React.ReactNode;
}

export const createMarkdownElementRenderer = (Element: ElementType) => {
  const MarkdownElementRendererBase = ({ children, node, ...rest }: MarkdownElementRendererProps) => {
    const { highlights, matchWholeWords, matchWholePhrase } = useMarkdownRendererContext((state) => state);

    const finalHighlights = useMemo(() => {
      if (typeof children !== 'string') return highlights;

      let updatedHighlights = highlights;

      if (matchWholePhrase) {
        updatedHighlights = [updatedHighlights.join(' ')];
      }

      if (matchWholeWords) {
        updatedHighlights = updatedHighlights.filter((highlight) => {
          const regex = new RegExp(`\\b${escapeRegExp(highlight)}\\b`, 'gi');

          return children.match(regex);
        });
      }

      return updatedHighlights;
    }, [highlights, matchWholePhrase, matchWholeWords, children]);

    if (typeof children !== 'string') {
      return <Element {...rest}>{children}</Element>;
    }

    return (
      <Element {...rest}>
        <Highlight span highlight={finalHighlights}>
          {children as string}
        </Highlight>
      </Element>
    );
  };

  return memo(MarkdownElementRendererBase) as typeof MarkdownElementRendererBase;
};

export const MarkdownSpanRenderer = createMarkdownElementRenderer('span');
export const MarkdownLiRenderer = createMarkdownElementRenderer('li');
export const MarkdownParagraphRenderer = createMarkdownElementRenderer('p');
export const MarkdownStrongRenderer = createMarkdownElementRenderer('strong');
export const MarkdownEmRenderer = createMarkdownElementRenderer('em');
