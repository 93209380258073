import { memo } from 'react';
import { ActionIcon, Button, Flex, Modal, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';

import { DeleteCommentButtonProps } from './DeleteCommentButton.types';
import { useDeleteComment } from '@/pageAI/hooks/caseTimeline/useDeleteComment';
import { posthog } from '@/shared/plugins/posthog';

const DeleteCommentButtonBase = ({ client, event, commentId }: DeleteCommentButtonProps) => {
  const [opened, { open, close }] = useDisclosure();

  const { deleteComment } = useDeleteComment();

  const handleDeleteComment = () => {
    posthog.capture('[Timeline] Delete comment', {
      commentId,
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      eventId: event.id,
      eventReference: event.reference,
    });

    deleteComment(client, event, { id: commentId });
  };

  return (
    <>
      <Tooltip label="Delete" withArrow withinPortal>
        <ActionIcon size="xs" className="unprintable" onClick={open} data-testid="delete-comment">
          <IconTrash size={12} />
        </ActionIcon>
      </Tooltip>

      <Modal title="Delete comment" opened={opened} onClose={close} centered>
        <Flex direction="column" gap="md">
          <Text fz="0.875rem" color="dark">
            Are you sure you want to delete this comment? You cannot undo this action.
          </Text>

          <Flex align="center" justify="end" gap="xs">
            <Button variant="subtle" color="gray.8" onClick={close}>
              Cancel
            </Button>

            <Button color="red" onClick={handleDeleteComment}>
              Delete
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const DeleteCommentButton = memo(DeleteCommentButtonBase);
