import { create } from 'zustand';

import { FileAsset } from '@/shared/@types';
import { FilePreviewState, FilePreviewTab } from './filePreview.states.types';
import { useLayoutStore } from '../layout';

export const useFilePreviewStore = create<FilePreviewState>(() => ({
  textContent: undefined,
  asset: undefined,
  isLoadingTextContent: false,
  defaultTab: FilePreviewTab.PDF,
  highlightedTexts: [],
  pagesToHighlight: undefined,
  displayName: undefined,
  initialPageIndex: undefined,
}));

export const setFilePreviewTextContent = (textContent?: string) => useFilePreviewStore.setState({ textContent });

export const setFilePreviewAsset = (asset?: FileAsset, displayName?: string) => {
  useFilePreviewStore.setState({
    asset,
    textContent: undefined,
    isLoadingTextContent: false,
    defaultTab: FilePreviewTab.PDF,
    displayName,
  });
};

export const setIsLoadingTextContent = (isLoadingTextContent: boolean) =>
  useFilePreviewStore.setState({ isLoadingTextContent });

export const setPdfPreviewInitalPageIndex = (initialPageIndex?: number) =>
  useFilePreviewStore.setState({ initialPageIndex });

export const setDefaultPreviewTab = (defaultTab: FilePreviewTab) => useFilePreviewStore.setState({ defaultTab });

export const setHighlightedTexts = (highlightedTexts: string[]) => useFilePreviewStore.setState({ highlightedTexts });

export const setPagesToHighlight = (pagesToHighlight?: number[]) => useFilePreviewStore.setState({ pagesToHighlight });

export const setAdditionalMenuItems = (additionalMenuItems?: React.ReactNode) =>
  useFilePreviewStore.setState({ additionalMenuItems });

useLayoutStore.subscribe((state) => {
  if (!state.isRightSidebarOpened) {
    setFilePreviewTextContent(undefined);
    setFilePreviewAsset(undefined);
    setPdfPreviewInitalPageIndex(undefined);
    setDefaultPreviewTab(FilePreviewTab.PDF);
    setHighlightedTexts([]);
    setPagesToHighlight(undefined);
  }
});
