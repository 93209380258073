import { memo, useCallback, useMemo, useRef, useState, useTransition } from 'react';
import {
  Checkbox,
  Flex,
  Highlight,
  Popover,
  Text,
  ActionIcon,
  Button,
  Loader,
  TextInput,
  ScrollArea,
} from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';

import { TimelineSearchInputProps } from './TimelineSearchInput.types';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { pushSearchKeyword, useSearchKeywordHistory } from '@/pageAI/hooks/search/useSearchKeywordHistory';
import { posthog } from '@/shared/plugins/posthog';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';

const TimelineSearchInputBase = ({
  placeholder = 'Search for keywords...',
  initialValue,
  onChange,
  matchWholeWords,
  onMatchWholeWordsChange,
  matchWholePhrase,
  onMatchWholePhraseChange,
}: TimelineSearchInputProps) => {
  const searchKeywordHistory = useSearchKeywordHistory();
  const client = useSelectedClient(true);

  const [isPending, startTransition] = useTransition();
  const forceRefocusRef = useRef(false);
  const debounceTimeoutRef = useRef<number | null>(null);
  const [value, setValue] = useState(initialValue);
  const [showInput, setShowInput] = useState(!!value);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number | null>(null);

  const filteredSearchKeywordHistory = useMemo(
    () =>
      value
        ? searchKeywordHistory.filter((keyword) => {
            const loweredCaseKeyword = keyword.toLowerCase();
            const loweredCaseValue = value.toLowerCase();
            return loweredCaseKeyword.includes(loweredCaseValue) && loweredCaseKeyword !== loweredCaseValue;
          })
        : searchKeywordHistory,
    [searchKeywordHistory, value],
  );

  const suggestion = useMemo(
    () =>
      value
        ? filteredSearchKeywordHistory.find((keyword) => {
            const loweredCaseKeyword = keyword.toLowerCase();
            const loweredCaseValue = value.toLowerCase();
            return loweredCaseKeyword.startsWith(loweredCaseValue) && loweredCaseKeyword !== loweredCaseValue;
          })
        : undefined,
    [value, filteredSearchKeywordHistory],
  );

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      setSelectedSuggestionIndex(null);

      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = window.setTimeout(() => {
        posthog.capture('[Timeline] Search timeline by keywords', {
          keywords: newValue,
          clientId: client.id,
          clientFirstName: client.firstName,
          clientLastName: client.lastName,
        });

        startTransition(() => {
          if (newValue) pushSearchKeyword(newValue);

          onChange?.(newValue);
        });
      }, 500);
    },
    [onChange, client],
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value) return;

    const eventTarget = event.target;

    setTimeout(() => {
      if (forceRefocusRef?.current) {
        forceRefocusRef.current = false;
        eventTarget.focus();

        return;
      }

      setShowInput(false);
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setValue('');
      setShowInput(false);

      return;
    }

    if (event.key === 'Enter') {
      if (selectedSuggestionIndex !== null && filteredSearchKeywordHistory[selectedSuggestionIndex]) {
        handleChange(filteredSearchKeywordHistory[selectedSuggestionIndex]);
      } else {
        onChange?.(value || '');
      }

      return;
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();

      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex === null || prevIndex === filteredSearchKeywordHistory.length - 1 ? 0 : prevIndex + 1,
      );

      return;
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();

      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex === null || prevIndex === 0 ? filteredSearchKeywordHistory.length - 1 : prevIndex - 1,
      );

      return;
    }

    if (event.key === 'Tab') {
      if (!value) return;

      event.preventDefault();

      if (suggestion) {
        handleChange(suggestion);

        posthog.capture('[Timeline] Use search keyword suggestion', {
          suggestion,
          clientId: client.id,
          clientFirstName: client.firstName,
          clientLastName: client.lastName,
        });
      }
    }
  };

  const renderCheckbox = (label: string, checked: boolean | undefined, onCheckedChange: (checked: boolean) => void) => {
    return (
      <Checkbox
        size="xs"
        label={label}
        checked={checked}
        onChange={(event) => onCheckedChange(event.currentTarget.checked)}
        onChangeCapture={() => {
          forceRefocusRef.current = true;
        }}
        sx={{
          '.ghost-Checkbox-body': {
            alignItems: 'center',
          },
          '.ghost-Checkbox-inner': {
            width: 14,
            height: 14,
            '.ghost-Checkbox-input': {
              width: 14,
              height: 14,
            },
          },
          'label.ghost-Checkbox-label': {
            paddingLeft: 10,
          },
        }}
      />
    );
  };

  const renderInputWrapper = (inputNode: React.ReactNode) => {
    return (
      <Popover width="target" shadow="sm" defaultOpened withinPortal position="bottom-end">
        <Popover.Target>{inputNode}</Popover.Target>

        <Popover.Dropdown p={0} onMouseDownCapture={() => (forceRefocusRef.current = true)}>
          <ScrollArea.Autosize mah={496}>
            <Flex direction="column" gap={4} p={4}>
              {value && filteredSearchKeywordHistory.length ? (
                <Flex direction="column">
                  {filteredSearchKeywordHistory.slice(0, 5).map((keyword, index) => (
                    <MenuItem
                      key={keyword}
                      title={
                        <Text fz="0.75rem">
                          <Highlight
                            highlight={[value]}
                            highlightStyles={{
                              backgroundColor: 'transparent !important',
                              fontWeight: 600,
                            }}
                          >
                            {keyword}
                          </Highlight>
                        </Text>
                      }
                      sx={{
                        backgroundColor: selectedSuggestionIndex === index ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                      }}
                    />
                  ))}
                </Flex>
              ) : null}

              {(onMatchWholePhraseChange || onMatchWholeWordsChange) && (
                <Flex pl="sm" py={6} direction="column" gap={6}>
                  {onMatchWholeWordsChange
                    ? renderCheckbox('Match whole words', matchWholeWords, onMatchWholeWordsChange)
                    : null}

                  {onMatchWholePhraseChange
                    ? renderCheckbox('Match whole phrase', matchWholePhrase, onMatchWholePhraseChange)
                    : null}
                </Flex>
              )}
            </Flex>
          </ScrollArea.Autosize>
        </Popover.Dropdown>
      </Popover>
    );
  };

  if (showInput) {
    const inputNode = (
      <Flex pos="relative">
        <TextInput
          pos="relative"
          className="search-input"
          size="xs"
          placeholder={placeholder}
          icon={<IconSearch size={16} />}
          rightSection={
            <>
              {isPending ? (
                <Loader size={12} />
              ) : (
                <ActionIcon size="xs" onClick={() => handleChange('')}>
                  <IconX size={14} />
                </ActionIcon>
              )}
            </>
          }
          value={value || ''}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
          autoFocus
          onKeyDown={handleKeyDown}
        />

        {suggestion && (
          <Text pos="absolute" left={31} top={6} fz="0.75rem" sx={{ zIndex: 0, opacity: 0.5 }}>
            {suggestion}
          </Text>
        )}
      </Flex>
    );

    return renderInputWrapper(inputNode);
  }

  return (
    <Button
      leftIcon={<IconSearch size={14} />}
      onClick={() => setShowInput(true)}
      variant="subtle"
      color="gray.7"
      size="xs"
    >
      Search
    </Button>
  );
};

export const TimelineSearchInput = memo(TimelineSearchInputBase);
