import { memo } from 'react';

import { ClientFileSearchLayoutProps } from './ClientFileSearchLayout.types';
import { ClientFileSearchPanel } from '../ClientFileSearchPanel';
import { FileSearchContextProvider } from '@/pageAI/contexts/fileSearchContext';
import { useFileSearchFilters } from '@/pageAI/hooks/files/useFileFilterSearchParams';

const ClientFileSearchLayoutBase = ({ client }: ClientFileSearchLayoutProps) => {
  const { fileGroups, setFileGroups, fileTypes, setFileTypes } = useFileSearchFilters();

  return (
    <FileSearchContextProvider
      fileGroups={fileGroups}
      setFileGroups={setFileGroups}
      fileTypes={fileTypes}
      setFileTypes={setFileTypes}
    >
      <ClientFileSearchPanel client={client} />
    </FileSearchContextProvider>
  );
};

export const ClientFileSearchLayout = memo(ClientFileSearchLayoutBase);
