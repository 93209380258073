import { memo, useCallback, useMemo, useRef } from 'react';
import { Flex, Text } from '@mantine/core';

import {
  highlightEventReference,
  useTimelineReferenceHighlightStore,
} from '@/pageAI/states/timelineReferenceHighlights';

import { addFileIndexToEventReference } from '@/pageAI/services/summaries';
import { getClientFiles } from '@/pageAI/services/clients';
import { TimelineEventSummary } from '../TimelineEventSummary';
import { ReadonlyTimelineEventProps } from './ReadonlyTimelineEvent.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { SummaryFileReferenceBadge } from '../../summaries/SummaryFileReferenceBadge';

const ReadonlyTimelineEventBase = ({ event: rawEvent, keywords }: ReadonlyTimelineEventProps) => {
  const client = useSelectedClient(true);
  const isHighlighted = useTimelineReferenceHighlightStore((state) =>
    Boolean(state.highlightedEventMapping[rawEvent.id]),
  );

  const referenceBadgeRef = useRef<HTMLSpanElement>(null);
  const eventContainerRef = useRef<HTMLDivElement>(null);

  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);

  const event = useMemo(
    () => ({ ...rawEvent, reference: addFileIndexToEventReference(rawEvent.reference, fileAssets) }),
    [rawEvent, fileAssets],
  );

  const handleHighlight = useCallback(() => {
    highlightEventReference(client.id, event.id);
  }, [client.id, event]);

  const handleClickEventSummary = useCallback(() => {
    referenceBadgeRef.current?.querySelector('a')?.click();
  }, []);

  return (
    <Flex
      ref={eventContainerRef}
      direction="column"
      sx={(theme) => ({
        '&:hover': {
          '.extra-action': {
            opacity: 1,
            pointerEvents: 'auto',
          },
        },
      })}
    >
      <Text color="dark.4" fz="sm">
        <TimelineEventSummary event={event} onClick={handleClickEventSummary} keywords={keywords} />

        <Flex align="center" sx={{ display: 'inline-flex' }} gap={6} ml={4}>
          <SummaryFileReferenceBadge
            ref={referenceBadgeRef}
            client={client}
            reference={event.reference}
            referencedTexts={event.referencedText}
            isHighlighted={isHighlighted}
            onClick={handleHighlight}
            keywords={keywords}
          />
        </Flex>
      </Text>
    </Flex>
  );
};

export const ReadonlyTimelineEvent = memo(ReadonlyTimelineEventBase);
