import { useCallback, useState } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { useSearchParams } from 'react-router-dom';

import { getItemFromStorage } from '@/shared/lib/storage';
import { updateSearchParams } from '@/shared/lib/browser';

const DISPLAY_CONDITION_RELATED_EVENTS_STORAGE_KEY = 'displayConditionRelatedEvents';
const DISPLAY_CONDITION_RELATED_EVENTS_FILTER_HINT_STORAGE_KEY = 'displayConditionRelatedEventsFilterHintDialog';

export const useDisplayConditionRelatedEvents = (forceDisplay = false) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultValue] = useState(() => {
    const storageValue = getItemFromStorage(DISPLAY_CONDITION_RELATED_EVENTS_STORAGE_KEY);

    if (!storageValue) return true;

    return storageValue === 'true';
  });
  const [displayConditionRelatedEvents, setDisplayConditionRelatedEventsBase] = useLocalStorage<boolean>({
    key: DISPLAY_CONDITION_RELATED_EVENTS_STORAGE_KEY,
    defaultValue,
  });

  const setDisplayConditionRelatedEvents = useCallback(
    (value: boolean) => {
      setDisplayConditionRelatedEventsBase(value);

      const newSearchParams = updateSearchParams(searchParams)('conditionEvents', value);
      setSearchParams(newSearchParams);
    },
    [setDisplayConditionRelatedEventsBase, searchParams, setSearchParams],
  );

  // TODO: Fix this after the feature is fully implemented
  return [false, setDisplayConditionRelatedEvents] as const;
};

export const useDisplayConditionRelatedEventsHint = () => {
  const [defaultFilterHintValue] = useState(() => {
    const storageValue = getItemFromStorage(DISPLAY_CONDITION_RELATED_EVENTS_FILTER_HINT_STORAGE_KEY);

    if (!storageValue) return true;

    return storageValue === 'true';
  });
  const [displayFilterHint, setDisplayFilterHint] = useLocalStorage<boolean>({
    key: DISPLAY_CONDITION_RELATED_EVENTS_FILTER_HINT_STORAGE_KEY,
    defaultValue: defaultFilterHintValue,
  });

  return [displayFilterHint, setDisplayFilterHint] as const;
};
