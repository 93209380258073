import { memo } from 'react';
import { Flex } from '@mantine/core';

import { FileIndexRightHeaderProps } from './FileIndexRightHeader.types';
import { FileFilters } from '../../files/FileFilters';
import { FileIndexSearchInput } from '../../files/FileIndexSearchInput';

const FileIndexRightHeaderBase = ({ client, keywords, onKeywordsChange }: FileIndexRightHeaderProps) => {
  return (
    <Flex align="center" gap={4}>
      <FileFilters client={client} />

      <FileIndexSearchInput initialValue={keywords} onChange={onKeywordsChange} />
    </Flex>
  );
};

export const FileIndexRightHeader = memo(FileIndexRightHeaderBase);
