import { useCallback } from 'react';

import { useSelectedClient } from '../../clients/useSelectedClient';
import { useMergedFile } from '../useMergedFile';
import { setPrintPageNumberMapping } from '@/pageAI/components/summaries/SummaryFileReferenceBadge';
import { getClientDisplayName, getClientFiles } from '@/pageAI/services/clients';
import { downloadFromUrl } from '@/shared/lib/browser';
import { getFilePageNumberMappingInMergedFile } from '@/pageAI/services/files';

export const useExportWithMergedFile = () => {
  const client = useSelectedClient(true);
  const { getClientMergedFile, isLoading, isError } = useMergedFile();

  const exportWithMergedFile = useCallback(async () => {
    const mergedFile = await getClientMergedFile(client.id);

    if (!mergedFile) return;

    downloadFromUrl(mergedFile.downloadUrl, `${getClientDisplayName(client)}_FullCFile.pdf`);

    const { fileAssets } = getClientFiles(client);

    const printPageNumberMapping = getFilePageNumberMappingInMergedFile(fileAssets, mergedFile.fileIds);

    setPrintPageNumberMapping(printPageNumberMapping);
  }, [client, getClientMergedFile]);

  return { exportWithMergedFile, isLoading, isError };
};
