import { safelyParseJSON } from '@/shared/utils/object';
import { Box } from '@mantine/core';
import { memo, useMemo } from 'react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

interface JSONPreviewerProps {
  src: string;
}

const JSONPreviewerBase = ({ src }: JSONPreviewerProps) => {
  const objectSrc = useMemo(() => safelyParseJSON(src), [src]);

  return (
    <Box
      sx={{
        '.json-view': {
          '.jv-size': {
            display: 'none',
          },
          '.jv-size-chevron': {
            display: 'none',
          },
        },
      }}
    >
      <JsonView
        src={objectSrc}
        style={{
          fontSize: '0.75rem',
        }}
        displaySize="expanded"
        enableClipboard
        collapsed={false}
        collapseStringsAfterLength={Infinity}
      />
    </Box>
  );
};

export const JSONPreviewer = memo(JSONPreviewerBase);
