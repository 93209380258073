import { useCallback, useEffect } from 'react';
import { useLocalStorage } from '@mantine/hooks';

import { TimelineGenerationByClient, TriggerSummariesGenerationOptions } from './useEnsureTimelineGeneration.types';
import { Client, ClientItem } from '@/pageAI/@types';
import { useEnsureClientFilesUploaded } from '@/pageAI/hooks/files/useEnsureClientFilesUploaded';
import { uploadBatchCompletePubsub } from '@/pageAI/hooks/files/useUploadMultipleFiles';
import { getUploadedFiles, isFileProcessing } from '@/shared/services/files';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useGenerateSummaries } from '../../summaries/useGenerateSummaries';
import { useUpdateClient } from '../../clients/useUpdateClient';
import { CaseTimelineStatus, SummaryByConditionStatus } from '@/pageAI/gql/graphql';
import { getClientDisplayName, getClientFiles } from '@/pageAI/services/clients';
import { useGenerateSummaryByConditions } from '../../summaries/useGenerateSummaryByConditions';

const ENSURE_TIMELINE_GENERATION_STORAGE_KEY = 'timeline_gen_by_client';

export const useSummariesGenerationSchedule = (client: ClientItem) => {
  const [value, setValue] = useLocalStorage<TimelineGenerationByClient>({
    key: ENSURE_TIMELINE_GENERATION_STORAGE_KEY,
    defaultValue: {},
  });

  const hasScheduled = !!value[client.id]?.scheduled;

  const setScheduled = useCallback(
    (scheduled: boolean) => {
      if (!scheduled) {
        return setValue((prevValue) => {
          const newState = { ...prevValue };

          delete newState[client.id];

          return newState;
        });
      }

      setValue((prevValue) => ({
        ...prevValue,
        [client.id]: {
          scheduled,
        },
      }));
    },
    [setValue, client.id],
  );

  const schedule = useCallback(() => {
    setScheduled(true);
  }, [setScheduled]);

  const unschedule = useCallback(() => {
    setScheduled(false);
  }, [setScheduled]);

  return { schedule, unschedule, hasScheduled };
};

export const useTriggerSummariesGeneration = (client: ClientItem, options?: TriggerSummariesGenerationOptions) => {
  const { notify } = useNotifications();
  const { generateSummaries } = useGenerateSummaries();
  const { generateSummaryByConditions } = useGenerateSummaryByConditions();
  const { updateClientLocally } = useUpdateClient(true);

  const { schedule, unschedule, hasScheduled } = useSummariesGenerationSchedule(client);
  const { saveNumberOfFiles, numberOfFiles: expectedNumberOfFiles } = useEnsureClientFilesUploaded(client.id);

  const handleGenerateSummaries = useCallback(
    async (isOcrComplete = true) => {
      const shouldGenerateSummaryByCondition = !options || options.generateMedicalConditionSummary;
      const shouldGenerateTimeine = !options || options.generateTimeline;

      try {
        if (shouldGenerateTimeine) {
          await generateSummaries({
            id: client.id,
            doGenerateSummaryByCondition: shouldGenerateSummaryByCondition,
            useLatestModel: true,
          });
        } else {
          await generateSummaryByConditions({ id: client.id, useLatestModel: true });
        }

        updateClientLocally({
          id: client.id,
          ...(shouldGenerateTimeine && { caseTimelineStatus: CaseTimelineStatus.Pending }),
          ...(shouldGenerateSummaryByCondition && { summaryByConditionStatus: SummaryByConditionStatus.Pending }),
        });

        unschedule();

        notify(
          `Generating summaries for ${getClientDisplayName(client)}...`,
          isOcrComplete
            ? 'The summary generation process has started.'
            : 'The summary generation process will start once the OCR process is complete.',
          'brand',
          10000,
        );
      } catch (error) {
        notify(
          `Summary generation failed for ${getClientDisplayName(client)}`,
          'Something went wrong while trying to generate summaries!',
          'red',
          10000,
        );
      }
    },
    [updateClientLocally, notify, generateSummaries, client, unschedule, options, generateSummaryByConditions],
  );

  const triggerSummariesGeneration = useCallback(
    (fullClient: Client, force = false) => {
      if (force) return handleGenerateSummaries();

      const { fileAssets } = getClientFiles(fullClient);

      if (fileAssets && fileAssets.length >= expectedNumberOfFiles) {
        const isOcrComplete = !fileAssets.some((fileAsset) => isFileProcessing(fileAsset));

        return handleGenerateSummaries(isOcrComplete);
      }

      schedule();
    },
    [schedule, handleGenerateSummaries, expectedNumberOfFiles],
  );

  return {
    triggerSummariesGeneration,
    handleGenerateSummaries,
    schedule,
    unschedule,
    hasScheduled,
    saveNumberOfFiles,
    expectedNumberOfFiles,
  };
};

const subscribedClientMap: Record<string, (() => void) | undefined> = {};

export const useEnsureTimelineGeneration = (client: ClientItem) => {
  const { hasScheduled, unschedule, expectedNumberOfFiles, handleGenerateSummaries } =
    useTriggerSummariesGeneration(client);

  useEffect(() => {
    if (!hasScheduled) return;

    if (typeof subscribedClientMap[client.id] === 'function') subscribedClientMap[client.id]?.();

    const unsubscribe = uploadBatchCompletePubsub.subscribe(({ uploadBatch }) => {
      if (uploadBatch.id !== client.id) return;

      const uploadedFiles = getUploadedFiles(uploadBatch.fileAssets);

      if (uploadedFiles.length < expectedNumberOfFiles) return;

      handleGenerateSummaries();
    });

    subscribedClientMap[client.id] = unsubscribe;

    return;
  }, [hasScheduled, handleGenerateSummaries, client.id, expectedNumberOfFiles]);

  return { hasScheduled, unschedule, expectedNumberOfFiles };
};
