import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Flex, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconArrowRight, IconClock, IconRotateClockwise, IconSparkles } from '@tabler/icons-react';

import { SummaryCardProps } from './SummaryCard.types';
import { SUMMARY_STATUS_COLOR_MAPPING, SUMMARY_STATUS_TRANSLATION_MAPPING } from '@/pageAI/services/summaries';
import { formatDate, formatDateTime } from '@/shared/utils/date';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { GenerateSummariesModal } from '../GenerateSummariesModal';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';

const SummaryCardBase = ({ id, name, description, updatedAt, status }: SummaryCardProps) => {
  const navigate = useNavigate();

  const client = useSelectedClient(true);
  const statusText = SUMMARY_STATUS_TRANSLATION_MAPPING[status as keyof typeof SUMMARY_STATUS_TRANSLATION_MAPPING];

  const [opened, { open, close }] = useDisclosure();

  const isError = [SUMMARY_STATUS_TRANSLATION_MAPPING.ERROR, SUMMARY_STATUS_TRANSLATION_MAPPING.CANCELED].includes(
    statusText,
  );
  const isNotGenerated = statusText === SUMMARY_STATUS_TRANSLATION_MAPPING.NOT_GENERATED;
  const canView = statusText === SUMMARY_STATUS_TRANSLATION_MAPPING.GENERATED;

  const renderActionButton = () => {
    if (client.viewerCanGenerateTimeline) {
      if (isNotGenerated) {
        return (
          <Button size="xs" leftIcon={<IconSparkles size={14} />} onClick={open}>
            Generate
          </Button>
        );
      }

      if (isError) {
        return (
          <Button size="xs" variant="outline" leftIcon={<IconRotateClockwise size={14} />} onClick={open}>
            Retry
          </Button>
        );
      }
    }

    const actionButton = (
      <Flex
        align="center"
        justify="center"
        sx={(theme) => ({
          width: 30,
          height: 30,
          borderRadius: 4,
          background: canView ? 'transparent' : theme.colors.gray[2],
          color: canView ? theme.colors.dark[5] : theme.colors.gray[5],
        })}
      >
        <IconArrowRight size={20} />
      </Flex>
    );

    if (canView) return actionButton;

    return (
      <Tooltip
        label="Your summaries are being generated and will be available to view when complete."
        withArrow
        withinPortal
      >
        {actionButton}
      </Tooltip>
    );
  };

  const handleClick = () => {
    if (!canView) return;

    navigate(`/clients/${client.id}/summaries?tab=${id}`);
  };

  return (
    <Flex
      className="summary-card"
      data-testid={id}
      direction="column"
      py="lg"
      px="xl"
      onClick={handleClick}
      sx={(theme) => ({
        width: '100%',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 4,
        backgroundColor: theme.white,
        transition: 'all 0.1s',
        ...(canView && {
          cursor: 'pointer',
          '&:hover': { backgroundColor: theme.colors.gray[0] },
        }),
      })}
    >
      <Flex align="center" justify="space-between" mb={4}>
        <Flex align="center" gap={4}>
          <Text fz="1rem" fw={600} color="dark.5">
            {name}
          </Text>
        </Flex>

        <Badge
          data-testid="status-badge"
          variant="outline"
          color={SUMMARY_STATUS_COLOR_MAPPING[status as keyof typeof SUMMARY_STATUS_COLOR_MAPPING]}
        >
          {statusText}
        </Badge>
      </Flex>

      <Text fz="0.875rem" color="dark.2" sx={{ maxWidth: 'calc(100% - 120px)' }} mb="xs">
        {description}
      </Text>

      <Flex sx={{ flexGrow: 1 }} />

      <Flex justify="space-between" align="end">
        {!isNotGenerated && (
          <Tooltip label={`Last updated at ${formatDateTime(updatedAt)}`} withArrow withinPortal>
            <Flex
              align="center"
              gap={4}
              sx={(theme) => ({
                color: theme.colors.gray[5],
              })}
            >
              <IconClock size={14} />

              <Text fz="0.75rem" color="gray.5" fw={600} sx={{ alignSelf: 'flex-end' }}>
                {formatDate(updatedAt)}
              </Text>
            </Flex>
          </Tooltip>
        )}

        <Flex gap="xs">{renderActionButton()}</Flex>
      </Flex>

      <GenerateSummariesModal
        client={client}
        opened={opened}
        onClose={close}
        defaultIsCaseTimelineSelected={id !== UnifiedTab.CONDITION_SUMMARY}
        defaultIsConditionSummarySelected={id !== UnifiedTab.TIMELINE}
      />
    </Flex>
  );
};

export const SummaryCard = memo(SummaryCardBase);
