import { memo, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { ActionIcon, Box, Flex, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDownload } from '@tabler/icons-react';

import { ExportConditionsModal } from '../ExportConditionsModal';
import {
  conditionsSelector,
  isPrintingConditionSummarySelector,
  setSummaryIsPrinting,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { PrintableConditionSummary } from '../PrintableConditionSummary';
import { setPrintPageNumberMapping } from '@/pageAI/components/summaries/SummaryFileReferenceBadge';
import { waitUntilTruthy } from '@/shared/utils/boolean';
import { wait } from '@/shared/utils/time';

interface ExportConditionsButtonProps {}

const ExportConditionsButtonBase = (props: ExportConditionsButtonProps) => {
  const [opened, { open, close }] = useDisclosure();
  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);
  const conditions = useUnifiedStore(conditionsSelector);
  const [selectedConditionIds, setSelectedConditionIds] = useState<string[]>(() =>
    conditions.map((condition) => condition.id),
  );

  const handleSelectConditions = useCallback((conditionIds: string[]) => {
    setSelectedConditionIds([...new Set(conditionIds)]);
  }, []);

  const handleConfirmExport = useCallback(() => {
    close();

    setSummaryIsPrinting(true);

    document.body.classList.add('printing');

    setTimeout(async () => {
      await waitUntilTruthy(() => !!document.getElementById('printable-condition-summary-container'), 20);

      await wait(1000);

      requestIdleCallback(() => {
        window.print();
        setSummaryIsPrinting(false);
        setPrintPageNumberMapping(null);
        document.body.classList.remove('printing');
      });
    });
  }, [close]);

  return (
    <>
      {createPortal(
        <Tooltip label="Export condition summary" withinPortal withArrow>
          <ActionIcon onClick={open} color="gray.6" sx={{ order: 2 }}>
            <IconDownload size={16} />
          </ActionIcon>
        </Tooltip>,
        document.querySelector('#page-ai-header-suffix-section') || document.body,
      )}

      <ExportConditionsModal
        opened={opened}
        onClose={close}
        selectedConditionIds={selectedConditionIds}
        onSelect={handleSelectConditions}
        onExport={handleConfirmExport}
      />

      {isPrinting
        ? createPortal(
            <Box
              className="printable"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 999999,
                background: 'white',
                overflow: 'visible',
                height: 'fit-content',
                ul: {
                  paddingLeft: 24,
                },
              }}
            >
              <PrintableConditionSummary selectedConditionIds={selectedConditionIds} />

              {false && (
                <Flex
                  align="center"
                  justify="center"
                  sx={(theme) => ({ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, background: theme.white })}
                >
                  <LoadingPlaceholder
                    title="Exporting timeline..."
                    description={`Please wait while your timeline is being exported (${Math.floor(0 * 100)}%).`}
                  />
                </Flex>
              )}
            </Box>,
            document.body,
          )
        : null}
    </>
  );
};

export const ExportConditionsButton = memo(ExportConditionsButtonBase);
