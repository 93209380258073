export type ResponseError = {
  response?: {
    errors: {
      message: string;
    }[];
  };
};

export enum FetchStatus {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  STREAMING = 'STREAMING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
