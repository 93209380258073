import { createContext, memo, useContext, useEffect, useRef } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

const MarkdownRendererContext = createContext<StoreApi<MarkdownRenderedContextState> | null>(null);

interface MarkdownRenderedContextState {
  highlights: string[];
  matchWholeWords: boolean;
  matchWholePhrase: boolean;
}

interface MarkdownRendererContextProviderProps {
  highlights?: string[];
  matchWholeWords?: boolean;
  matchWholePhrase?: boolean;
  children: React.ReactNode;
}

const MarkdownRendererContextProviderBase = ({
  highlights = [],
  matchWholePhrase = false,
  matchWholeWords = false,
  children,
}: MarkdownRendererContextProviderProps) => {
  const storeRef = useRef<StoreApi<MarkdownRenderedContextState> | null>(null);

  if (!storeRef.current) {
    storeRef.current = createStore<MarkdownRenderedContextState>((set) => ({
      matchWholeWords,
      matchWholePhrase,
      highlights,
    }));
  }

  useEffect(() => {
    if (!storeRef.current) return;

    const currentState = storeRef.current.getState();

    if (
      currentState.matchWholeWords !== matchWholeWords ||
      currentState.matchWholePhrase !== matchWholePhrase ||
      currentState.highlights !== highlights
    ) {
      storeRef.current.setState({ highlights, matchWholeWords, matchWholePhrase });
    }
  }, [highlights, matchWholePhrase, matchWholeWords]);

  return <MarkdownRendererContext.Provider value={storeRef.current}>{children}</MarkdownRendererContext.Provider>;
};

export const MarkdownRendererContextProvider = memo(MarkdownRendererContextProviderBase);

export const useMarkdownRendererContext = <T,>(selector: (state: MarkdownRenderedContextState) => T) => {
  const store = useContext(MarkdownRendererContext);

  if (!store) {
    throw new Error('Missing MarkdownRendererContextProvider');
  }

  return useStore(store, selector);
};
