import { memo, useCallback, useMemo } from 'react';

import { TimelineQuickNavigationProps } from './TimelineQuickNavigation.types';
import { getTimelineTableOfContents, highlightTimelineEventPubsub } from '@/pageAI/services/caseTimeline';
import { QuickNavigation } from '../../unified/QuickNavigation';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { posthog } from '@/shared/plugins/posthog';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';

const TimelineQuickNavigationBase = ({ timelineByDate, sortOrder = 'desc' }: TimelineQuickNavigationProps) => {
  const client = useSelectedClient(true);
  const tableOfContents = useMemo(() => getTimelineTableOfContents(timelineByDate), [timelineByDate]);

  const handleSelect = useCallback(
    (event: CaseTimelineEvent) => {
      posthog.capture('[Timeline] Navigate to event', {
        clientId: client.id,
        clientFirstName: client.firstName,
        clientLastName: client.lastName,
        eventId: event.id,
        eventReference: event.reference,
      });

      highlightTimelineEventPubsub.publish({ event, shouldOpenReference: false });
    },
    [client],
  );

  return <QuickNavigation mapping={tableOfContents} sortOrder={sortOrder} onSelect={handleSelect} />;
};

export const TimelineQuickNavigation = memo(TimelineQuickNavigationBase);
