import { Link } from 'react-router-dom';
import { UnstyledButton, Flex, Tooltip } from '@mantine/core';
import { IconBuilding, IconUsers, IconZoomQuestion } from '@tabler/icons-react';

import { MainLinkProps } from './MainLinks.types';
import { LeftSidebarType, toggleLeftSidebar } from '@/shared/states/layout';
import { usePageMatch } from '@/shared/hooks/routes/usePageMatch';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';

export const MainLink = ({ icon, label, path, pathname, tooltip, onClick }: MainLinkProps) => {
  const { isInPage } = usePageMatch();
  const active = path && isInPage(path);

  const button = (
    <UnstyledButton
      onClick={onClick}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.fn.lighten(theme.black, 0.9),

        '&:hover': {
          backgroundColor: theme.fn.lighten(theme.colors.dark[5], 0.1),
        },

        ...(active
          ? {
              backgroundColor: theme.white,
              color: theme.colors.dark[5],
              fontWeight: 500,

              '&:hover': {
                backgroundColor: theme.colors.white,
              },
            }
          : {}),
      })}
    >
      <Flex align="center" gap="sm">
        <Flex align="center" justify="center">
          {icon}
        </Flex>
      </Flex>
    </UnstyledButton>
  );

  const content = path ? (
    <Link to={path} style={{ textDecoration: 'none' }} data-testid={pathname || path}>
      {button}
    </Link>
  ) : (
    button
  );

  return (
    <Tooltip label={tooltip || label} position="right" withArrow withinPortal>
      {content}
    </Tooltip>
  );
};

export const MainLinks = () => {
  const { currentUser } = useCurrentUser();
  const organization = currentUser?.organizations?.nodes?.[0];
  const areThereJoinRequests = !!organization?.joinRequests?.nodes?.length;

  const data = [
    { icon: <IconUsers size={24} />, path: '/clients', label: 'Clients', pathname: '/clients' },
    {
      icon: areThereJoinRequests ? (
        <Flex align="center" justify="center" pos="relative">
          <IconBuilding size={24} />

          <Flex
            sx={(theme) => ({
              position: 'absolute',
              top: 1,
              right: 1,
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: theme.colors.red[6],
            })}
          />
        </Flex>
      ) : (
        <IconBuilding size={24} />
      ),
      tooltip: areThereJoinRequests ? 'Your organization has pending join requests.' : 'Organization',
      path: `/organizations/${organization?.id}`,
      pathname: '/organizations',
      label: 'Organization',
      onClick: (event: React.MouseEvent) => {
        if (!organization) event.preventDefault();
      },
    },
    {
      icon: <IconZoomQuestion size={24} />,
      path: '/research',
      pathname: '/research',
      label: 'Research',
      onClick: (event: React.MouseEvent) => {
        if (window.location.pathname.includes('/chat/')) event.preventDefault();

        toggleLeftSidebar(LeftSidebarType.CONVERSATIONS);
      },
    },
  ];

  const links = data.map((link) => <MainLink {...link} key={link.label} />);

  return (
    <Flex direction="column" gap={4}>
      {links}
    </Flex>
  );
};
