import { useCallback } from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import { ResponseError } from '@/shared/@types';
import {
  generateUploadCompletionMutation,
  generateUploadInitializationMutation,
  generateUploadPartMutation,
} from '@/pageAI/api';
import {
  File as GraphQLFile,
  GenerateUploadCompletionUrlInput,
  GenerateUploadCompletionUrlMutation,
  GenerateUploadInitializationUrlInput,
  GenerateUploadInitializationUrlMutation,
  GenerateUploadUrlInput,
  GenerateUploadUrlMutation,
} from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { extractFileUploadId } from '@/shared/services/files';
import { useFileUploadStatus } from '@/pageAI/hooks/files/useFileUploadStatus';
import { useSelectedClient } from '../../clients/useSelectedClient';

export const useUploadLargeFiles = () => {
  const { request } = useGraphQLRequest();
  const { startFileUploadStatusPolling } = useFileUploadStatus();
  const selectedClient = useSelectedClient(true);

  const { mutateAsync: generateUploadInitializationUrl } = useMutation<
    GenerateUploadInitializationUrlMutation,
    ResponseError,
    GenerateUploadInitializationUrlInput
  >({
    mutationFn: async (variables) => {
      return request(generateUploadInitializationMutation, { input: variables });
    },
  });

  const { mutateAsync: generateUploadUrl } = useMutation<
    GenerateUploadUrlMutation,
    ResponseError,
    GenerateUploadUrlInput
  >({
    mutationFn: async (variables) => {
      return request(generateUploadPartMutation, { input: variables });
    },
  });

  const { mutateAsync: generateUploadCompletionUrl } = useMutation<
    GenerateUploadCompletionUrlMutation,
    ResponseError,
    GenerateUploadCompletionUrlInput
  >({
    mutationFn: async (variables) => {
      return request(generateUploadCompletionMutation, { input: variables });
    },
  });

  const uploadLargeFile = useCallback(
    async (file: File, waitUntilUploaded = false, checkCombinedFile = false) => {
      const initResponse = await generateUploadInitializationUrl({
        name: file.name,
        size: file.size,
        mimeType: file.type,
        clientId: selectedClient.id,
        checkCombinedFile,
      });

      const fileId = initResponse.generateUploadInitializationUrl.fileId;

      if (!initResponse.generateUploadInitializationUrl.url) return;

      const resUploadId = await axios.post(initResponse.generateUploadInitializationUrl.url, null, {
        headers: { 'Content-Type': file.type },
      });

      const uploadId = extractFileUploadId(resUploadId.data);

      let bodyPart = '';

      const promises = [];

      if (uploadId) {
        const chunkSize = 100 * 1024 * 1024; // 100MB chunk size (adjust as needed)
        let currentChunk = 0;
        let partNumber = 1;

        while (currentChunk < file.size) {
          const start = currentChunk;
          const end = currentChunk + chunkSize;
          const fileChunk = file.slice(start, end);

          currentChunk = currentChunk + chunkSize;

          const generateUploadUrlResponse = await generateUploadUrl({
            fileId: initResponse.generateUploadInitializationUrl.fileId,
            uploadId,
            partNumber,
          });

          promises.push(axios.put(generateUploadUrlResponse.generateUploadUrl.url, fileChunk));
          partNumber++;
        }
      }

      const responses = await Promise.all([...promises]);

      responses.forEach((response, index) => {
        bodyPart += ` <Part>
              <PartNumber>${index + 1}</PartNumber>
              <ETag>${response.headers['etag']}</ETag>
            </Part>`;
      });

      const completionResponse = await generateUploadCompletionUrl({
        uploadId,
        fileId: initResponse.generateUploadInitializationUrl.fileId,
      });

      const xmlBody = `<CompleteMultipartUpload>${bodyPart}</CompleteMultipartUpload>`;

      await axios.post(`${completionResponse.generateUploadCompletionUrl.url}`, xmlBody, {
        headers: { 'Content-Type': 'text/xml' },
      });

      if (waitUntilUploaded) {
        return new Promise<Omit<GraphQLFile, 'permissions'>>((resolve) => {
          const stopPolling = startFileUploadStatusPolling({
            fileId,
            callback: (file) => {
              resolve(file);
              stopPolling();
            },
            seconds: 3,
          });
        });
      }
    },
    [
      generateUploadCompletionUrl,
      generateUploadInitializationUrl,
      generateUploadUrl,
      startFileUploadStatusPolling,
      selectedClient.id,
    ],
  );

  return { uploadLargeFile };
};
