import { memo, useMemo } from 'react';

import { FileSearchResult, SortOrder } from '@/shared/@types';
import { getFileSearchTableOfContents } from '@/pageAI/services/files';
import { QuickNavigation } from '@/pageAI/components/unified/QuickNavigation';

export interface FileSearchResultQuickNavigationProps {
  results: FileSearchResult[];
  sortOrder?: SortOrder;
}

const FileSearchResultQuickNavigationBase = ({ results, sortOrder = 'desc' }: FileSearchResultQuickNavigationProps) => {
  const tableOfContents = useMemo(() => getFileSearchTableOfContents(results), [results]);

  const navigateToResult = (result: FileSearchResult) => {
    const resultElement = document.querySelector(`[data-file-id="${result.file.id}"]`) as HTMLDivElement | null;

    if (!resultElement) return;

    resultElement.scrollIntoView();
  };

  const handleSelect = (result: FileSearchResult) => {
    navigateToResult(result);
  };

  return <QuickNavigation mapping={tableOfContents} sortOrder={sortOrder} onSelect={handleSelect} />;
};

export const FileSearchResultQuickNavigation = memo(FileSearchResultQuickNavigationBase);
