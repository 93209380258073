import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AcceptOrganizationInvitationInput, AcceptOrganizationInvitationMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { acceptOrganizationInvitationMutation } from '@/pageAI/api';

export const useAcceptOrganizationInvitation = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync: acceptInvitation, isLoading } = useMutation<
    AcceptOrganizationInvitationMutation,
    Error,
    AcceptOrganizationInvitationInput
  >({
    mutationFn: async (input) => {
      try {
        const responseBody = await request(acceptOrganizationInvitationMutation, { input });

        await queryClient.refetchQueries(['currentUser']);

        return responseBody;
      } catch (error) {
        if ((error as Error).message.includes('invitation is not pending')) return {};

        throw error;
      }
    },
    onError: () => {
      notify('Error', 'Failed to accept the invitation');
    },
  });

  return { acceptInvitation, isLoading };
};
