import { memo } from 'react';
import { Badge, Flex, Skeleton, Text, Tooltip } from '@mantine/core';

import { fileSummarySelector, useFileSummaryState } from '@/shared/states/fileSummary';
import { FetchStatus } from '@/shared/@types';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { IconSparkles } from '@tabler/icons-react';

interface FileSearchResultSummaryProps {
  fileId: string;
}

const FileSearchResultSummaryBase = ({ fileId }: FileSearchResultSummaryProps) => {
  const fileSummaryState = useFileSummaryState(fileSummarySelector(fileId));

  if (!fileSummaryState) return null;

  if (fileSummaryState.status === FetchStatus.LOADING) {
    return (
      <Flex align="center" px="md" mt={4}>
        <Skeleton height={24} />
      </Flex>
    );
  }

  if (!fileSummaryState.summary) return null;

  return (
    <Flex direction="column" pl="md">
      <Flex align="center" gap={6}>
        <Flex
          style={{
            display: 'inline-flex',
          }}
          align="flex-end"
          gap={4}
          sx={(theme) => ({ color: theme.colors.gray[6], width: 'fit-content' })}
        >
          <IconSparkles size={14} />

          <Text fz="0.625rem" color="gray.6" fw={600} span sx={{ lineHeight: '14px' }}>
            AI Generated
          </Text>
        </Flex>

        {fileSummaryState.relevant ? (
          <Tooltip label="This file contains information relevant to the current condition." withArrow withinPortal>
            <Badge color="teal.6" variant="light" size="xs" sx={{ textTransform: 'none' }}>
              Relevant
            </Badge>
          </Tooltip>
        ) : (
          <Tooltip label="This file contains information not relevant to the current condition." withArrow withinPortal>
            <Badge
              color="gray.7"
              variant="light"
              size="xs"
              sx={(theme) => ({ textTransform: 'none', background: theme.colors.gray[2] })}
            >
              Irrelevant
            </Badge>
          </Tooltip>
        )}
      </Flex>

      <Text
        fz="0.875rem"
        color="dark.4"
        component="span"
        sx={{
          p: {
            display: 'inline',
            margin: 0,
            '&:not(:last-of-type)': {
              margin: '0 0 4px 0',
            },
          },
          ul: {
            marginTop: 8,
            marginBottom: 8,
          },
          ol: {
            marginTop: 8,
            marginBottom: 8,
          },
        }}
      >
        <MarkdownRenderer content={fileSummaryState.summary} />
      </Text>
    </Flex>
  );
};

export const FileSearchResultSummary = memo(FileSearchResultSummaryBase);
