import { memo } from 'react';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit } from '@tabler/icons-react';

import { ClientHeaderActionsProps } from './ClientHeaderActions.types';
import { DeleteClientButton } from '../DeleteClientButton';
import { UpsertClientModal } from '../UpsertClientModal';
import { ShareClientButton } from '../ShareClientButton';

const ClientHeaderActionsBase = ({ client }: ClientHeaderActionsProps) => {
  const [isUpsertClientModalOpened, { open: openUpsertClientModal, close: closeUpsertClientModal }] = useDisclosure();

  if (!client) return null;

  return (
    <Flex direction="column" align="flex-end" sx={(theme) => ({ alignSelf: 'flex-start' })}>
      <Flex sx={{ width: '100%' }} justify="flex-end">
        <ShareClientButton />

        {client.viewerCanUpdate && (
          <Tooltip label="Edit client's info" withinPortal withArrow>
            <ActionIcon onClick={openUpsertClientModal} color="gray.6">
              <IconEdit size={16} />
            </ActionIcon>
          </Tooltip>
        )}

        <DeleteClientButton client={client} color="red.4" />
      </Flex>

      <UpsertClientModal opened={isUpsertClientModalOpened} isEdit client={client} onClose={closeUpsertClientModal} />
    </Flex>
  );
};

export const ClientHeaderActions = memo(ClientHeaderActionsBase);
