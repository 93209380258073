import { memo, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { Anchor, Avatar, Button, Flex, Paper, Text } from '@mantine/core';

import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import {
  CreateOrganizationForm,
  CreateOrganizationFormValues,
} from '@/pageAI/components/organizations/CreateOrganizationForm';
import { useCreateOrganization } from '@/pageAI/hooks/organizations/useCreateOrganization';
import { getItemFromStorage } from '@/shared/lib/storage';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useOrganizationByDomain } from '@/pageAI/hooks/organizations/useOrganizationByDomain';
import { getDomainFromEmail } from '@/shared/utils/string';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { useRequestToJoinOrganization } from '@/pageAI/hooks/organizations/useRequestToJoinOrganization';
import { LogOutButton } from '@/pageAI/components/auth/LogOutButton';
import { isFreeEmailDomain } from '@/pageAI/services/users';

const OrganizationOnboardingPageBase = () => {
  const navigate = useNavigate();
  const { notify } = useNotifications();
  const [searchParams] = useSearchParams();

  const { currentUser } = useCurrentUser();
  const domain = currentUser?.email ? getDomainFromEmail(currentUser.email) : null;
  const { organization: existingOrganization, isLoading } = useOrganizationByDomain(domain);
  const { createOrganization, isLoading: isCreating } = useCreateOrganization();
  const { requestToJoinOrganization, isLoading: isRequesting } = useRequestToJoinOrganization();
  const [tab, setTab] = useState<'create' | 'join' | null>(null);
  const [hasRequested, setHasRequested] = useState(false);

  if (currentUser?.organizations?.nodes?.length) return <Navigate to="/" />;

  if (isLoading)
    return (
      <LoadingPlaceholder
        title="Authenticating..."
        description="Please wait while Page retrieves your account information."
        fullScreen
      />
    );

  const invitationCode = searchParams.get('code') || getItemFromStorage('invitationCode');

  const handleRequestToJoin = async () => {
    if (!currentUser || !existingOrganization) return;

    await requestToJoinOrganization({
      clientMutationId: currentUser.id,
      organizationId: existingOrganization.id,
    });

    setHasRequested(true);
  };

  const handleSubmit = async (values: CreateOrganizationFormValues) => {
    if (!currentUser) return;

    await createOrganization({
      clientMutationId: currentUser.id,
      name: values.organizationName.trim(),
      website: values.website?.trim() || '',
      practiceAreas: values.practiceAreas,
      invitationCode,
    });

    notify('Congratulations!', 'Your organization has been successfully created.', 'success');

    navigate('/user-onboarding?onboarding=true', { replace: true });
  };

  const renderContent = () => {
    if (existingOrganization)
      return (
        <Flex direction="column" gap="md">
          <Flex direction="column" align="center">
            <PageLogoCircle width={64} height={64} />

            <Text fz="1.5rem" color="dark.5" fw={600} mt="xs">
              Join your organization
            </Text>

            <Text fz="1rem" color="dark.2" fw={500} align="center">
              Your organization already exists on Page. You can request to join now and wait for an approval.
            </Text>
          </Flex>

          <Flex direction="column">
            <Flex align="center" gap={6} justify="center" mb="md">
              <Avatar src={existingOrganization.avatarUrl} radius="50%" />

              <Text color="dark" fw={500}>
                {existingOrganization.name || existingOrganization.website || domain}
              </Text>
            </Flex>

            <Button
              sx={{ width: '100%' }}
              color="red.6"
              size="md"
              onClick={handleRequestToJoin}
              loading={isRequesting}
              disabled={hasRequested}
            >
              Request to join
            </Button>

            {hasRequested && (
              <Text fz="0.875rem" align="center" color="dark.2" mt={4}>
                You have requested to join the organization.
              </Text>
            )}
          </Flex>
        </Flex>
      );

    const contactEmail = 'support@pageai.co';

    if (!invitationCode) {
      return (
        <Flex direction="column" gap="lg">
          <Flex direction="column" align="center" mb="md">
            <PageLogoCircle width={64} height={64} />

            <Text fz="1.5rem" color="dark.5" fw={600} mt="xs">
              Welcome to Page
            </Text>

            <Text fz="1rem" color="dark.2" fw={500} align="center">
              You&apos;ve successfully registered your account.
            </Text>

            <Text fz="1rem" color="dark.2" fw={500} align="center">
              To start using Page, you need to have an invitation from the Page team. Please contact us at&nbsp;
              <Anchor href={`mailto:${contactEmail}`}>{contactEmail}</Anchor>.
            </Text>
          </Flex>
        </Flex>
      );
    }

    const isFreeEmail = domain && isFreeEmailDomain(domain);

    if (isFreeEmail) {
      if (!tab) {
        return (
          <Flex direction="column" gap="xs">
            <Flex direction="column" align="center" mb="md">
              <PageLogoCircle width={64} height={64} />

              <Text fz="1.5rem" color="dark.5" fw={600} mt="xs">
                Welcome to Page
              </Text>

              <Text fz="1rem" color="dark.2" fw={500} align="center">
                Would you like to join an existing organization on Page or create your own?
                <br />
                <Text fz="0.875rem">
                  <strong>Note:</strong> If you are Medical Opinion Provider and have had an individual client shared
                  with you, you should create your own organization.
                </Text>
              </Text>
            </Flex>

            <Flex direction="column" gap="md">
              <Button
                sx={{ width: '100%' }}
                variant="outline"
                size="md"
                onClick={() => setTab('join')}
                disabled={isRequesting}
              >
                Join existing organization
              </Button>

              <Button
                sx={{ width: '100%' }}
                color="red.6"
                size="md"
                onClick={() => setTab('create')}
                disabled={isCreating}
              >
                Create your organization
              </Button>
            </Flex>
          </Flex>
        );
      }

      if (tab === 'join') {
        return (
          <Flex direction="column" gap="md">
            <Flex direction="column" align="center" mb="md">
              <PageLogoCircle width={64} height={64} />

              <Text fz="1.5rem" color="dark.5" fw={600} mt="xs">
                Join existing organization
              </Text>

              <Text fz="1rem" color="dark.2" fw={500} align="center">
                Please reach out to your admin on Page to request an invite to their organization.
              </Text>
            </Flex>
          </Flex>
        );
      }
    }

    if (tab === 'create' || !isFreeEmail) {
      return (
        <Flex direction="column" gap="lg">
          <Flex direction="column" align="center" mb="md">
            <PageLogoCircle width={64} height={64} />

            <Text fz="1.5rem" color="dark.5" fw={600} mt="xs">
              Create your organization
            </Text>

            <Text fz="1rem" color="dark.2" fw={500} align="center">
              Welcome to Page. Let us know a few things about your organization to get started.
            </Text>
          </Flex>

          {currentUser && (
            <CreateOrganizationForm
              onSubmit={handleSubmit}
              bottomSection={
                <Flex direction="column">
                  <Button mt={4} type="submit" sx={{ width: '100%' }} color="red.6" size="md" loading={isCreating}>
                    Create your organization
                  </Button>
                </Flex>
              }
            />
          )}
        </Flex>
      );
    }
  };

  return (
    <Flex align="center" justify="center" sx={(theme) => ({ background: theme.colors.dark[5], height: '100%' })}>
      <Paper p={64} shadow="sm" sx={{ width: 564 }}>
        {renderContent()}
      </Paper>

      <Flex align="center" justify="center" pos="fixed" right={12} bottom={12}>
        <LogOutButton />
      </Flex>
    </Flex>
  );
};

export const OrganizationOnboardingPage = memo(OrganizationOnboardingPageBase);
