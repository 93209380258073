import { memo } from 'react';
import { Flex, Box } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import { MedicalConditionSidebar } from '../../medicalConditions/MedicalConditionSidebar';
import { useUnifiedStore } from '@/pageAI/states/unified';
import { isSidebarOpenedSelector } from '@/pageAI/states/unified';
import { UnifiedTimeline } from '../UnifiedTimeline';
import { UnifiedContentSearch } from '../UnifiedContentSearch';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { UnifiedFileIndex } from '../UnifiedFileIndex';
import { UnifiedConditionSummary } from '../UnifiedConditionSummary';
import { useUnifiedTab } from '@/pageAI/hooks/unified/useUnifiedTab';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';

const UnifiedLayoutBase = () => {
  const client = useSelectedClient();
  const [tab] = useUnifiedTab();

  const isSidebarOpened = useUnifiedStore(isSidebarOpenedSelector);

  const renderContent = () => {
    if (tab === UnifiedTab.CONDITION_SUMMARY) {
      return <UnifiedConditionSummary />;
    }

    if (tab === UnifiedTab.TIMELINE) {
      return <UnifiedTimeline />;
    }

    if (tab === UnifiedTab.CONTENT_SEARCH) {
      return <UnifiedContentSearch />;
    }

    if (tab === UnifiedTab.FILE_INDEX) {
      return <UnifiedFileIndex />;
    }

    if (!client) return null;

    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('tab', UnifiedTab.FILE_INDEX);

    return <Navigate to={`/clients/${client.id}/summaries?${searchParams.toString()}`} />;
  };

  return (
    <Flex>
      <MedicalConditionSidebar />

      <Box sx={{ flex: '1 1', width: isSidebarOpened ? 'calc(100% - 320px)' : '100%' }}>
        <Flex direction="column">{renderContent()}</Flex>
      </Box>
    </Flex>
  );
};

export const UnifiedLayout = memo(UnifiedLayoutBase);
