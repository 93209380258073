import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Text, clsx, createStyles } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';

import { FileTypeBadges } from '../FileTypeBadges';
import { FileTableRowProps } from './FileTableRow.types';
import { formatDate, parseDateOnly } from '@/shared/utils/date';
import { FileStatusBadge } from '@/shared/components/files/FileStatusBadge';
import { openRightSidebar } from '@/shared/states/layout';
import { setFilePreviewAsset } from '@/shared/states/filePreview';
import { InlineEditor } from '@/shared/components/editor/InlineEditor';
import { useUpdateFileMetadata } from '@/pageAI/hooks/files/useUpdateFileMetadata/useUpdateFileMetadata';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { Client } from '@/pageAI/@types';
import { TimelineEventTypesSelect } from '@/pageAI/components/timeline/TimelineEventTypesSelect';
import { TimelineEventType } from '@/pageAI/gql/graphql';
import { MarkFileAsImportantButton } from '../MarkFileAsImportantButton';
import { singularOrPlural } from '@/shared/utils/string';
import { setSelectedClientFile, useClientFileIndexStore } from '@/pageAI/states/client/clientFileIndex.states';
import { posthog } from '@/shared/plugins/posthog';

const useStyles = createStyles((theme) => ({
  tableRow: {
    borderLeft: `1px solid ${theme.colors.gray[2]}`,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
  selected: {
    backgroundColor: theme.colors.gray[1],
  },
  tableRowCell: {
    '&:hover': {
      '.edit-button': {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },
}));

const FileTableRowBase = ({
  fileAsset,
  rowIndex,
  hiddenColumns,
  style,
  renderActionCell,
  onClick,
}: FileTableRowProps) => {
  const { classes } = useStyles();

  const selectedClient = useSelectedClient();
  const { updateFileMetadataOptimistically } = useUpdateFileMetadata();
  const isSelected = useClientFileIndexStore((state) => state.selectedFileMapping[fileAsset.id]);

  const trRef = useRef<HTMLTableRowElement>(null);
  const [filename, setFilename] = useState(fileAsset.name);
  const classifications = fileAsset.metadata?.pageAIMetadata?.eventTypeClassifications;
  const [isEditingFilename, setIsEditingFilename] = useState(false);
  const [isEditingClassifications, setIsEditingClassifications] = useState(false);

  const handleSelectFile = useCallback(
    (event: React.MouseEvent) => {
      openRightSidebar();
      setFilePreviewAsset(fileAsset);
      setSelectedClientFile(fileAsset.id);

      onClick?.(event, rowIndex, fileAsset);

      posthog.capture('[Files] Select file', {
        fileId: fileAsset.id,
        fileName: fileAsset.name,
        fileIndex: fileAsset.metadata?.pageAIMetadata?.cleanedFileName,
      });
    },
    [fileAsset, onClick, rowIndex],
  );

  const handleClickFilenameCell = useCallback(
    (event: React.MouseEvent) => {
      if (isEditingFilename) event.stopPropagation();
    },
    [isEditingFilename],
  );

  const handleClickClassificationCell = useCallback(
    (event: React.MouseEvent) => {
      if (isEditingClassifications) event.stopPropagation();
    },
    [isEditingClassifications],
  );

  const cancelEditingClassifications = useCallback(() => {
    setIsEditingClassifications(false);
  }, []);

  const confirmEditingClassifications = useCallback(
    (newValues: TimelineEventType[]) => {
      if (!selectedClient) return;

      updateFileMetadataOptimistically(selectedClient as Client, {
        fileId: fileAsset.id,
        metadata: {
          eventTypeClassifications: newValues,
        },
      });

      requestIdleCallback(() => setIsEditingClassifications(false));
    },
    [selectedClient, updateFileMetadataOptimistically, fileAsset.id],
  );

  const handleClickEditClassifications = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();

    setIsEditingClassifications((isEditing) => !isEditing);
    setIsEditingFilename(false);
  }, []);

  const handleChangeClassifications = useCallback(
    (value?: string) => {
      posthog.capture('[Files] Change file classification', {
        clientId: selectedClient?.id,
        clientFirstName: selectedClient?.firstName,
        clientLastName: selectedClient?.lastName,
        fileId: fileAsset.id,
        fileName: fileAsset.name,
        fileIndex: fileAsset.metadata?.pageAIMetadata?.cleanedFileName,
        classification: value,
      });

      const newValue = value as TimelineEventType | undefined;

      if (!newValue) {
        confirmEditingClassifications([]);

        return;
      }

      confirmEditingClassifications([newValue]);
    },
    [confirmEditingClassifications, fileAsset, selectedClient],
  );

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (isEditingClassifications) cancelEditingClassifications();
      }
    };

    document.addEventListener('keydown', handleKeydown, { capture: true });

    return () => {
      document.removeEventListener('keydown', handleKeydown, { capture: true });
    };
  }, [isEditingFilename, isEditingClassifications, confirmEditingClassifications, cancelEditingClassifications]);

  const classificationCellRef = useClickOutside(() => {
    cancelEditingClassifications();
  });

  return (
    <tr
      key={fileAsset.id}
      ref={trRef}
      className={clsx('file-row', ['row', rowIndex].join('-'), classes.tableRow, {
        [classes.selected]: isSelected,
      })}
      data-index={rowIndex}
      {...(isSelected ? { 'data-active': true } : {})}
      onClick={handleSelectFile}
      style={{
        userSelect: 'none',
        transition: 'all 0.1s',
        height: 40,
        cursor: 'pointer',
        ...style,
      }}
      data-testid={fileAsset.metadata?.pageAIMetadata?.indexNumber}
    >
      {!hiddenColumns?.includes('#') && (
        <td style={{ fontWeight: 500 }} data-testid="file-id">
          {fileAsset.index || rowIndex + 1}
        </td>
      )}

      {!hiddenColumns?.includes('Name') && (
        <td className={classes.tableRowCell} onClick={handleClickFilenameCell} data-testid="file-name">
          <Box
            component="span"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 6,
              cursor: 'pointer',
              '.ck.ck-content': {
                border: 'none',
              },
            }}
          >
            <MarkFileAsImportantButton fileAsset={fileAsset} />

            {isEditingFilename ? (
              <InlineEditor data={filename} onDataChange={setFilename} />
            ) : (
              <>
                <Text
                  component="span"
                  className="file-name"
                  fw={500}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {filename}
                </Text>
              </>
            )}
          </Box>
        </td>
      )}

      {!hiddenColumns?.includes('Classification') && (
        <td className={classes.tableRowCell} onClick={handleClickClassificationCell}>
          <Box
            component="span"
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              '.close-button': {
                display: 'none',
              },
              '.ghost-InputWrapper-root.ghost-Select-root': {
                'div[role="combobox"]': {
                  opacity: 0,
                  pointerEvents: 'none',
                  position: 'absolute',
                  left: 0,
                  top: -5,
                },
              },
            }}
            ref={classificationCellRef}
          >
            <FileTypeBadges fileAsset={fileAsset} onClick={handleClickEditClassifications} />

            {isEditingClassifications && (
              <TimelineEventTypesSelect
                value={classifications?.[0]}
                onChange={handleChangeClassifications}
                size="xs"
                clearable={false}
                withinPortal={false}
                initiallyOpened
              />
            )}
          </Box>
        </td>
      )}

      {!hiddenColumns?.includes('Received Date') && (
        <td>
          <Text component="span" color="dark">
            {fileAsset.createdAt ? formatDate(parseDateOnly(fileAsset.createdAt)) : '-'}
          </Text>
        </td>
      )}

      {!hiddenColumns?.includes('Size') && (
        <td>
          <Text fz="0.875rem" color="dark" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {fileAsset.metadata?.totalPages} {singularOrPlural('page', 'pages')(fileAsset.metadata?.totalPages)}
          </Text>
        </td>
      )}

      {!hiddenColumns?.includes('Status') && (
        <td>
          <FileStatusBadge file={fileAsset} />
        </td>
      )}

      {!hiddenColumns?.includes('') && (
        <td style={{ textAlign: 'right' }}>{renderActionCell?.(fileAsset, rowIndex)}</td>
      )}
    </tr>
  );
};

export const FileTableRow = memo(FileTableRowBase);
