import { memo, useEffect } from 'react';
import { Box, Flex, ScrollArea } from '@mantine/core';
import { IconTemplateOff } from '@tabler/icons-react';

import { MedicalConditionItemAsSummary } from '../../medicalConditions/MedicalConditionItemAsSummary';
import { selectedConditionSelector, setDefaultCondition, useUnifiedStore } from '@/pageAI/states/unified';
import { isSidebarOpenedSelector } from '@/pageAI/states/unified';
import { MedicalConditionTitle } from '../../medicalConditions/MedicalConditionTitle';
import { useConditionSharing } from './UnifiedConditionSummary.utils';
import { useClientMedicalConditions } from '@/pageAI/hooks/clients/useClientMedicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { truthy } from '@/shared/utils/boolean';
import { SummaryByConditionStatus } from '@/pageAI/gql/graphql';
import { DataEmptyPlaceholder } from '../../clients/DataEmptyPlaceholder';
import { ExportConditionsButton } from '../../medicalConditions/export/ExportConditionsButton';
import { useElementRect } from '@/shared/hooks/dom/useElementRect';
import { useConditionSearchTerms } from '@/pageAI/hooks/conditions/useConditionSearchTerms';

const UnifiedConditionSummaryBase = () => {
  const client = useSelectedClient(true);

  const { medicalConditions, isLoading, isError } = useClientMedicalConditions({
    clientId: client.id,
    autoSelect: true,
  });
  const {
    getConditionSearchTerms,
    isLoading: isLoadingConditionSearchTerms,
    isError: isErrorConditionSearchTerms,
  } = useConditionSearchTerms();

  const isSidebarOpened = useUnifiedStore(isSidebarOpenedSelector);
  const condition = useUnifiedStore(selectedConditionSelector);
  const { ref: headerRef, rect: headerRect } = useElementRect();

  useConditionSharing({ selectedMedicalConditionId: condition?.id });

  useEffect(() => {
    if (condition) return;

    if (!isLoading && medicalConditions?.length) {
      setDefaultCondition();
    }
  }, [isLoading, medicalConditions, condition]);

  useEffect(() => {
    if (!condition || isLoadingConditionSearchTerms || isErrorConditionSearchTerms) return;

    getConditionSearchTerms(condition);
  }, [condition, isLoadingConditionSearchTerms, isErrorConditionSearchTerms, getConditionSearchTerms]);

  if (client.summaryByConditionStatus !== SummaryByConditionStatus.Generated)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <DataEmptyPlaceholder
          icon={<IconTemplateOff size={80} />}
          title="Not generated"
          description="The condition summary has yet to be generated for this client."
        />
      </Flex>
    );

  if (isLoading)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <LoadingPlaceholder
          title="Loading condition summary..."
          description={[
            'Please wait while Page retrieves the condition summary',
            client ? ` of ${getClientDisplayName(client)}` : '',
            '.',
          ]
            .filter(truthy)
            .join('')}
          fullScreen
        />
      </Flex>
    );

  if (isError || !medicalConditions) throw new Error('Could not load condition summary');

  if (!condition) return null;

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        ref={headerRef}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          padding: '12px 32px',
        })}
      >
        <MedicalConditionTitle isSidebarOpened={isSidebarOpened} />
      </Flex>

      <ScrollArea
        sx={{
          height: `calc(100vh - ${headerRect.top + headerRect.height}px)`,
          code: {
            fontFamily: 'unset',
            textDecoration: 'underline',
            fontWeight: 500,
          },
          '.ghost-ScrollArea-viewport': {
            '> div': {
              height: '100%',
            },
          },
        }}
      >
        <Box
          sx={{
            padding: '16px 32px',
          }}
        >
          <MedicalConditionItemAsSummary medicalCondition={condition} />
        </Box>
      </ScrollArea>

      <ExportConditionsButton />
    </Flex>
  );
};

export const UnifiedConditionSummary = memo(UnifiedConditionSummaryBase);
