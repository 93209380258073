import { memo } from 'react';
import { Button, Flex } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';

import { ErrorPlaceholder } from '../ErrorPlaceholder';
import { PageErrorBoundaryProps } from './PageErrorBoundary.types';

const PageErrorBoundaryBase = ({ resetError }: PageErrorBoundaryProps) => {
  return (
    <Flex sx={{ height: '100%', width: '100%' }} align="center" justify="center">
      <ErrorPlaceholder
        action={
          <Button color="red" size="md" leftIcon={<IconRefresh size={20} />} mt={4} onClick={resetError}>
            Refresh
          </Button>
        }
      />
    </Flex>
  );
};

export const PageErrorBoundary = memo(PageErrorBoundaryBase) as (props: PageErrorBoundaryProps) => React.ReactElement;
