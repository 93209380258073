import { useMemo, useState } from 'react';
import { ActionIcon, Flex, Popover, ScrollArea, Text, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSelect } from '@tabler/icons-react';
import { isEmpty } from 'lodash-es';

import { MenuItem } from '@/shared/designSystem/MenuItem';
import { getMonthText } from '@/shared/utils/date';
import { SortOrder } from '@/shared/@types';
import { genericMemo } from '@/pageAI/@types/common';

export interface QuickNavigationProps<T> {
  mapping: Record<string, Record<string, T[]>>;
  onSelect?: (item: T) => void;
  sortOrder?: SortOrder;
}

const filterBySearchValue = <T,>(searchValue: string, mapping: Record<string, Record<string, T[]>>) => {
  const filteredMapping: Record<string, Record<string, T[]>> = {};
  const loweredCaseSearchValue = searchValue.toLowerCase();
  const parsedDate = new Date(searchValue);
  const parsedYear = parsedDate.getFullYear();
  const parsedMonth = parsedDate.getMonth() + 1;

  Object.entries(mapping).forEach(([year, byMonthMapping]) => {
    if (year.toLowerCase().includes(searchValue.toLowerCase())) {
      filteredMapping[year] = byMonthMapping;

      return;
    }

    const filteredMonths: Record<string, T[]> = {};

    Object.entries(byMonthMapping).forEach(([month, items]) => {
      const monthText = getMonthText(month);

      if (
        month.toLowerCase().includes(loweredCaseSearchValue) ||
        monthText.toLowerCase().includes(loweredCaseSearchValue) ||
        (year === parsedYear.toString() && Number(month) === parsedMonth)
      ) {
        filteredMonths[month] = items;
      }
    });

    if (!isEmpty(filteredMonths)) {
      filteredMapping[year] = filteredMonths;
    }
  });

  return filteredMapping;
};

const QuickNavigationBase = <T,>({ mapping: rawMapping, onSelect, sortOrder = 'desc' }: QuickNavigationProps<T>) => {
  const [opened, { close, toggle }] = useDisclosure();
  const [searchValue, setSearchValue] = useState('');
  const mapping = useMemo(
    () => (searchValue ? filterBySearchValue(searchValue, rawMapping) : rawMapping),
    [searchValue, rawMapping],
  );

  const sortedEntries = useMemo(() => {
    let entries = Object.entries(mapping);

    if (sortOrder === 'desc') entries = entries.toReversed();

    return entries;
  }, [mapping, sortOrder]);

  const handleSelect = (year: string) => {
    close();

    const item = Object.values(mapping[year]).at(sortOrder === 'desc' ? -1 : 0)?.[0];

    if (!item) return;

    onSelect?.(item);
  };

  const handleSelectMonth = (year: string, month: string) => {
    close();

    const item = mapping[year][month][0];

    if (!item) return;

    onSelect?.(item);
  };

  return (
    <Popover shadow="sm" width={320} position="bottom-start" opened={opened} onClose={close}>
      <Popover.Target>
        <Tooltip label="Quick Navigation" withArrow withinPortal>
          <ActionIcon color="gray.7" onClick={toggle}>
            <IconSelect size={20} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <Flex direction="column">
          <Flex align="center" justify="space-between" sx={{ padding: '12px 12px 8px 8px' }}>
            <Text size="sm" fw={500} color="dark">
              Quick Navigation
            </Text>

            <Flex>
              <TextInput
                placeholder="Filter..."
                size="xs"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </Flex>
          </Flex>

          {!isEmpty(mapping) ? (
            <ScrollArea h={440} sx={{ padding: '0 4px' }}>
              <Flex direction="column" pb={4}>
                {sortedEntries.map(([year, itemsByYear]) => {
                  let itemsByMonth = Object.entries(itemsByYear);

                  if (sortOrder === 'desc') itemsByMonth = itemsByMonth.toReversed();

                  return (
                    <Flex key={year} direction="column">
                      <MenuItem onClick={() => handleSelect(year)}>
                        <Text fw={500}>{year}</Text>
                      </MenuItem>

                      {itemsByMonth.map(([month, items]) => {
                        return (
                          <Flex key={month} direction="column">
                            <MenuItem onClick={() => handleSelectMonth(year, month)}>
                              <Flex align="center" gap={4} pl="xs">
                                <Text>{getMonthText(month)}</Text>

                                <Text color="dark.3" fz="0.75rem">
                                  ({items.length})
                                </Text>
                              </Flex>
                            </MenuItem>
                          </Flex>
                        );
                      })}
                    </Flex>
                  );
                })}
              </Flex>
            </ScrollArea>
          ) : (
            <Flex px="xs" py="md" align="center" justify="center">
              <Text fz="0.875rem" fw={600} color="dark.2" align="center">
                No results
              </Text>
            </Flex>
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const QuickNavigation = genericMemo(QuickNavigationBase);
